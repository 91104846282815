export default function FilterIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.6187 4.64343C21.5033 4.37672 21.312 4.14983 21.0686 3.99098C20.8253 3.83213 20.5406 3.74833 20.25 3.74999H3.74997C3.45966 3.75057 3.17575 3.83537 2.93269 3.99412C2.68962 4.15288 2.49785 4.37875 2.38063 4.64435C2.26341 4.90995 2.22579 5.20386 2.27232 5.49042C2.31886 5.77698 2.44755 6.04388 2.64279 6.25874L2.65029 6.26718L8.99998 13.0472V20.25C8.99991 20.5215 9.07353 20.7879 9.21297 21.0208C9.35241 21.2537 9.55246 21.4445 9.79178 21.5726C10.0311 21.7008 10.3007 21.7616 10.5719 21.7486C10.843 21.7356 11.1056 21.6492 11.3315 21.4987L14.3315 19.4981C14.5372 19.3611 14.7058 19.1755 14.8224 18.9576C14.939 18.7398 15 18.4965 15 18.2494V13.0472L21.3506 6.26718L21.3581 6.25874C21.5554 6.04486 21.6853 5.77761 21.7317 5.49034C21.778 5.20307 21.7387 4.90851 21.6187 4.64343ZM13.7043 12.2419C13.5746 12.3794 13.5016 12.5609 13.5 12.75V18.2494L10.5 20.25V12.75C10.5 12.5595 10.4276 12.3762 10.2975 12.2372L3.74997 5.24999H20.25L13.7043 12.2419Z"
        fill="white"
      />
    </svg>
  );
}
