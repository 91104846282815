import React, { useEffect, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button } from '../../components/ui/button';
import { Label } from '../../components/ui/label';
import { EyeOpenIcon, EyeNoneIcon } from '@radix-ui/react-icons';
import { useToast } from '../../components/ui/use-toast';
import logoImage from '../../assets/images/Scale-secure-logo-white.png';
import { composeValidators, required } from '../../helpers/form-validations';
import withLoader from '../../redux/Hoc/withLoader';
import withUser from '../../redux/Hoc/withUser';
import { resetPassword, verifyResetPasswordToken } from '../../utils/util';
import right from '../../assets/images/right.png';
import Backdrop from '../../components/Backdrop/backdrop';

const ResetPassword = ({ setLoader }) => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showValidations, setShowValidations] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    digit: false,
    special: false
  });

  const validatePassword = (value) => {
    const newValidation = {
      length: value.length >= 8,
      uppercase: /[A-Z]/.test(value),
      lowercase: /[a-z]/.test(value),
      digit: /\d/.test(value),
      special: /[!@#$%^&*(),.?":{}|<>]/.test(value)
    };
    setPasswordValidation(newValidation);

    if (Object.values(newValidation).every(Boolean)) {
      setShowValidations(false);
    }
  };

  const onSubmit = async (formData) => {
    if (Object.values(passwordValidation).every(Boolean) && password === confirmPassword) {
      const payload = {
        password: formData.password,
        token: token
      };
      setLoader(true);
      const resetRes = await resetPassword(payload);
      setLoader(false);
      if (resetRes.status === 200) {
        toast({
          variant: 'success',
          title: 'Success',
          description: resetRes.data.message
        });
        navigate('/login');
      } else {
        toast({
          variant: 'destructive',
          title: 'Error',
          description: resetRes.data.message
        });
      }
    } else {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'Please ensure all password requirements are met and passwords match.'
      });
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const verifyPasswordToken = async () => {
    const tokenRes = await verifyResetPasswordToken(token);
    if (tokenRes.data.message === 'Invalid token.') {
      navigate('/reset-password');
    }
  };

  useEffect(() => {
    verifyPasswordToken();
  }, []);

  const Passwordicon = () => {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <circle cx="8" cy="8" r="8" fill="#34416D" />
      </svg>
    );
  };
  const Approvedicon = () => {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <circle cx="14" cy="14" r="14" fill="#24AC54" />
        <path
          d="M6.98804 13.9849L6.98804 13.9849C6.83586 14.1359 6.75 14.3411 6.75 14.5554C6.75 14.7698 6.83586 14.975 6.98804 15.126L10.9078 19.0145C10.9831 19.0893 11.0724 19.1485 11.1706 19.1889C11.2688 19.2292 11.3739 19.25 11.4801 19.25C11.5863 19.25 11.6914 19.2292 11.7896 19.1889C11.8878 19.1485 11.9771 19.0893 12.0525 19.0145L21.012 10.1263C21.1641 9.97537 21.25 9.77019 21.25 9.55582C21.25 9.34146 21.1641 9.13628 21.012 8.98531L21.012 8.98531C20.8599 8.83442 20.654 8.75 20.4397 8.75C20.2255 8.75 20.0196 8.83442 19.8675 8.98531L19.8675 8.98532L11.4801 17.3065L8.13255 13.985L8.13254 13.9849C7.98043 13.834 7.77455 13.7496 7.56029 13.7496C7.34603 13.7496 7.14014 13.834 6.98804 13.9849Z"
          fill="white"
          stroke="white"
          strokeWidth="0.5"
        />
      </svg>
    );
  };

  const ValidationItem = ({ condition, text }) => (
    <div className="w-full flex flex-row items-center">
      <div className="relative flex justify-center">
        <div className={`w-5 h-5 rounded-full ${condition ? 'text-green-500' : 'text-[#868686]'}`}>
          {condition ? <Approvedicon /> : <Passwordicon />}
        </div>
        {!(
          text === 'Password must contain at least one special character like !, @, #, $, etc.'
        ) && (
          <div
            className={`absolute left-2.3 top-5 w-0.5 h-6 ${condition ? 'bg-[#24AC54]' : 'bg-[#34416D]'}`}></div>
        )}
      </div>
      <p className="ml-4 text-xs text-[#868686]">{text}</p>
    </div>
  );

  return (
    <div className="w-full grid h-[100vh] grid-cols-1 sm:grid-cols-2 md:h-[100vh] lg:grid-cols-2 lg:h-[100vh] xl:h-[100vh] 2xl:h-[100vh]">
      <Backdrop />
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <div
            className="relative flex items-start justify-center w-auto overflow-hidden rounded-md"
            style={{
              backgroundImage: `url(${right})`,
              backgroundPosition: 'right',
              backgroundSize: 'cover'
            }}>
            <form
              onSubmit={handleSubmit}
              className="flex items-center justify-center w-[90%] h-[100%] flex-col manrope-fontCss">
              <div className="flex justify-center">
                <img width={'219px'} height={'107px'} src={logoImage} alt="" />
              </div>
              <div
                className="flex flex-col w-[98%] gap-6 border rounded-md px-10 py-6 "
                style={{ backgroundColor: 'rgba(30, 34, 48,0.93)' }}>
                <div className="text-start space-y-1">
                  <h1 className="text-lg sm:text-sm lg:text-lg xl:text-2xl font-semibold manrope-fontCss">
                    Reset Password
                  </h1>
                  <p className="font-normal text-xs manrope-fontCss text-muted-foreground manrope-fontCss">
                    This will take some effort, Relax and then get started!
                  </p>
                </div>
                <div className="grid gap-4 mt-4">
                  <div className="relative grid gap-1">
                    <div className="flex items-center">
                      <Label
                        htmlFor="password"
                        className="text-white manrope-fontCss text-xs font-semibold manrope-fontCss">
                        Set-up your 8+ digits password
                      </Label>
                    </div>
                    <Field
                      id="password"
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                      placeholder="Start entering your new password here..."
                      validate={composeValidators(required)}
                      className="bg-[#11141F] text-white  text-base font-semibold">
                      {({ input, meta }) => (
                        <div>
                          <input
                            {...input}
                            className="w-full h-10 p-2 rounded bg-[#11141F] text-white"
                            onChange={(e) => {
                              input.onChange(e);
                              setPassword(e.target.value);
                              validatePassword(e.target.value);
                              setShowValidations(true);
                            }}
                            onFocus={() => setShowValidations(true)}
                          />
                          {meta.error && meta.touched && (
                            <span className="text-red-500">{meta.error}</span>
                          )}
                        </div>
                      )}
                    </Field>
                    <div
                      className="absolute h-15 top-8 right-0 flex items-center px-4 cursor-pointer"
                      onClick={togglePasswordVisibility}>
                      {showPassword ? (
                        <EyeNoneIcon className="h-5 w-5 text-white" />
                      ) : (
                        <EyeOpenIcon className="h-5 w-5 text-white" />
                      )}
                    </div>
                  </div>
                  <div className="relative grid gap-1">
                    <div className="flex items-center">
                      <Label
                        htmlFor="confirmPassword"
                        className="text-white text-xs manrope-fontCss font-semibold">
                        Confirm New Password
                      </Label>
                    </div>
                    <Field
                      id="confirmPassword"
                      type={showConfirmPassword ? 'text' : 'password'}
                      name="confirmPassword"
                      placeholder="Start entering your new password here..."
                      validate={composeValidators(required)}
                      className="bg-[#11141F] text-white">
                      {({ input, meta }) => (
                        <div>
                          <input
                            {...input}
                            className="w-full h-10 p-2 rounded bg-[#11141F] text-white"
                            onChange={(e) => {
                              input.onChange(e);
                              setConfirmPassword(e.target.value);
                            }}
                          />
                          {meta.error && meta.touched && (
                            <span className="text-red-500">{meta.error}</span>
                          )}
                        </div>
                      )}
                    </Field>
                    <div
                      className="absolute h-15 top-8 right-0 flex items-center px-4 cursor-pointer"
                      onClick={toggleConfirmPasswordVisibility}>
                      {showConfirmPassword ? (
                        <EyeNoneIcon className="h-5 w-5 text-white" />
                      ) : (
                        <EyeOpenIcon className="h-5 w-5 text-white" />
                      )}
                    </div>
                  </div>
                  {showValidations && (
                    <div className="space-y-2 text-xs ">
                      <ValidationItem
                        condition={passwordValidation.length}
                        text="Password must be at least 8 characters long"
                        className="text-[#868686] text-xs"
                      />
                      <ValidationItem
                        condition={passwordValidation.uppercase && passwordValidation.lowercase}
                        text="Password must contain 1 Uppercase & 1 Lowercase letter"
                      />
                      <ValidationItem
                        condition={passwordValidation.digit}
                        text="Password must contain at least one digit like 1,2,3,4,5,6,etc."
                      />
                      <ValidationItem
                        condition={passwordValidation.special}
                        text="Password must contain at least one special character like !, @, #, $, etc."
                      />
                    </div>
                  )}
                  <Button
                    type="submit"
                    className="text-white w-[50%] h-9 text-sm manrope-fontCss mt-4 bg-[#9747FF] hover:bg-[#9747FF]"
                    disabled={
                      !Object.values(passwordValidation).every(Boolean) ||
                      password !== confirmPassword
                    }>
                    Save & Sign In
                  </Button>
                </div>
              </div>
            </form>
          </div>
        )}
      />
    </div>
  );
};

export default withLoader(withUser(ResetPassword));
