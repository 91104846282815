import { Card, CardDescription, CardHeader } from '../../components/ui/card';
import { Button } from '../../components/ui/button';
import RightArrowIcon from '../../assets/icons/right-arrow-icon';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '../ui/dialog';
import { Field, Form } from 'react-final-form';
import { Check, Upload, X } from 'lucide-react';
import { Input } from '../ui/input';
import { useEffect, useState } from 'react';
import withUser from '../../redux/Hoc/withUser';
import { getSecurityTestResults, runSecurityTest } from '../../utils/util';
import withLoader from '../../redux/Hoc/withLoader';
import { useToast } from '../ui/use-toast';
import { composeValidators, openApiUrl, renderField ,required} from '../../helpers/form-validations';


const securityTests = [
  {
    name: 'NMAP TCP Port Scan',
    value: 'security-tcp_scan',
    description:
      'Discover open ports on your servers with a complete TCP port scan of ports 0 to 36645'
  },
  // {
  //   name: 'NMAP UDP Port Scan',
  //   value: 'NMAP UDP Port Scan',
  //   description: 'Discover open ports of common UDP Services'
  // },
  {
    name: 'API Scan',
    value: 'security-rest-apiscan',
    description: 'Upload OpenAPI & Swagger templates for API vulnerability scanning'
  },
  {
    name: 'Website Scan',
    value: 'security-website',
    description: 'Securely connect scanners on private networks'
  }
];

const ButtonCard = ({
  title,
  buttonName,
  className,
  containerClassName,
  user,
  setLoader,
  setData,
  page
}) => {
  const { toast } = useToast();
  const [selectedTests, setSelectedTests] = useState([]);
  const [selectAll] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadedFileName, setUploadedFileName] = useState(null);
  const [jsonURL,setJsonURL]=useState("");
  // const required = (value) => (value ? undefined : 'Required');
  useEffect(() => {
    
    if (selectAll) {
      
      setSelectedTests(securityTests.map((test) => test.value));
      console.log("sekar selected tests ",selectedTests)
    } else {
      setSelectedTests([]);
    }
  }, [selectAll]);


  useEffect(() => {
    if (jsonURL) {
      console.log('Updated jsonURL:', jsonURL);
    }
  }, [jsonURL]);
  const onSubmit = async (formData) => {

    
    try {
      // Checks fields are 
      if(selectedTests.length===0){
        throw new Error("Select Test types")
      }
      // const selectedTestTypes = Object.keys(formData.testType || {}).filter(
      //   (key) => formData.testType[key]
      // );
     
      console.log("sekar naveen formdata 11111 ",formData.testType)
      console.log("sekar naveen ",selectedTests)

      
      // Base payload structure with user and test info
      let payload = {
        user_id: user.user_id,
        enterprise_id: user.enterprise_id ? user.enterprise_id : null,
        test_name: formData.name,
        // type: selectedTestTypes.join(', '), // Comma-separated string for types
        type:selectedTests[0], //here only there only one element that element was which is selected on the checkbox
        object_id: user.object_id,
        subscription_id: user.activated_subscription_id,
        token:selectedTests[0]==="security-rest-apiscan"?formData.bearerToken:"",
        user_name:selectedTests[0]==="security-rest-apiscan"?formData.username:"",
        password:selectedTests[0]==="security-rest-apiscan"?formData.password:"",
        authorization_type:selectedTests[0]==="security-rest-apiscan"?authType:"",
        url: 'https://heritage-explorer-31.netlify.app'
      };

      // If 'API Scan' is selected, handle file or URL upload

      // this condition with selected testtypes array
      // if (selectedTestTypes.includes('security-rest-apiscan'))
        
      if (selectedTests[0]==="security-rest-apiscan")
        {
          // console.log(formData.bearerToken)
          // if(!formData.bearerToken){
          //     throw new Error("select auth type")
          // }
         
        if (uploadedFile) {
          // Convert file to Base64 and add to payload
       
          const reader = new FileReader();
          reader.onloadend = async () => {
            const base64data = reader.result.split(',')[1]; // Remove Base64 header
            payload.openapi_file = base64data; // Add file data in base64 format
            payload.openapi_url = ''; // Empty if no URL is provided

            // for authtype checking
            if(authType==="Bearer"){
              payload.user_name=""
              payload.password=""
              
            }
            else if(authType==="Basic"){
              payload.token=""
            }
            else{
              payload.user_name=""
              payload.password=""
              payload.token=""
            }

            await sendRequestWithPayload(payload); // Function call to send request
          };
          reader.readAsDataURL(uploadedFile); // Trigger Base64 conversion
        } else if (formData.apiUrl || jsonURL) {
          // Include URL if provided
          console.log("OPEN API URL",formData.apiUrl)
          payload.openapi_url = formData.apiUrl
          // payload.openapi_url = formData.apiUrl;
          payload.openapi_file = ''; // Empty if no file is uploaded

          await sendRequestWithPayload(payload); // Function call to send request
          console.log(payload);
        } else {
          throw new Error('For API Scan, either a file or URL must be provided');
        }
      } else {
        // For other scan types, use the URL from the form
        payload.url = formData.url;
        payload.openapi_file = ''; // Empty if not an API scan
        payload.openapi_url = ''; // Empty if not an API scan
        

          // for authtype checking
          // if(authType==="Bearer"){
          //   payload.user_name=""
          //   payload.password=""
            
          // }
          // else if(authType==="Basic"){
          //   payload.token=""
          // }
          // else{
          //   payload.user_name=""
          //   payload.password=""
          //   payload.token=""
          // }
        
          if(!formData.url || !payload.test_name){
              throw new Error("Fields are required")
          }

        await sendRequestWithPayload(payload); // Function call to send request
      }
    } catch (error) {
      console.error(error);
      toast({
        variant: 'destructive',
        title: 'Error',
        description: error.message
      });
    }
  };

  const sendRequestWithPayload = async (payload) => {
    try {
      setLoader(true);
      const response = await runSecurityTest(payload); // Call your API with payload

      const listPayload = {
        user_id: user.user_id,
        enterprise_id: user.enterprise_id ? user.enterprise_id : null,
        page_number: page,
        per_page: 10,
        object_id: user.object_id,
        subscription_id: user.activated_subscription_id
      };

      setIsDialogOpen(false); // Close dialog after successful submission

      const listResponse = await getSecurityTestResults(listPayload); // Call your API with payload
      setLoader(false);

      setData({ security: listResponse.data.data });

      // Show success toast message
      toast({
        variant: 'success',
        title: 'Success',
        description: response.data?.message
      });
    } catch (error) {
      console.error(error);
      setLoader(false);
      toast({
        variant: 'destructive',
        title: 'Error',
        description: error.message
      });
    }
  };

  // const handleTestSelection = (testName) => {
  //   setSelectedTests((prevSelectedTests) =>
  //     prevSelectedTests.includes(testName)
  //       ? prevSelectedTests.filter((name) => name !== testName)
  //       : [...prevSelectedTests, testName]
  //   );
  // };
  const handleTestSelection = (testName) => {
    setSelectedTests([testName]); // Clear previous selections and set the new one
  };
  const handleFileUpload = (e) => {
    const file = e.target.files[0];

    try {
      if (file && file.type !== "application/json") {
        // Handle the file (e.g., read it, upload it, etc.)
        // console.log("JSON file selected:", file);
        throw new Error("Upload json file only")
      } 
      if (file) {
        setUploadedFile(file);
        setUploadedFileName(file.name);
      }
    } catch (error) {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: error.message
      });
    }
   
   
  };

  const removeUploadedFile = () => {
    setUploadedFile(null);
    setUploadedFileName(null);
  };

  const AUTHORIZATION_TYPES = [
    // { value: 'noAuth', label: 'No Auth' },
    // { value: 'basicAuth', label: 'Basic Auth' },
    // { value: 'bearerToken', label: 'Bearer Token' },

    { value: 'NoAuth', label: 'No Auth' },
    { value: 'Basic', label: 'Basic Auth' },
    { value: 'Bearer', label: 'Bearer Token' },
    
  ];

  // const composeValidators =
  // (...validators) =>
  //   (value) =>
  //     validators.reduce((error, validator) => error || validator(value), undefined);

  const [authType, setAuthType] = useState('');

  const SECURITY_TEST_AUTH_TYPE = AUTHORIZATION_TYPES;

  return (
    <div className={`md:w-[250px] w-[220px] manrope-fontCss h-full mt-2   ${containerClassName}`}>
      <Card x-chunk="dashboard-05-chunk-2" className={`h-[85%] ${className}`}>
        <CardHeader className="p-4 px-6 pb-0 h-full">
          <div className="flex flex-col h-full space-y-6">
            <CardDescription className="text-xl text-white font-semibold p-0 pt-0 pb-0">
              {title}
            </CardDescription>
            <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
              <DialogTrigger asChild>
                <Button
                  className="w-full space-x-1 flex justify-between"
                  onClick={() => setIsDialogOpen(true)}>
                  <p className="text-white font-bold text-base">{buttonName}</p>
                  <div className="bg-white h-7 w-7 flex items-center p-1 justify-center border-none rounded-full">
                    <RightArrowIcon />
                  </div>
                </Button>
              </DialogTrigger>
              <DialogContent className="max-w-[70%]  bg-[#222938] text-white rounded-lg overflow-y-auto h-[85vh] opacity-100">
                <DialogHeader>
                  <DialogTitle className="text-2xl font-bold mb-2">New Security Test</DialogTitle>
                </DialogHeader>

                <Form 
                  onSubmit={onSubmit}
                  render={({ handleSubmit,values }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="space-y-4 p-4">
                        {securityTests.map((test, index) => (
                          <div key={index}>
                            <Field name={`testType.${test.value}`} type="checkbox" key={test.name}>
                              {({ input }) => (
                                <div className="flex items-center justify-between p-2 rounded-lg mb-2">
                                  <div className="flex items-center">
                                    <div className="relative inline-flex items-center mr-3">
                                      <input
                                        {...input}
                                        type="checkbox"
                                        id={test.name}
                                        value={test.name}
                                        checked={selectedTests.includes(test.value)}
                                        disabled={test.disabled}
                                        onChange={(e) => {
                                          handleTestSelection(test.value);
                                          input.onChange(e);
                                        }}
                                        className={`appearance-none bg-[#161A27] border-2 border-[#747474] rounded w-5 h-5 cursor-pointer relative outline-none
                          checked:bg-[#9747FF] checked:border-[#9747FF]
                          disabled:bg-[#333E57] disabled:cursor-not-allowed
                          peer z-10`}
                                      />
                                      {selectedTests.includes(test.value) && (
                                        <Check
                                          className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-3 h-3 text-white opacity-0 peer-checked:opacity-100 pointer-events-none z-40"
                                          strokeWidth={5}
                                        />
                                      )}
                                    </div>
                                    <div>
                                      <label htmlFor={test.name} className="cursor-pointer">
                                        <p className=" font-semibold text-lg">{test.name}</p>
                                        <p className="text-base font-medium text-[#A9A9A9]">
                                          {test.description}
                                        </p>
                                      </label>
                                    </div>
                                  </div>
                                  {/* <Button
                                    className="bg-[#333E57] hover:bg-[#333E57] text-white font-medium text-base border-[#444444] border-2 w-36 h-10"
                                    size="sm">
                                    Learn More
                                  </Button> */}
                                </div>
                              )}
                            </Field>
                            <hr className="h-px bg-[#6F6F6F] border-0 w-full" />
                          </div>
                        ))}
                        <div className="flex flex-col space-y-2">
                          <label className="text-lg font-semibold">Enter Test Name</label>
                          <Field name="name">
                            {({ input }) => (
                              <Input
                                {...input}
                                placeholder="Enter Test Name"
                                className="bg-[#161A27] border-[#747474] text-white h-14"
                              />
                            )}
                          </Field>

                          {/* Conditional Rendering for API Scan */}
                          {selectedTests.includes('security-rest-apiscan') ? (
                            <>
                              <label className="text-lg font-semibold">
                                Enter Open API JSON file URL
                              </label>

                            {/* New open api url field  */}

                              <Field name="apiUrl" id="apiUrl"
                              type='text'
                              placeholder="https://example.com/api.json"
                              className="bg-[#161A27] border-[#747474] text-white h-14"
                              //  className="bg-[#11141F] h-9 w-full rounded-base text-base border-[#34416D]"
                              disabled={uploadedFileName?true:false}
                              value={jsonURL}
                              onChange={(e) => {
                                setJsonURL(e.target.value); // Update the state
                              }}
                                    validate={composeValidators(openApiUrl)}
                              >
                                {renderField}
                              {/* <Field
                      id="password"
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                      placeholder="********"
                      validate={composeValidators(required, password)}
                      className="bg-[#11141F] h-9 w-full rounded-base text-base border-[#34416D">
                      {renderField}
                    </Field> */}
                                {/* {({ input }) => (
                                  <Input
                                    {...input}
                                    // placeholder="Enter Postman Dump/Swagger URL"
                                    pattern="https:\/\/.+\.json" 
                                    placeholder="https://example.com/api.json"
                                    className="bg-[#161A27] border-[#747474] text-white h-14"
                                    disabled={uploadedFileName?true:false}
                                    value={jsonURL}
                                    onChange={(e)=>setJsonURL(e.target.value)}
                                  />
                                )} */}
                              </Field>

                              {/* File Upload Input */}
                              <label className="text-lg font-semibold">Upload JSON File</label>
                              <div className="flex items-center space-x-2 w-1/2">
                                <Button
                                  type="button"
                                  onClick={() => document.getElementById('fileInput').click()}
                                  disabled={jsonURL?true:false}
                                  className={`bg-[#161A27] border-[#747474] text-white h-10 px-3 w-1/2 flex items-center justify-center `}>
                                  <Upload className="mr-2" size={16} 
                                 
                                  />
                                  Upload
                                </Button>
                                {uploadedFileName && (
                                  <div className="flex items-center bg-[#161A27] border border-[#747474] w-auto text-white h-10 px-3 rounded-md">
                                    <span className="truncate">{uploadedFileName}</span>
                                    <Button
                                      variant="ghost"
                                      type="button"
                                      onClick={removeUploadedFile}
                                      className="ml-2 p-1 rounded-full"
                                      
                                      >

                                      <X size={16} />
                                    </Button>
                                  </div>
                                )}
                              </div>
                              <input
                                id="fileInput"
                                type="file"
                                accept="application/json"
                                onChange={handleFileUpload}
                                className="hidden"
                              />
                                <div className="flex items-center space-x-4 pt-4 text-lg font-bold">
                    <label htmlFor="testName" className="text-lg font-bold">
                      API Authorization
                    </label>
                    <hr className="flex-grow border-t border-dashed border-[#1E4E9D]" />
                  </div>
                  <label htmlFor="authType" className="text-base font-semibold ">
                    Authorization Type
                  </label>

                  {/* DropDown List  */}
                 
                  <div className="flex flex-row w-full space-x-4  ">
          
          <div className="flex flex-col space-y-4 w-[25%] ">
                             <Field name="authType" component="select" value={authType}>
                               {({ input }) => (
                                 <div className="w-full flex flex-col   ">
                                   <select
                                     {...input}
                                     value={authType} // Set the value of the select element to authType state
                                     onChange={(e) => {
                                       input.onChange(e);
                                       setAuthType(e.target.value); // Update authType state when selection changes
                                     }}
                                     className="w-[95%] bg-[#161A27] rounded-xl p-3 border border-[#747474]  ">
                                     <option value="" disabled>
                                       Select authorization type
                                     </option>
                                     {AUTHORIZATION_TYPES.map((type) => (
                                       <option key={type.value} value={type.value}>
                                         {type.label}
                                       </option>
                                     ))}
                                   </select>
                                 </div>
                               )}
                             </Field>
       
                             {/* <p className="text-base text-[#A1A1AA] font-normal manrope-fontCss">
                               {authType === 'noAuth' && (
                                 <>
                                   The authorization header will be automatically generated when you send
                                   the request. Learn more about{' '}
                                   <span className="text-[#9747FF] manrope-fontCss">API Key</span>{' '}
                                   authorization.
                                 </>
                               )}
                               {authType === 'bearerToken' && (
                                 <>
                                   OAuth 2.0 requires you to provide an access token for authorization.
                                   Learn more about{' '}
                                   <span className="text-[#9747FF] manrope-fontCss">API Key</span>{' '}
                                   authorization.
                                 </>
                               )}
                               {authType === 'basicAuth' && (
                                 <>
                                   Basic Authentication requires a username and password. Learn more about{' '}
                                   <span className="text-[#9747FF] manrope-fontCss">API Key</span>{' '}
                                   authorization.
                                 </>
                               )}
                              
       
                               {!authType && (
                                 <>
                                   The authorization header will be automatically generated when you send
                                   the request. Select an authorization type to see more details.
                                 </>
                               )}
                             </p> */}
                           </div>

                           <div className="h-full w-[1px] bg-[#6F6F6F] inline-block"></div>
       
       <div className="flex flex-col w-[80%]">
                             <Card className="w-full min-h-12 h-auto p-3 border border-[#747474]">
                               {authType === SECURITY_TEST_AUTH_TYPE[0].value && (
                                 <div className="flex flex-col gap-4 items-center">
                                   <p className="text-sm text-[#A1A1AA]">
                                     This request does not use any authorization. Learn more about
                                     authorization.
                                   </p>
                                 </div>
                               )}
                               {authType === SECURITY_TEST_AUTH_TYPE[1].value && (
                                 <div className="flex flex-col gap-4">
                                   {/* <div className="text-xs text-[#A1A1AA] bg-[#9747FF]/20 rounded-lg p-4 w-full">
                                     <p className="flex justify-center">
                                       Note: These parameters hold sensitive data. To keep this data secure
                                       while working in a collaborative environment, we recommend using
                                       variables. Learn more about{' '}
                                       <span className="text-[#9747FF] manrope-fontCss">Variables</span>{' '}
                                       here.
                                     </p>
                                   </div> */}
                                   <div className="grid grid-cols-1 gap-4">
                                     <div className="grid gap-2">
                                       <div className="relative">
                                         <Field
                                           id="username"
                                           type="text"
                                           name="username"
                                           validate={composeValidators(required)}>
                                           {({ input }) => (
                                             <>
                                               <input
                                                 {...input}
                                                 type="text"
                                                 className="block w-full px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                                 placeholder=" "
                                                 value={values.username}
       
                                               // onChange={(e) =>
                                               //   setValues({ ...values, username: e.target.value })
                                               // }
                                               />
                                               <label
                                                 htmlFor="username"
                                                 className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#161A27] px-2 peer-focus:px-2 peer-focus:text-white-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
                                                 Username *
                                               </label>
                                             </>
                                           )}
                                         </Field>
                                       </div>
                                     </div>
                                   </div>
                                   <div className="grid gap-2">
                                     <div className="relative">
                                       <Field
                                         id="password"
                                         type="password"
                                         name="password"
                                         validate={composeValidators(required)}>
                                         {({ input }) => (
                                           <>
                                             <Input
                                               {...input}
                                               type="password"
                                               className="block w-full px-4 py-2 text-white bg-[#161A27] border border-[#747474] rounded-md appearance-none focus:outline-none peer h-10"
                                               placeholder=" "
                                               value={values.password}
                                             // onChange={(e) =>
                                             //   setValues({ ...values, password: e.target.value })
                                             // }
                                             />
                                             <label
                                               htmlFor="password"
                                               className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#161A27] px-2 peer-focus:px-2 peer-focus:text-white-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
                                               Password *
                                             </label>
                                           </>
                                         )}
                                       </Field>
                                     </div>
                                   </div>
                                 </div>
                               )}
                               {authType === SECURITY_TEST_AUTH_TYPE[2].value && (
                                 <div className="grid grid-cols-1 pt-2 gap-2">
                                   <div className="grid gap-4 ">
                                     {/* <div className="text-xs text-[#A1A1AA] bg-[#9747FF]/20 rounded-lg p-4 w-full">
                                       <p className="flex justify-center">
                                         Note: These parameters hold sensitive data. To keep this data
                                         secure while working in a collaborative environment, we recommend
                                         using variables. Learn more about{' '}
                                         <span className="text-[#9747FF] manrope-fontCss">Variables</span>{' '}
                                         here.
                                       </p>
                                     </div> */}
                                     <div className="relative ">
                                       <Field
                                         id="bearerToken"
                                         type="textarea"
                                         name="bearerToken"
                                         validate={composeValidators(required)}>
                                         {({ input }) => (
                                           <>
                                             <textarea
                                               {...input}
                                               className="block w-full px-4 py-2 text-white bg-[#161A27] border border-[#747474] rounded-md appearance-none focus:outline-none peer h-10"
                                               placeholder=" "
                                               value={values.bearerToken}
                                             // onChange={(e) =>
                                             //   setValues({ ...values, bearerToken: e.target.value })
                                             // }
                                             />
                                             <label
                                               htmlFor="bearerToken"
                                               className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#161A27] px-2 peer-focus:px-2 peer-focus:text-white-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
                                               Bearer Token *
                                             </label>
                                           </>
                                         )}
                                         
                                       </Field>
                                     </div>
                                   </div>
                                 </div>
                               )}
                                                                                                                                  </Card>
                           </div>
       
       
                         </div>
       
       
       
       
       
       
       
       
       
       
                             

                            </>
                          ) : (
                            <>
                              <label className="text-lg font-semibold">Enter URL</label>
                              <Field name="url">
                                {({ input }) => (
                                  <Input
                                    {...input}
                                    placeholder="Enter URL for testing"
                                    className="bg-[#161A27] border-[#747474] text-white h-14"
                                  />
                                )}
                              </Field>
                            </>
                          )}
                        </div>
                        <div className="flex justify-between items-center">
                          <p className="text-sm text-gray-400">
                            {selectedTests.length} Tests Selected
                          </p>
                          <Button
                            type="submit"
                            className="bg-[#9747FF] hover:bg-[#9747FF] text-lg h-12 w-[20%] text-white">
                            Start Test
                          </Button>
                        </div>
                      </div>
                    </form>
                  )}
                />
              </DialogContent>
            </Dialog>
          </div>
        </CardHeader>
      </Card>
    </div>
  );
};

export default withLoader(withUser(ButtonCard));
