import React from 'react';
import { Form, Field } from 'react-final-form';
import { Button } from '../../components/ui/button';
import { Label } from '../../components/ui/label';
import { useToast } from '../../components/ui/use-toast';
import logoImage from '../../assets/images/Scale-secure-logo-white.png';
import { composeValidators, email, renderField, required } from '../../helpers/form-validations';
import withLoader from '../../redux/Hoc/withLoader';
import withUser from '../../redux/Hoc/withUser';
import { forgotPassword } from '../../utils/util';
import right from '../../assets/images/right.png';
import Backdrop from '../../components/Backdrop/backdrop';
import { isEmpty } from 'lodash';

const ForgotPassword = ({ setLoader }) => {
  const { toast } = useToast();

  const onSubmit = async (formData) => {
    try {
      setLoader(true);
      const forgotPasswordRes = await forgotPassword(formData);
      setLoader(false);
      if (forgotPasswordRes.status === 200) {
        toast({
          variant: 'success',
          title: 'Success',
          description:
            'Reset password link has been sent to your registered email. Please reset your password.'
        });
      } else {
        toast({
          variant: 'destructive',
          title: 'Error',
          description: forgotPasswordRes.data.message
        });
      }
    } catch (e) {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: e.message
      });
    }
  };

  return (
    <div className="w-full grid h-[100vh] grid-cols-1 sm:grid-cols-2 md:h-[100vh] lg:grid-cols-2 lg:h-[100vh] xl:h-[100vh] 2xl:h-[100vh]">
      <Backdrop />
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, values }) => (
          <div
            className="relative flex flex-col items-center justify-start w-auto"
            style={{
              backgroundImage: `url(${right})`,
              backgroundPosition: 'right',
              backgroundSize: 'cover'
            }}>
            <form
              onSubmit={handleSubmit}
              className="flex items-center justify-center w-[90%] h-[100%] flex-col">
              <div className="flex justify-center">
                <img width={'219px'} height={'107px'} src={logoImage} alt="Scale Secure Logo" />
              </div>
              <div
                className="flex flex-col w-full gap-8 border rounded-xl p-8"
                style={{ backgroundColor: 'rgba(30, 34, 48,0.93)' }}>
                <div className="space-y-1 space-x-12">
                  <div className="flex gap-4 text-start items-center">
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => window.history.back()}
                      style={{ cursor: 'pointer' }}>
                      <circle
                        cx="20"
                        cy="20"
                        r="20"
                        transform="matrix(-1 0 0 1 40 0)"
                        fill="#2C2E35"
                      />
                      <path
                        d="M11.2194 20.031L17.9694 26.781C18.1101 26.9218 18.301 27.0008 18.5 27.0008C18.699 27.0008 18.8899 26.9218 19.0306 26.781C19.1714 26.6403 19.2504 26.4494 19.2504 26.2504C19.2504 26.0514 19.1714 25.8605 19.0306 25.7198L13.5603 20.2504H28.25C28.4489 20.2504 28.6397 20.1714 28.7803 20.0307C28.921 19.8901 29 19.6993 29 19.5004C29 19.3015 28.921 19.1107 28.7803 18.9701C28.6397 18.8294 28.4489 18.7504 28.25 18.7504H13.5603L19.0306 13.281C19.1714 13.1403 19.2504 12.9494 19.2504 12.7504C19.2504 12.5514 19.1714 12.3605 19.0306 12.2198C18.8899 12.0791 18.699 12 18.5 12C18.301 12 18.1101 12.0791 17.9694 12.2198L11.2194 18.9698C11.1496 19.0394 11.0943 19.1222 11.0566 19.2132C11.0188 19.3043 10.9994 19.4019 10.9994 19.5004C10.9994 19.599 11.0188 19.6966 11.0566 19.7876C11.0943 19.8787 11.1496 19.9614 11.2194 20.031Z"
                        fill="white"
                      />
                    </svg>
                    <h1 className="text-2xl font-semibold manrope-fontCss mb-2">Forgot Password</h1>
                  </div>
                  <div className="">
                    <p className="text-sm font-normal text-[#D9D9D9] w-full manrope-fontCss">
                      Before resetting, think of all possible passwords!
                    </p>
                  </div>
                </div>
                <div className="grid gap-4">
                  <div className="grid gap-2">
                    <Label htmlFor="email" className="font-semibold text-xl">
                      Email <span className="text-[#EF4444]">*</span>
                    </Label>
                    <Field
                      id="email"
                      type="email"
                      name="email"
                      placeholder="m@example.com"
                      validate={composeValidators(required, email)}
                      className="bg-[#11141F] h-9 w-full rounded-base text-base border-[#34416D]">
                      {renderField}
                    </Field>
                  </div>
                  <Button
                    type="submit"
                    className={`text-white w-[30%] h-9 text-sm manrope-fontCss mt-4 ${!isEmpty(values.email) ? '' : 'opacity-50'}`}>
                    Send Reset Link
                  </Button>
                </div>
              </div>
            </form>
          </div>
        )}
      />
    </div>
  );
};

export default withLoader(withUser(ForgotPassword));
