import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { Plus, Trash2 } from 'lucide-react';
// import { useNavigate } from 'react-router-dom';

import { LOAD_TEST_METHODS } from '../../constants';
import arrayMutators from 'final-form-arrays';
import { Label } from '../../components/ui/label';
import { renderField } from '../../helpers/form-validations';
import { Input } from '../../components/ui/input';
import { useToast } from '../../components/ui/use-toast';
import { Button } from '../../components/ui/button';
import Box from '@mui/material/Box';  // MUI Box
import Typography from '@mui/material/Typography';  // MUI Typography
import Modal from '@mui/material/Modal';  // MUI Modal

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',  // Make the modal wider to accommodate more content
  maxHeight: '80%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto'
};
const bodyStyle = {
  maxHeight: '60vh',  // Limit the body height
  overflowY: 'scroll',  // Make the body scrollable with always visible scrollbar
  scrollbarWidth: 'thin',  // For Firefox, ensures the scrollbar is visible but thin
  '&::-webkit-scrollbar': {
    width: '8px',  // For Chrome, Safari, and Edge
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',  // Custom color for the scrollbar thumb
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#555',  // Darker thumb on hover
  },
};

const LoadWebSocket = ({ user, setLoader }) => {


  const { toast } = useToast();
  const [open, setOpen] = useState(false);  // Modal open state
  const [apiResponse, setApiResponse] = useState(null);  // API response state

  const handleOpen = () => setOpen(true);  // Open modal
  const handleClose = () => setOpen(false);  // Close modal

  const onSubmit = async (formData) => {

    try {
      console.log(formData, user);

      const response = await fetch(formData?.apiPath, {
        method: formData?.httpMethod,
        headers: {
          'Content-Type': 'application/json',
          ...formData?.headers?.reduce((acc, header) => {
            acc[header.key] = header.value;
            return acc;
          }, {})
        },
        body: (formData?.httpMethod === 'POST' || formData?.httpMethod === 'PUT')
          ? formData?.isBodyDynamic
            ? formData?.bodyDynamicValue
            : formData?.bodyStaticValue
          : null,  // Only attach body if method is POST or PUT
      });

      const responseData = await response.json();
      console.log('API Response:', responseData);

      setApiResponse(responseData);
      handleOpen();


      setLoader(true);
      // const loadTestRes = await loadTest(payload);
      setLoader(false);
    } catch (e) {
      console.log('Error', e);
      toast({
        variant: 'destructive',
        title: 'Error',
        description: e.message
      });
    }
  };


  const handleKeyValueBlurOrChange = (fields, index, values) => {
    const currentPair = values.headerValues[index];
    if (currentPair && (currentPair.key || currentPair.value) && index === fields.length - 1) {
      fields.push({ key: '', value: '' });
    }
  };

  const onChangeParamsValue = (fields, index, values) => {
    const currentPair = values.paramValues[index];
    if (currentPair && (currentPair.key || currentPair.value) && index === fields.length - 1) {
      fields.push({ key: '', value: '' });
    }
  };

  const required = (value) => (value ? undefined : 'Required');

  const composeValidators =
    (...validators) =>
      (value) =>
        validators.reduce((error, validator) => error || validator(value), undefined);

  return (
    <main className="grid flex-1 items-start gap-4 p-4  sm:py-0 md:gap-8 w-full ">
      <div className="grid auto-rows-max items-start gap-4 md:gap-8 lg:col-span-2 manrope-fontCss">
        <Form
          onSubmit={onSubmit}
          initialValues={{
            httpMethod: LOAD_TEST_METHODS[0].method,
            // authType: LOAD_TEST_AUTH_TYPE[0].type,
            headerValues: [{ key: '', value: '' }],
            paramValues: [{ key: '', value: '' }],

          }}
          mutators={{
            ...arrayMutators
          }}
          render={({ handleSubmit, values }) => (
            <>
              <form onSubmit={handleSubmit}>
                <div className="grid gap-4 w-full">
                  <div className="flex w-full pt-2 gap-2">


                    <div className="flex-1 gap-4 gap-y-2 font-semibold text-lg">
                      <Label htmlFor="url" className={`mb-4`}>
                        Websocket URL
                      </Label>
                      <Field
                        className="w-full bg-[#161A27] mt-2"
                        id="url"
                        type="text"
                        name="url"
                        placeholder="Add URL here"
                        validate={composeValidators(required)}>
                        {renderField}
                      </Field>
                    </div>
                    <div className="flex-1 gap-4 gap-y-2 font-semibold text-lg">
                      <Label htmlFor="message" className={`mb-4`}>
                        Message
                      </Label>
                      <Field
                        className="w-full bg-[#161A27] mt-2"
                        id="message"
                        type="text"
                        name="message"
                        placeholder="Enter Message here"
                        validate={composeValidators(required)}>
                        {renderField}
                      </Field>
                    </div>
                  </div>
                  <div className="flex w-full pt-2 gap-2">
                    <div className="flex-1 gap-4 font-semibold text-lg">
                      <Label htmlFor="testName" className={`mb-4`}>
                        Test Name
                      </Label>
                      <Field
                        className="w-full bg-[#161A27] mt-2"
                        id="testName"
                        type="text"
                        name="testName"
                        placeholder="Write test name like “Load Test 1”, etc. "
                        validate={composeValidators(required)}>
                        {renderField}
                      </Field>
                    </div>

                    <div className="flex-1 gap-4 font-semibold text-lg">
                      <Label htmlFor="virtualUsers" className={`mb-4`}>
                        Virtual Users
                      </Label>
                      <Field
                        className="w-full bg-[#161A27] mt-2"
                        id="virtualUsers"
                        type="text"
                        name="virtualUsers"
                        placeholder="Enter number of Virtual Users here "
                        validate={composeValidators(required)}>
                        {renderField}
                      </Field>
                    </div>
                  </div>





                  {/* Headers  */}

                  <div className="flex items-center space-x-4 pt-4">
                    <Label htmlFor="headerValues" className="text-lg font-bold">
                      Headers
                    </Label>
                    <hr className="flex-grow border-t border-dashed border-[#1E4E9D]" />
                  </div>

                  <div className="">
                    <FieldArray name="headerValues">
                      {({ fields }) => (
                        <>
                          {fields.map((name, index) => (
                            <div
                              key={index}
                              className="grid grid-cols-2 items-center space-x-4 pb-2">
                              <div className={`flex flex-col col-span-1`}>
                                {index === 0 && (
                                  <label className="text-lg font-semibold mb-2">Key</label>
                                )}
                                <Field
                                  name={`${name}.key`}
                                  render={({ input }) => (
                                    <Input
                                      {...input}
                                      placeholder="Key"
                                      className="border p-2 rounded bg-[#161A27]"
                                      onBlur={() =>
                                        handleKeyValueBlurOrChange(fields, index, values)
                                      }
                                      onChange={(e) => {
                                        input.onChange(e);
                                        handleKeyValueBlurOrChange(fields, index, values);
                                      }}
                                    />
                                  )}
                                />
                              </div>
                              <div className="flex flex-col col-span-1">
                                {index === 0 && (
                                  <label className="text-lg font-semibold mb-2">Value</label>
                                )}
                                <div className="flex items-center space-x-4">
                                  <Field
                                    name={`${name}.value`}
                                    render={({ input }) => (
                                      <Input
                                        {...input}
                                        placeholder="Value"
                                        className="border p-2 rounded bg-[#161A27]"
                                        onBlur={() =>
                                          handleKeyValueBlurOrChange(fields, index, values)
                                        }
                                        onChange={(e) => {
                                          input.onChange(e);
                                          handleKeyValueBlurOrChange(fields, index, values);
                                        }}
                                      />
                                    )}
                                  />
                                  {fields.length > 1 && (
                                    <Trash2
                                      type="button"
                                      onClick={() => fields.remove(index)}
                                      className="focus:outline-none cursor-pointer"
                                      size={18}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                    </FieldArray>
                  </div>

                  <div className="flex items-center space-x-4 pt-2">
                    <Label htmlFor="paramValues" className="text-lg font-bold">
                      Parameters
                    </Label>
                    <hr className="flex-grow border-t border-dashed border-[#1E4E9D]" />
                  </div>

                  <div className="">
                    <FieldArray name="paramValues">
                      {({ fields }) => (
                        <>
                          {fields.map((name, index) => (
                            <div
                              key={index}
                              className="grid grid-cols-2 items-center space-x-4 pb-2">
                              <div className={`flex flex-col col-span-1`}>
                                {index === 0 && (
                                  <label className="text-lg font-semibold mb-2">Key</label>
                                )}
                                <Field
                                  name={`${name}.key`}
                                  render={({ input }) => (
                                    <Input
                                      {...input}
                                      placeholder="Key"
                                      className="border p-2 rounded bg-[#161A27]"
                                      onBlur={() =>
                                        handleKeyValueBlurOrChange(fields, index, values)
                                      }
                                      onChange={(e) => {
                                        input.onChange(e);
                                        handleKeyValueBlurOrChange(fields, index, values);
                                      }}
                                    />
                                  )}
                                />
                              </div>
                              <div className="flex flex-col col-span-1">
                                {index === 0 && (
                                  <label className="text-lg font-semibold mb-2">Value</label>
                                )}
                                <div className="flex items-center space-x-4">
                                  <Field
                                    name={`${name}.value`}
                                    render={({ input }) => (
                                      <Input
                                        {...input}
                                        placeholder="Value"
                                        className="border p-2 rounded bg-[#161A27]"
                                        onBlur={() => onChangeParamsValue(fields, index, values)}
                                        onChange={(e) => {
                                          input.onChange(e);
                                          onChangeParamsValue(fields, index, values);
                                        }}
                                      />
                                    )}
                                  />
                                  {fields.length > 1 && (
                                    <Trash2
                                      type="button"
                                      onClick={() => fields.remove(index)}
                                      className="focus:outline-none cursor-pointer"
                                      size={18}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                    </FieldArray>
                  </div>
                



                </div>
                <div className="flex flex-row mt-4 gap-4 w-full mb-4">
                  <div className="w-1/2 justify-start items-center">
                    <Button
                      type="submit"
                      className="bg-[#222938] text-white h-10 w-60 text-base font-medium gap-1 border-[#444444] border">
                      <Plus />
                      New Test
                    </Button>
                  </div>
                  <div className="flex w-1/2 justify-end gap-4 ">
                    <Button
                      type="submit"
                      className="bg-[#222938] text-white h-10 w-60 text-base font-medium border-[#444444] border">
                      Discard Changes
                    </Button>
                    <Button type="submit" className=" text-white h-10 w-60 text-base font-medium">
                      Validate
                    </Button>
                  </div>
                </div>
              </form>

            </>
          )}
        />
        {/* Modal to display API response */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2" color="black">
              API Response
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }} color="black" style={bodyStyle}>
              <pre>{JSON.stringify(apiResponse, null, 2)}</pre>
            </Typography>
          </Box>
        </Modal>
      </div>
    </main>
  )
};

export default LoadWebSocket;
