import React, { useState, useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { Plus, Trash2, } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

import { LOAD_TEST_BODY_TYPE, LOAD_TEST_METHODS } from '../../constants';
import arrayMutators from 'final-form-arrays';
import { Label } from '../../components/ui/label';
import { renderField } from '../../helpers/form-validations';
import { Input } from '../../components/ui/input';
import { useToast } from '../../components/ui/use-toast';
import { Button } from '../../components/ui/button';

import { loadTestWebsite, getEnvironmentNames } from '../../utils/util';
import withLoader from '../../redux/Hoc/withLoader';
import withUser from '../../redux/Hoc/withUser';

const Loadwebsite = ({ user, setLoader }) => {
  const { toast } = useToast();
  const navigate = useNavigate();

  const onSubmit = async (formData) => {
    console.log({formData});
    
    try {
      const payload = {
        user_id: user?.user_id,
        url: formData?.websiteUrl,
        virtual_user: formData?.noOfUsers,
        method: formData?.httpMethod,
        testname: formData?.testName,
        enterprise_id: user?.enterprise_id,
        params: formData?.paramValues.filter((i => i.key)),
        restart: false,
        subscription_id: user?.activated_subscription_id,
        object_id: user?.object_id,
        environment: user?.environment || "dev"
      };
      if (formData?.httpMethod === LOAD_TEST_METHODS[1].method ||
        formData?.httpMethod === LOAD_TEST_METHODS[3].method) {

        payload.body = formData?.bodyStaticValue
      }
      console.log({ payload });
      setLoader(true);
      const loadTestRes = await loadTestWebsite(payload);
			setLoader(false);
			handleApiResponse(loadTestRes.data);
    } catch (e) {
      console.log('Error', e);
      toast({
        variant: 'destructive',
        title: 'Error',
        description: e.message
      });
    }
  };


  const handleKeyValueBlurOrChange = (fields, index, values) => {
    const currentPair = values.headerValues[index];
    if (currentPair && (currentPair.key || currentPair.value) && index === fields.length - 1) {
      fields.push({ key: '', value: '' });
    }
  };

  const onChangeParamsValue = (fields, index, values) => {
    const currentPair = values.paramValues[index];
    if (currentPair && (currentPair.key || currentPair.value) && index === fields.length - 1) {
      fields.push({ key: '', value: '' });
    }
  };

  const AUTHORIZATION_TYPES = [
    { value: 'noAuth', label: 'No Auth' },
    { value: 'basicAuth', label: 'Basic Auth' },
    { value: 'bearerToken', label: 'Bearer Token' },
    // { value: 'jwtbearer', label: 'JWT BearerToken' },
    // { value: 'digitalauth', label: 'Digital Auth' },
    // { value: 'oauth1', label: 'OAuth 1.0' },
    // { value: 'oauth2', label: 'OAuth 2.0' },
    // { value: 'hawk', label: 'Hawk Authentication' },
    // { value: 'awssign', label: 'AWS Signature' },
    // { value: 'nltm', label: 'NLTM Authentication' }
  ];
  const LOAD_TEST_AUTH_TYPE = AUTHORIZATION_TYPES;

  const required = (value) => (value ? undefined : 'Required');

  const composeValidators =
    (...validators) =>
      (value) =>
        validators.reduce((error, validator) => error || validator(value), undefined);


  const [formState] = useState({

    httpMethod: LOAD_TEST_METHODS[0].method,
    authType: LOAD_TEST_AUTH_TYPE[0].type,
    bodyType: LOAD_TEST_BODY_TYPE[0].type,
    headerValues: [{ key: '', value: '' }],
    paramValues: [{ key: '', value: '' }],
    bodyDynamicValue: [{ name: '', type: '', isDynamic: true }],
    isBodyDynamic: false,
    bodyStaticValue: '',

  })

  const handleApiResponse = (response) => {
    switch (response.status) {
      case 'success':
        toast({ description: response.message, variant: 'success' });
        navigate('/load')
        break;
      case 'error':
        switch (response.code) {
          case 404:
            toast({ description: response.message, variant: 'error' });
            break;
          case 400:
            toast({ description: response.message, variant: 'error' });
            break;
          case 500:
            toast({ description: response.message, variant: 'error' });
            break;
          default:
            toast({ description: 'An unknown error occurred.', variant: 'error' });
        }
        break;
      default:
        toast({ description: 'Unexpected response from the server.', variant: 'warning' });
    }
  };

 
  useEffect(() => {
    fetchEnvNames();
  }, []);

  const fetchEnvNames = async () => {
    try {
      setLoader(true);
      const response = await getEnvironmentNames({
        user_id: user.user_id,
        enterprise_id: user.enterprise_id
      });
      console.log(response);

      setLoader(false);

    } catch (error) {
      setLoader(false);
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'Failed to fetch environment names'
      });
    }
  };
  return (
    <main className="grid flex-1 items-start gap-4 p-4  sm:py-0 md:gap-8 w-full ">
      <div className="grid auto-rows-max items-start gap-4 md:gap-8 lg:col-span-2 manrope-fontCss">
        {/*<div className="grid gap-4 py-4">*/}
        {/*  <div className="grid grid-cols-4 items-center gap-4">*/}
        <Form
          onSubmit={onSubmit}
          initialValues={
            formState
          }
          keepDirtyOnReinitialize
          mutators={{
            ...arrayMutators
          }}
          render={({ handleSubmit, values }) => (
            <>
              <form onSubmit={handleSubmit}>
                <div className="grid gap-4 w-full">
                  <div className="flex w-full pt-2 gap-2">
                    <div className="flex-1 gap-4 font-semibold text-lg">
                      <Label htmlFor="testName" className={`mb-4`}>
                        Test Name *
                      </Label>
                      <Field
                        className="w-full bg-[#161A27] mt-2"
                        id="testName"
                        type="text"
                        name="testName"
                        placeholder="Write test name like “Load Test 1”, etc. "
                        validate={composeValidators(required)}>
                        {renderField}
                      </Field>
                    </div>

                    <div className="flex-1 gap-4 gap-y-2 font-semibold text-lg">
                      <Label htmlFor="noOfUsers" className={`mb-4`}>
                        Select the No of virtual Users *
                      </Label>
                      {/* <Field name="noOfUsers">
                        {({ input, meta }) => (
                          <div>
                            <Input
                              type="text"
                              value={input.value !== undefined ? input.value : ''} // Ensure correct value
                              onChange={(e) => {
                                const value = parseInt(e.target.value, 10);
                                if (!isNaN(value) && value >= 1 && value <= 500) {
                                  input.onChange(value);
                                } else if (e.target.value === '') {
                                  input.onChange('');
                                }
                              }}
                              className="bg-[#161A27]"
                              // placeholder={'Enter the number of Virtual Users <=500'}
                            />
                            <Slider
                              value={[input.value !== undefined ? input.value : 20]}
                              onValueChange={(newValue) => {
                                input.onChange(newValue[0]);
                              }}
                              className="h-0 bg-background"
                              max={500}
                              min={1}
                              step={1}
                            />
                            {meta.error && meta.touched && (
                              <span className="text-red-500 text-xs italic align-text-top">
                                {meta.error}
                              </span>
                            )}
                          </div>
                        )}
                      </Field> */}
                      <Field
                        className="w-full bg-[#161A27] mt-2"
                        id="noOfUsers"
                        type="text"
                        name="noOfUsers"
                        placeholder="Enter the number of virtual users (1-500)"
                        validate={composeValidators(required)}>
                        {renderField}
                      </Field>
                    </div>
                  </div>

                  {/* <div>
                    <div className="flex items-center space-x-4 font-semibold text-xl pt-2">
                      <Label htmlFor="testName" className="text-lg font-bold">
                        API Detail
                      </Label>
                      <hr className="flex-grow border-t border-dashed border-[#1E4E9D]" />
                    </div>
                    <div>
                      <label htmlFor="httpMethods" className="text-base">
                        HTTP Methods
                      </label>
                      <Field
                        name="httpMethod"
                        component="select"
                        onChange={(event) => handleMethodChange(event.target.value)}
                        className="ml-4 bg-gray-700 border border-gray-600 text-white p-1 rounded-md flex-1"
                      >
                        <option value="">Select Method</option>
                        {LOAD_TEST_METHODS.map((methodObj) => (
                          <option key={methodObj.method} value={methodObj.method}>
                            {methodObj.label}
                          </option>
                        ))}
                      </Field>
                    </div>
                  </div> */}

                  <div className="relative">
                  <Label htmlFor="apiPath" className={`mb-4`}>
                        Enter the URL
                      </Label>
                    <Field
                      id="websiteUrl"
                      type="text"
                      name="websiteUrl"
                      validate={composeValidators(required)}>
                      {({ input }) => (
                        <>
                          <input
                            {...input}
                            type="text"
                            className="block w-full px-4 py-2 text-white  rounded-md appearance-none focus:outline-none peer bg-[#161A27] border-[#747474] border"
                            placeholder="URL of Website"
                          />
                          {/* <label
                            htmlFor="apiPath"
                            className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#161A27] px-2 peer-focus:px-2 peer-focus:text-white-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 ">
                            URL of Website
                          </label> */}
                        </>
                      )}
                    </Field>
                  </div>
                  <div className="flex items-center space-x-4 pt-2">
                    <Label htmlFor="paramValues" className="text-lg font-bold">
                      Parameters
                    </Label>
                    <hr className="flex-grow border-t border-dashed border-[#1E4E9D]" />
                  </div>

                  <div className="">
                    <FieldArray name="paramValues">
                      {({ fields }) => (
                        <>
                          {fields.map((name, index) => (
                            <div
                              key={index}
                              className="grid grid-cols-2 items-center space-x-4 pb-2">
                              <div className={`flex flex-col col-span-1`}>
                                {index === 0 && (
                                  <label className="text-lg font-semibold mb-2">Key</label>
                                )}
                                <Field
                                  name={`${name}.key`}
                                  render={({ input }) => (
                                    <Input
                                      {...input}
                                      placeholder="Key"
                                      className="border p-2 rounded bg-[#161A27]"
                                      onBlur={() =>
                                        handleKeyValueBlurOrChange(fields, index, values)
                                      }
                                      onChange={(e) => {
                                        input.onChange(e);
                                        handleKeyValueBlurOrChange(fields, index, values);
                                      }}
                                    />
                                  )}
                                />
                              </div>
                              <div className="flex flex-col col-span-1">
                                {index === 0 && (
                                  <label className="text-lg font-semibold mb-2">Value</label>
                                )}
                                <div className="flex items-center space-x-4">
                                  <Field
                                    name={`${name}.value`}
                                    render={({ input }) => (
                                      <Input
                                        {...input}
                                        placeholder="Value"
                                        className="border p-2 rounded bg-[#161A27]"
                                        onBlur={() => onChangeParamsValue(fields, index, values)}
                                        onChange={(e) => {
                                          input.onChange(e);
                                          onChangeParamsValue(fields, index, values);
                                        }}
                                      />
                                    )}
                                  />
                                  {fields.length > 1 && (
                                    <Trash2
                                      type="button"
                                      onClick={() => fields.remove(index)}
                                      className="focus:outline-none cursor-pointer"
                                      size={18}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                    </FieldArray>
                  </div>
                </div>
                <div className="flex flex-row mt-4 gap-4 w-full mb-4">
                  <div className="w-1/2 justify-start items-center">
                    <Button
                      type="submit"
                      className="bg-[#222938] text-white h-10 w-60 text-base font-medium gap-1 border-[#444444] border">
                      <Plus />
                      New Test
                    </Button>
                  </div>
                  <div className="flex w-1/2 justify-end gap-4 ">
                    <Button
                      type="button"
                      className="bg-[#222938] text-white h-10 w-60 text-base font-medium border-[#444444] border">
                      Discard Changes
                    </Button>
                    <Button type="submit" className=" text-white h-10 w-60 text-base font-medium">
                      Run Test
                    </Button>
                  </div>
                </div>
              </form>

            </>
          )}
        />
      </div>
    </main>
  );
};

export default withLoader(withUser(Loadwebsite));

