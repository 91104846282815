import React from 'react';

const RecommendationIcon = () => {
  return (
    <svg width="17" height="21" viewBox="0 0 21 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.447 13.4053C15.8515 13.2611 16.1222 12.8753 16.1222 12.4459C16.1222 12.0165 15.8505 11.6307 15.448 11.4865L13.5978 10.8196C11.7703 10.1621 10.3453 8.73498 9.68682 6.90871L9.01994 5.05843C8.87474 4.65603 8.48996 4.38428 8.06265 4.38428H8.06161C7.63327 4.38428 7.24849 4.65496 7.10225 5.05843L6.43641 6.90871C5.77781 8.73615 4.35174 10.1623 2.5244 10.8196L0.676224 11.4865C0.271748 11.6317 0 12.0165 0 12.4459C0 12.8753 0.271721 13.26 0.674153 13.4053L2.52443 14.0721C4.35187 14.7297 5.77691 16.1558 6.43537 17.9831L7.10225 19.8344C7.24745 20.2368 7.63327 20.5075 8.06161 20.5075H8.06265C8.49099 20.5075 8.87577 20.2358 9.01994 19.8334L9.68682 17.9831C10.3444 16.1556 11.7715 14.7306 13.5978 14.0721L15.447 13.4053ZM13.0357 12.5102C10.7415 13.3368 8.95133 15.1259 8.12587 17.42L8.06156 17.5994L7.99726 17.42C7.16962 15.1258 5.38054 13.3357 3.08747 12.5102L2.90805 12.4459L3.08747 12.3816C5.38163 11.555 7.17179 9.7659 7.99726 7.4718L8.06156 7.29238L8.12587 7.4718C8.95247 9.76596 10.7416 11.5561 13.0357 12.3816L13.2151 12.4459L13.0357 12.5102Z"
        fill="white"
      />
      <path
        d="M12.5993 5.362L13.5358 5.7001C14.3469 5.99258 14.9775 6.6242 15.2699 7.43419L15.607 8.36968C15.7387 8.73683 16.0893 8.98264 16.4782 8.98264C16.8671 8.98264 17.2187 8.7358 17.3494 8.37072L17.6875 7.43419C17.9799 6.62314 18.6116 5.99152 19.4216 5.7001L20.3591 5.362C20.7232 5.23028 20.97 4.88077 20.971 4.49288C20.9721 4.105 20.7283 3.75445 20.3591 3.6196L19.4216 3.28357C18.6115 2.99109 17.9799 2.35947 17.6875 1.54948L17.3494 0.612953C17.2177 0.245808 16.8681 0 16.4792 0C16.0892 0 15.7387 0.246841 15.608 0.611918L15.2699 1.54845C14.9774 2.3595 14.3458 2.99008 13.5358 3.28254L12.5962 3.62064C12.2311 3.75339 11.9853 4.10394 11.9863 4.49288C11.9884 4.8818 12.2342 5.23132 12.5993 5.362ZM16.4792 2.86664C16.8723 3.5408 17.4313 4.09876 18.1034 4.49081C17.4292 4.8839 16.8712 5.4429 16.4792 6.11498C16.0861 5.44187 15.5271 4.88286 14.855 4.49081C15.5281 4.09773 16.0861 3.53976 16.4792 2.86664Z"
        fill="white"
      />
      <path
        d="M20.3581 19.5293L19.4215 19.1912C18.6115 18.8987 17.9799 18.2671 17.6874 17.4571L17.3493 16.5206C17.2176 16.1545 16.8671 15.9087 16.4782 15.9087C16.0882 15.9087 15.7376 16.1555 15.607 16.5206L15.2689 17.4571C14.9764 18.2682 14.3448 18.8998 13.5348 19.1912L12.5993 19.5283C12.2332 19.659 11.9874 20.0095 11.9863 20.3974C11.9853 20.7853 12.2301 21.1369 12.5983 21.2707L13.5348 21.6088C14.3458 21.9013 14.9774 22.5319 15.2689 23.3429L15.6059 24.2784C15.7397 24.6445 16.0892 24.8913 16.4792 24.8913C16.8692 24.8913 17.2197 24.6445 17.3504 24.2794L17.6885 23.3429C17.981 22.5329 18.6126 21.9012 19.4226 21.6088L20.3633 21.2707C20.7273 21.1369 20.9721 20.7874 20.9711 20.3984C20.9711 20.0095 20.7231 19.661 20.3581 19.5293ZM16.4792 22.0247C16.0862 21.3505 15.5272 20.7926 14.8551 20.4005C15.5282 20.0074 16.0872 19.4484 16.4792 18.7763C16.8723 19.4495 17.4313 20.0085 18.1034 20.4005C17.4314 20.7936 16.8723 21.3516 16.4792 22.0247Z"
        fill="white"
      />
    </svg>
  );
};

export default RecommendationIcon;
