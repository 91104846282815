import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom'; // Navigate
import { persistor, store } from './redux/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
// import { isEmpty } from 'lodash';
import Global from '../src/components/Global/Global';

import './App.css';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
// import Load from './pages/Load';
import LoadIndex from './pages/Load/loadIndex';
import LoadTestForm from './pages/Load/loadTestForm';
import NotFound from '../src/pages/NotFound';
import Layout from '../src/Layout';
import Signup from '../src/pages/Signup';
import Security from '../src/pages/Security';
import ForgotPassword from '../src/pages/ForgotPassword';
import ResetPassword from '../src/pages/ResetPassword';
import Test from './pages/Architecture';
import ArchitectureInfo from './pages/Architecture/architectureinfo';
import CloudComparison from './pages/Architecture/cloudcomparison';
import Prerequisite from './pages/Prerequisite';
import { generateToken, messaging } from '../src/notifications/firebase';
import { onMessage } from 'firebase/messaging';
import { useToast } from '../src/components/ui/use-toast';
import Subscriptions from './pages/My Subscriptions/Subscriptions';
import Architecture from './components/Architecture/Form';
import UserManagementForm from './pages/UserManagement_Admin/UserManagementForm';
import SubscriptionNew from './pages/My Subscriptions/SubscriptionNew';
import Profile from './pages/Profile';
import Loading from './pages/Loading';
import { isEmpty } from 'lodash';
import SecurityResult from './pages/Security/SecurityResult';
import Loadwebsite from './pages/Load/loadWebsite';
import LoadDatabaseForm from './pages/Load/loadDatabaseForm';
import LoadGraphql from './pages/Load/loadGraphql';
import LoadSoapapi from './pages/Load/loadSoapapi';
import LoadWebSocket from './pages/Load/loadWebSocket';
import LoadTestResults from './pages/Load/loadTestResults';
import LoadMessagingBroker from './pages/Load/loadMessagingBroker';
const ValidateRoute = ({ children }) => {
  const { user } = store.getState();
  if (isEmpty(user.user_id)) {
    return <Navigate to={'/login'} replace />;
  }
  return children;
};
function App() {
  const { toast } = useToast();
  useEffect(() => {
    generateToken();
    onMessage(messaging, (payload) => {
      toast({
        variant: 'success',
        title: payload.notification.title,
        description: payload.notification.body
      });
    });
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Global />
        <Routes>
          <Route path="/loading" element={<Loading />} />
          <Route path="/" element={<Layout />}>
            <Route
              path="/"
              element={
                <ValidateRoute>
                  <Dashboard />
                </ValidateRoute>
              }
            />
            <Route
              path="/load"
              element={
                <ValidateRoute>
                  <LoadIndex />
                </ValidateRoute>
              }
            />
            <Route
              path="/load/test"
              element={
                <ValidateRoute>
                  <LoadTestForm />
                </ValidateRoute>
              }
            />
            <Route
              path="/load/database"
              element={
                <ValidateRoute>
                  <LoadDatabaseForm />
                </ValidateRoute>
              }
            />
            <Route
              path="/load/websockets"
              element={
                <ValidateRoute>
                  <LoadWebSocket />
                </ValidateRoute>
              }
            />
            <Route
              path="/load/graphql"
              element={
                <ValidateRoute>
                  <LoadGraphql />
                </ValidateRoute>
              }
            />
            <Route
              path="/load/soap-api"
              element={
                <ValidateRoute>
                  <LoadSoapapi />
                </ValidateRoute>
              }
            />
            <Route
              path="/load/website"
              element={
                <ValidateRoute>
                  <Loadwebsite />
                </ValidateRoute>
              }
            />
            <Route
              path="/load/messaging-broker"
              element={
                <ValidateRoute>
                  <LoadMessagingBroker />
                </ValidateRoute>
              }
            />
            <Route
              path="/load/test-results/:test_name"
              element={
                <ValidateRoute>
                  <LoadTestResults />
                </ValidateRoute>
              }
            />
            <Route
              path="/security"
              element={
                <ValidateRoute>
                  <Security />
                </ValidateRoute>
              }
            />
            <Route
              path="/security/result/:id/:name"
              element={
                <ValidateRoute>
                  <SecurityResult />
                </ValidateRoute>
              }
            />
            <Route
              path="/architecture"
              element={
                <ValidateRoute>
                  <Test />
                </ValidateRoute>
              }
            />
            <Route
              path="/architecture/form"
              element={
                <ValidateRoute>
                  <Architecture />
                </ValidateRoute>
              }
            />
            <Route
              path="/architecture/info/:id/:name"
              element={
                <ValidateRoute>
                  <ArchitectureInfo />
                </ValidateRoute>
              }
            />
            <Route
              path="/architecture/comparison"
              element={
                <ValidateRoute>
                  <CloudComparison />
                </ValidateRoute>
              }
            />

            <Route
              path="/plans"
              element={
                <ValidateRoute>
                  <SubscriptionNew />
                </ValidateRoute>
              }
            />
            <Route
              path="/subscriptions"
              element={
                <ValidateRoute>
                  <Subscriptions />
                </ValidateRoute>
              }
            />
            <Route
              path="/prerequisite"
              element={
                <ValidateRoute>
                  <Prerequisite />
                </ValidateRoute>
              }
            />
            <Route
              path="/admin"
              element={
                <ValidateRoute>
                  <UserManagementForm />
                </ValidateRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <ValidateRoute>
                  <Profile />
                </ValidateRoute>
              }
            />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />

          {/* <Route path="/signup-password" element={<Password />} /> */}

          <Route path="/*" element={<NotFound />} />
        </Routes>
      </PersistGate>
    </Provider>
  );
}

export default App;
