import { QuestionMarkCircledIcon } from '@radix-ui/react-icons';
import { Card, CardTitle, CardHeader, CardDescription, CardFooter } from '../ui/card';
// import { Progress } from '../ui/progress';
// import { GreenMetricIcon } from '../../assets/icons';
// import { useEffect, useState } from 'react';

const NormalCard = ({ title, numberOfTest, denominator }) => {
  // const [progressValue, setProgressValue] = useState('');

  // useEffect(() => {
  //   if (denominator) {
  //     const progressValue = (numberOfTest / denominator) * 100;
  //     setProgressValue(progressValue);
  //   } else {
  //     setProgressValue(0);
  //   }
  // }, []);

  return (
    <div className="md:w-[250px] w-[220px] h-full manrope-fontCss mt-2">
      <Card x-chunk="dashboard-05-chunk-1 w-full" className={`h-[85%] flex flex-col mb-6`}>
        <CardHeader className=" flex p-4 px-6 pb-0">
          <CardTitle className="font-semibold h-full flex items-center gap-2 text-xl ">
            <p>{title}</p>
            <div>
              <QuestionMarkCircledIcon color="#616161" className="" width={19} height={19} />
            </div>
          </CardTitle>
        </CardHeader>
        <CardDescription>
          <div className="flex flex-col items-start mt-3">
            <p className="text-6xl leading-none font-bold text-white">
              {numberOfTest}
              {denominator && <span className="text-[#787878] font-normal">/{denominator}</span>}
            </p>
          </div>
          {/* {
          denominator && (
            <div className="flex justify-between items-center">
              <Progress
                className="h-3 p-[1px] w-[90%] border-[#272D40] border-2 rounded-full bg-[#272D40]"
                value={progressValue}
                aria-label={`${progressValue}% progress`}
              />
            </div>
          )} */}
          {/* {metrics && (
            <div
              className={`flex items-center mt-0 text-base md:text-sm gap-1 ${denominator ? 'mt-2' : 'mt-0'}`}>
              <GreenMetricIcon />
              <p className="text-[#22C55E]">{metrics}</p>
            </div>
          )} */}
          <CardFooter></CardFooter>
        </CardDescription>
      </Card>
    </div>
  );
};
export default NormalCard;
