import React from 'react';
import { Field, Form } from 'react-final-form';
import { useNavigate } from 'react-router-dom';

import arrayMutators from 'final-form-arrays';
import { Label } from '../../components/ui/label';
import { renderField } from '../../helpers/form-validations';
import { useToast } from '../../components/ui/use-toast';
import { Button } from '../../components/ui/button';

import withLoader from '../../redux/Hoc/withLoader';
import withUser from '../../redux/Hoc/withUser';
import { loadTestKafka } from '../../utils/util';


const LoadMessagingBroker = ({ user, setLoader }) => {


	const { toast } = useToast();
	const navigate = useNavigate();

	const onSubmit = async (formData) => {

		try {
			const payload = {
				user_id: user?.user_id,
				kafka_broker: formData?.kafka_broker,
				kafka_topic: formData?.kafka_topic,
				kafka_message: formData?.kafka_message,
				virtual_user: formData?.virtualUsers,
				test_name: formData?.testName,
				enterprise_id: user?.enterprise_id,
				restart: false,
				subscription_id: user?.activated_subscription_id,
				object_id: user?.object_id,
				environment: user?.environment || "dev"
			};
			setLoader(true);
			const loadTestRes = await loadTestKafka(payload);
			setLoader(false);
			handleApiResponse(loadTestRes.data);
			
		} catch (e) {
			console.log('Error', e);
			toast({
				variant: 'destructive',
				title: 'Error',
				description: e.message
			});
		}
	};

	const handleApiResponse = (response) => {
		console.log(response);
		switch (response.status) {
			case 'success':
				toast({ description: response.message, variant: 'success' });
				navigate('/load')
				break;
			case 'error':
				switch (response.code) {
					case 404:
						toast({ description: response.message, variant: 'error' });
						break;
					case 400:
						toast({ description: response.message, variant: 'error' });
						break;
					case 500:
						toast({ description: response.message, variant: 'error' });
						break;
					default:
						toast({ description: 'An unknown error occurred.', variant: 'error' });
				}
				break;
			default:
				toast({ description: 'Unexpected response from the server.', variant: 'warning' });
		}
	};
	const required = (value) => (value ? undefined : 'Required');

	const composeValidators =
		(...validators) =>
			(value) =>
				validators.reduce((error, validator) => error || validator(value), undefined);


	return (
		<main className="grid flex-1 items-start gap-4 p-4  sm:py-0 md:gap-8 w-full ">
			<div className="grid auto-rows-max items-start gap-4 md:gap-8 lg:col-span-2 manrope-fontCss">
				<Form
					onSubmit={onSubmit}
					initialValues={{
					}}
					mutators={{
						...arrayMutators
					}}
					render={({ handleSubmit }) => (
						<>
							<form onSubmit={handleSubmit}>
								<div className="grid gap-4 w-full">
									<div className="flex w-full pt-2 gap-2">
										<div className="flex-1 gap-4 font-semibold text-lg">
											<Label htmlFor="testName" className={`mb-4`}>
												Test Name
											</Label>
											<Field
												className="w-full bg-[#161A27] mt-2"
												id="testName"
												type="text"
												name="testName"
												placeholder="Write test name like “Load Test 1”, etc. "
												validate={composeValidators(required)}>
												{renderField}
											</Field>
										</div>

										<div className="flex-1 gap-4 gap-y-2 font-semibold text-lg">
											<Label htmlFor="kafka_message" className={`mb-4`}>
												Message
											</Label>
											<Field
												className="w-full bg-[#161A27] mt-2"
												id="kafka_message"
												type="text"
												name="kafka_message"
												placeholder="Enter Message Here"
												validate={composeValidators(required)}>
												{renderField}
											</Field>
										</div>
									</div>

									<div className="flex w-full pt-2 gap-2">
										<div className="flex-1 gap-4 font-semibold text-lg">
											<Label htmlFor="kafka_broker" className={`mb-4`}>
												Broker
											</Label>
											<Field
												className="w-full bg-[#161A27] mt-2"
												id="kafka_broker"
												type="text"
												name="kafka_broker"
												placeholder="Enter Broker Here"
												validate={composeValidators(required)}>
												{renderField}
											</Field>
										</div>

										<div className="flex-1 gap-4 gap-y-2 font-semibold text-lg">
											<Label htmlFor="kafka_topic" className={`mb-4`}>
												Topic
											</Label>
											<Field
												className="w-full bg-[#161A27] mt-2"
												id="kafka_topic"
												type="text"
												name="kafka_topic"
												placeholder="Enter Topic here"
											>
												{renderField}
											</Field>
										</div>
									</div>
									<div className="flex w-full pt-2 gap-2">
										<div className="flex-1 gap-4 font-semibold text-lg">
											<Label htmlFor="virtualUsers" className={`mb-4`}>
												Virtual Users
											</Label>
											<Field
												className="w-full bg-[#161A27] mt-2"
												id="virtualUsers"
												type="text"
												name="virtualUsers"
												placeholder="Enter number of Virtual Users here "
												validate={composeValidators(required)}>
												{renderField}
											</Field>
										</div>

										{/* <div className="flex-1 gap-4 gap-y-2 font-semibold text-lg">
                      <Label htmlFor="url" className={`mb-4`}>
                      URL
                      </Label>
                      <Field
                        className="w-full bg-[#161A27] mt-2"
                        id="url"
                        type="text"
                        name="url"
                        placeholder="Add URL here"
                        validate={composeValidators(required)}>
                        {renderField}
                      </Field>
                    </div> */}
									</div>

								</div>
								<div className="flex flex-row mt-4 gap-4 w-full mb-4">
									<div className="w-1/2 justify-start items-center">
										{/* <Button
											type="submit"
											className="bg-[#222938] text-white h-10 w-60 text-base font-medium gap-1 border-[#444444] border">
											<Plus />
											New Test
										</Button> */}
									</div>
									<div className="flex w-1/2 justify-end gap-4 ">
										<Button
											type="submit"
											className="bg-[#222938] text-white h-10 w-60 text-base font-medium border-[#444444] border">
											Discard Changes
										</Button>
										<Button type="submit" className=" text-white h-10 w-60 text-base font-medium">
											Run Test
										</Button>
									</div>
								</div>
							</form>

						</>
					)}
				/>
			</div>
		</main>
	);
};


export default withLoader(withUser(LoadMessagingBroker));
