import React from 'react';
// import { ChevronLeft, ChevronRight } from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '../../components/ui/dropdown-menu';
import { Button } from '../../components/ui/button';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const pageOptions = Array.from({ length: totalPages }, (_, i) => i + 1);

  return (
    <div className="flex items-center justify-end space-x-4 w-40 h-10 opacity-100">
      {/* <Button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        variant="ghost"
        size="icon"
        className="p-1 rounded-md hover:bg-gray-700 disabled:opacity-50 disabled:cursor-not-allowed">
        <ChevronLeft className="h-5 w-5" />
      </Button> */}
      <div className="flex items-center space-x-3 p-4">
        <span className="text-sm text-white">Page</span>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              variant="outline"
              className="w-14 h-8 bg-transparent border-[#747474] text-white">
              {currentPage}
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="bg-[#222938] text-white">
            {pageOptions.map((page) => (
              <DropdownMenuItem
                key={page}
                onSelect={() => onPageChange(page)}
                className="cursor-pointer hover:bg-gray-700">
                {page}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
        <span className="text-sm text-white">of</span>
        <span>{totalPages}</span>
      </div>
      {/* <Button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        variant="ghost"
        size="icon"
        className="p-1 rounded-md hover:bg-gray-700 disabled:opacity-50 disabled:cursor-not-allowed">
        <ChevronRight className="h-5 w-5" />
      </Button> */}
    </div>
  );
};

export default Pagination;
