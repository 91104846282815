import DashTable from '../../components/Admin_UserManagement/DashTable';
import React, { useEffect, useState } from 'react';
import { deleteIcon, download } from '../../assets/icons2/index';
import NormalCard from '../../components/Cards/normal-card';
import ButtonCard from '../../components/Cards/SecurityButtonCard';
import { getSecurityTestResults } from '../../utils/util';
import withUser from '../../redux/Hoc/withUser';
import withLoader from '../../redux/Hoc/withLoader';

const Index = ({ user, setLoader }) => {
  // import { useSelector } from 'react-redux';

  // const collapsed = useSelector((state) => state.toggle);
  const tabsConfig = [{ value: 'security', label: 'Security' }];
  const [page] = useState(1);
  const [data, setData] = useState(null); // Initialize with null

  const columns = [
    { id: 'testDetails', header: 'Test Details' },
    { id: 'type', header: 'Type' },
    { id: 'status', header: 'Status' },
    { id: 'startTime', header: 'Start Time' }
  ];

  // const data = {
  //   security: [
  //     {
  //       id: 'uygb1213',
  //       testDetails: 'Security Team 1',
  //       url: 'https://security-team1.com',
  //       status: 'Started',
  //       duration: '1h 30m 0s',
  //       startTime: '04/19/2024 08:43:26 PM'
  //     },
  //     {
  //       id: 'uygb1213',
  //       testDetails: 'Security Team 2',
  //       url: 'https://security-team2.com',
  //       status: 'Not Started',
  //       duration: '2h 15m 0s',
  //       startTime: '05/01/2024 10:00:00 AM'
  //     },
  //     {
  //       id: 'uygb1213',
  //       testDetails: 'Security Team 3',
  //       url: 'https://security-team3.com',
  //       status: 'Success',
  //       duration: '45m 0s',
  //       startTime: '05/15/2024 01:30:45 PM'
  //     },
  //     {
  //       id: 'uygb1213',
  //       testDetails: 'Security Team 4',
  //       url: 'https://security-team4.com',
  //       status: 'Failed',
  //       duration: '3h 0m 0s',
  //       startTime: '06/01/2024 09:00:00 AM'
  //     },
  //     {
  //       id: 'uygb1213',
  //       testDetails: 'Security Team 5',
  //       url: 'https://security-team5.com',
  //       status: 'Completed',
  //       duration: '1h 20m 0s',
  //       startTime: '06/10/2024 04:45:00 PM'
  //     },
  //     {
  //       id: 'uygb1213',
  //       testDetails: 'Security Team 6',
  //       url: 'https://security-team6.com',
  //       status: 'Pending',
  //       duration: '2h 0m 0s',
  //       startTime: '07/01/2024 11:00:00 AM'
  //     },
  //     {
  //       id: 'uygb1213',
  //       testDetails: 'Security Team 7',
  //       url: 'https://security-team7.com',
  //       status: 'Completed',
  //       duration: '1h 50m 0s',
  //       startTime: '07/15/2024 03:30:00 PM'
  //     },
  //     {
  //       id: 'uygb1213',
  //       testDetails: 'Security Team 8',
  //       url: 'https://security-team8.com',
  //       status: 'In Progress',
  //       duration: '2h 30m 0s',
  //       startTime: '08/01/2024 08:00:00 AM'
  //     },
  //     {
  //       id: 'uygb1213',
  //       testDetails: 'Security Team 9',
  //       url: 'https://security-team9.com',
  //       status: 'Failed',
  //       duration: '1h 0m 0s',
  //       startTime: '08/10/2024 02:15:00 PM'
  //     },
  //     {
  //       id: 'uygb1213',
  //       testDetails: 'Security Team 10',
  //       url: 'https://security-team10.com',
  //       status: 'Completed',
  //       duration: '1h 40m 0s',
  //       startTime: '09/01/2024 10:30:00 AM'
  //     }
  //   ]
  // };

  const buttonOptions = [
    {
      icon: download,
      name: 'Download',
      alt: 'Send Icon',
      text: <span className="text-base font-semibold">Download Certificate</span>,
      iconHeight: 4,
      iconWidth: 18,
      iconColor: 'text-[#FFFFFF]'
    },
    {
      icon: deleteIcon,
      alt: '',
      name: 'Delete',
      text: <span className="text-base font-semibold">Delete</span>,
      iconHeight: 17,
      iconWidth: 17,
      iconColor: 'text-[#FFFFFF]'
    }
  ];

  const accordionTab = [
    { title: 'Title 1', subtitles: ['Subtitle 1.1', 'Subtitle 1.2'] },
    { title: 'Title 2', subtitles: ['Subtitle 2.1', 'Subtitle 2.2'] },
    { title: 'Title 3', subtitles: ['Subtitle 3.1', 'Subtitle 3.2'] },
    { title: 'Title 4', subtitles: ['Subtitle 3.1', 'Subtitle 3.2'] },
    { title: 'Title 5', subtitles: ['Subtitle 3.1', 'Subtitle 3.2'] },
    { title: 'Title 6', subtitles: ['Subtitle 3.1', 'Subtitle 3.2'] },
    { title: 'Title 7', subtitles: ['Subtitle 3.1', 'Subtitle 3.2'] }
  ];
  const [securityAvailableForAsPerPlan,setSecurityAvailableForAsPerPlan]=useState()
  const [countOfSecurityTest,setCountOfSecurityTest]=useState(0)

  useEffect(()=>{
  if(user){
    if(user?.subscription_plan.toLowerCase()==="basic"){
      setSecurityAvailableForAsPerPlan(1)
    }
    else if(user?.subscription_plan.toLowerCase()==="standard"){
      setSecurityAvailableForAsPerPlan(5)
    }
    else if(user?.subscription_plan.toLowerCase()==="pro"){
      setSecurityAvailableForAsPerPlan(30)
    }
    else{
      securityAvailableForAsPerPlan(0)
      
    }
  }
  },[user])

  
  const url = '/';
  const buttonName = 'Start';
  const buttonTitle = 'Security Test';
  const cardData = [
    { title: 'No of Security', numberOfTest:countOfSecurityTest || 0,
      // denominator:securityAvailableForAsPerPlan 
    },
    // { title: 'Risk', numberOfTest: 32, denominator: 50}
  ];
  const fetchData = async () => {
     

    try {
      const payload = {
        user_id: user.user_id,
        enterprise_id: user.enterprise_id ? user.enterprise_id : null,
        page_number: page,
        per_page: 10,
        object_id: user.object_id,
        subscription_id: user.activated_subscription_id
      };
      const response = await getSecurityTestResults(payload);

      setLoader(false);
      console.log("count:",response?.data.count)
      setCountOfSecurityTest(response?.data.count)
      setData({ security: response.data.data });
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {

   
 setLoader(true);
   

    fetchData();
  }, [user,countOfSecurityTest]);

  useEffect(()=>{
    console.log("hello sekar")
    setInterval(() => {
      fetchData()
    }, 5000);
  },[])

  //after delete teh data in dashtable.jsx it should update in index.js 

  const handleUpdateData = (updatedData) => {
    console.log("updated data",updatedData)
    setData({ security: updatedData });
  };


  return (
    <main className="grid flex-1 items-start gap-4 p-4 sm:py-0 md:gap-8 w-full">
      <div className="grid auto-rows-max items-start gap-4 md:gap-8 lg:col-span-2 manrope-fontCss">
        <div className={`flex h-full w-full gap-4`}>
          {cardData.map((card, index) => (
            <NormalCard
              key={index}
              title={card.title}
              numberOfTest={card.numberOfTest}
              denominator={card.denominator}
            />
          ))}
          <ButtonCard
            title={buttonTitle}
            buttonName={buttonName}
            url={url}
            setData={setData}
            page={page}
          />
        </div>
        <DashTable
        updatedData={handleUpdateData}
          tabsConfig={tabsConfig}
          columns={columns}
          data={data != 'None' ? (data ? data : {}) : { security: null }}
          buttonType={'Download'}
          buttonOptions={buttonOptions}
          accordionTab={accordionTab}
          page={page}
          name={'security'}
        />
      </div>
    </main>
  );
};

export default withLoader(withUser(Index));
