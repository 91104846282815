import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { QuestionMarkCircledIcon } from '@radix-ui/react-icons';
import { Card, CardTitle, CardHeader, CardContent } from '../ui/card';
import { Progress } from '../ui/progress';
import { GreenMetricIcon, RightArrowIcon } from '../../assets/icons';
import { Button } from '../ui/button';

const NormalCardDash = ({
  title,
  numberOfTest,
  denominator,
  metrics,
  buttonName,
  url,
  className = '',
  containerClassName = ''
}) => {
  const [progressValue, setProgressValue] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (denominator) {
      const calculatedProgress = (numberOfTest / denominator) * 100;
      setProgressValue(calculatedProgress);
    } else {
      setProgressValue(0);
    }
  }, [numberOfTest, denominator]);

  const handleButtonClick = () => {
    if (url) {
      navigate(url);
    }
  };

  return (
    <div className={`md:w-[250px] w-[220px] h-full mt-2 ${containerClassName}`}>
      <Card x-chunk="dashboard-05-chunk-1 w-full" className={`h-[85%] mb-6 ${className}`}>
        <CardHeader className="flex p-4 px-6 pb-0">
          <CardTitle className="font-semibold h-full flex items-center gap-2 text-xl">
            <p>{title}</p>
            <QuestionMarkCircledIcon color="#616161" width={19} height={19} />
          </CardTitle>
        </CardHeader>
        <CardContent>
          {numberOfTest !== undefined ? (
            <>
              <p className="text-6xl leading-none font-bold mb-3 mt-3">
                {numberOfTest}
                {denominator && <span className="text-[#787878] font-normal">/{denominator}</span>}
              </p>
              {denominator && (
                <div className="flex items-center space-x-5 mt-4">
                  <Progress
                    className="h-3 p-[1px] w-[90%] border-[#272D40] border-2 rounded-full bg-[#272D40]"
                    value={progressValue}
                    aria-label={`${progressValue}% progress`}
                  />
                </div>
              )}
              {metrics && (
                <div className={`flex items-center mt-2 text-base md:text-sm gap-1`}>
                  <GreenMetricIcon />
                  <p className="text-[#22C55E]">{metrics}</p>
                </div>
              )}
            </>
          ) : (
            <div className={`flex text-base md:text-sm mt-16`}>
              <Button
                className="w-full space-x-2 flex justify-between "
                onClick={handleButtonClick}>
                <p className="text-white font-bold text-base">{buttonName}</p>
                <div className="bg-white h-7 w-7 flex items-center p-0.5 justify-center border-none rounded-full">
                  <RightArrowIcon />
                </div>
              </Button>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default NormalCardDash;
