export default function DangerProfile() {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="24" cy="24" r="24" fill="#EF4444" fillOpacity="0.27" />
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        x="12"
        y="15"
        transform="translate(-12, -9)">
        <path
          d="M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21508 0.913451 7.4078C0.00519943 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.807 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0865C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6934 24 14.3734 24 12C23.9966 8.81843 22.7313 5.76814 20.4816 3.51843C18.2319 1.26872 15.1816 0.00335979 12 0ZM12 22.1538C9.99176 22.1538 8.02862 21.5583 6.35883 20.4426C4.68904 19.3269 3.38759 17.7411 2.61907 15.8857C1.85055 14.0303 1.64947 11.9887 2.04126 10.0191C2.43305 8.04943 3.40011 6.24019 4.82015 4.82015C6.24019 3.4001 8.04943 2.43304 10.0191 2.04126C11.9887 1.64947 14.0303 1.85055 15.8857 2.61907C17.7411 3.38759 19.3269 4.68903 20.4426 6.35882C21.5583 8.02861 22.1538 9.99176 22.1538 12C22.1508 14.692 21.08 17.2729 19.1765 19.1765C17.2729 21.08 14.692 22.1508 12 22.1538ZM11.0769 12.9231V6.46154C11.0769 6.21672 11.1742 5.98193 11.3473 5.80882C11.5204 5.63571 11.7552 5.53846 12 5.53846C12.2448 5.53846 12.4796 5.63571 12.6527 5.80882C12.8258 5.98193 12.9231 6.21672 12.9231 6.46154V12.9231C12.9231 13.1679 12.8258 13.4027 12.6527 13.5758C12.4796 13.7489 12.2448 13.8462 12 13.8462C11.7552 13.8462 11.5204 13.7489 11.3473 13.5758C11.1742 13.4027 11.0769 13.1679 11.0769 12.9231ZM13.3846 17.0769C13.3846 17.3508 13.3034 17.6185 13.1513 17.8462C12.9991 18.0739 12.7829 18.2513 12.5299 18.3561C12.2769 18.4609 11.9985 18.4884 11.7299 18.4349C11.4613 18.3815 11.2146 18.2496 11.0209 18.056C10.8273 17.8623 10.6954 17.6156 10.642 17.347C10.5886 17.0785 10.616 16.8001 10.7208 16.5471C10.8256 16.294 11.0031 16.0778 11.2308 15.9257C11.4585 15.7735 11.7262 15.6923 12 15.6923C12.3672 15.6923 12.7194 15.8382 12.9791 16.0978C13.2387 16.3575 13.3846 16.7097 13.3846 17.0769Z"
          fill="#EF4444"
        />
      </svg>
    </svg>
  );
}
