import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyAMv4rBdCtm_8QcVNiez0pc-h6pUdDl3TQ',
  authDomain: 'scaledev-f795f.firebaseapp.com',
  projectId: 'scaledev-f795f',
  storageBucket: 'scaledev-f795f.appspot.com',
  messagingSenderId: '391475286525',
  appId: '1:391475286525:web:f8f3636e6e171b9c2b8994',
  measurementId: 'G-RV4D9PVMJ3'
};

const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const generateToken = async () => {
  const permission = await Notification.requestPermission();
  console.log('Hello');

  console.log(permission);
  if (permission === 'granted') {
    // console.log('aaa');
    const token = await getToken(messaging, {
      vapidKey:
        'BLt_hIXxxU3J8OT-yr15Hp2c18oRMiNuICxIFif3YXIaNgS-Eb1Sf5O-Bd7qz4j_mw6j57WDr0Wrk-4yIOx83I4'
    });
    console.log('token', token);
    return token;
  } else if (permission === 'denied') {
    return null;
  }
};
