import React, { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { LOGIN_TYPE } from '../../constants';
import { useNavigate } from 'react-router-dom';
import { isBusinessEmail } from '../../helpers/global';
import { getUserDetailAfterGoogleLogin } from '../../utils/util';
import { toast } from '../../components/ui/use-toast';
import withUser from '../../redux/Hoc/withUser';
import { Spinner } from 'react-activity';
import { generateToken } from '../../notifications/firebase';

const Loading = ({ setUser }) => {
  const { instance, inProgress, accounts } = useMsal();
  const navigate = useNavigate();
  useEffect(() => {
    if (accounts.length > 0 && inProgress === InteractionStatus.None) {
      handleMicrosoftLoginSuccess();
    }
  }, [accounts, inProgress]);

  const handleMicrosoftLoginSuccess = async () => {
    console.log('Microsoft login successful');
    const account = instance.getAllAccounts()[0];

    console.log('Acquiring token silently');
    const response = await instance.acquireTokenSilent({
      scopes: ['user.read'],
      account: account
    });

    console.log('Token acquired silently');

    const userDetail = await getUserMicrosoftDetails(response.accessToken);
    setUser({
      ...userDetail,
      token: response.accessToken
    });

    if (!isBusinessEmail(userDetail.email)) {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'You can only register with business email!'
      });
      return false;
    }
    const device_id = await generateToken();

    const loginDetail = await getUserDetailAfterGoogleLogin({
      email: userDetail.email,
      access_token: response.accessToken,
      user_name: userDetail.name,
      client_name: userDetail.email.split('@')[1],
      type: LOGIN_TYPE.OAUTH,
      device_id: device_id
    });
    console.log('Microsoft login details:', loginDetail);

    if (loginDetail.data.status) {
      setUser({ ...loginDetail.data.data });
      console.log('Navigating to dashboard');
      navigate('/');
      toast({
        variant: 'success',
        title: 'Success',
        description: loginDetail.data.message
      });
    } else {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: loginDetail.data.message
      });
    }
  };

  const getUserMicrosoftDetails = async (accessToken) => {
    try {
      console.log('Fetching Microsoft user details');
      const response = await fetch('https://graph.microsoft.com/v1.0/me', {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch user details from Microsoft Graph API');
      }

      const data = await response.json();

      return {
        email: data.mail || data.userPrincipalName,
        name: data.displayName,
        id: data.id
      };
    } catch (error) {
      console.error('Error fetching Microsoft user details:', error.message);
      // Handle the error as needed, for example by returning a default value or rethrowing the error
      return {
        email: null,
        name: null,
        id: null
      };
    }
  };
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-black opacity-75 flex flex-col items-center justify-center">
      <Spinner size={30} color="white" />
    </div>
  );
};

export default withUser(Loading);
