import React from 'react';
import withLoader from '../../redux/Hoc/withLoader';
import Loader from '../Loader/index';
import { Toaster } from '../../components/ui/toaster';

const Global = ({ loader }) => {
  return (
    <>
      <Toaster />
      {loader && <Loader />}
    </>
  );
};

export default withLoader(Global);
