import apiClient from '../utils/axiosConfig';
import { middlewareApiClient } from '../utils/axiosConfig';
export const login = (data) => {
  return apiClient
    .post('/user/login', data)
    .then(async (response) => response)
    .catch((error) => error);
};
export const generateOtp = (data) => {
  return apiClient
    .post('/user/sendotp', data)
    .then(async (response) => response)
    .catch((error) => error);
};

// export const downloadPDF = async (url,testid,type) => {
//   try {
//     console.log("api client ",apiClient)
//     const response = await apiClient.get(`/download/report?url=${url}&test_id=${testid}&type=${type}`);
//     console.log("response from utils",response)
//     return response;
//   } catch (error) {
//     console.error('Error downloading PDF:', error);
//     return error;
// }
// };

export const downloadPDF = async (url, testid, type) => {
  try {
    // Construct the URL with query parameters
    const apiUrl = `/download/report?url=${encodeURIComponent(url)}&test_id=${encodeURIComponent(testid)}&type=${encodeURIComponent(type)}`;

    // Fetch the PDF file from the server
    const response = await apiClient.get(apiUrl, { responseType: 'blob' });

    console.log("hii from utils link for certificate",response)
    // Check if the content-type is 'application/pdf'
    const contentType = response.headers['content-type'];
    console.log('Content-Type:', contentType);
    

    const generatedUrl = response.request.responseURL;

    if (!generatedUrl) {
      throw new Error("URL not found in the response.");
    }

    // Redirect to the new URL in a new tab
    window.open(generatedUrl, '_blank');

    // if (contentType !== 'application/pdf') {
    //   console.error('Expected a PDF, but received:', contentType);
    //   throw new Error('The response is not a valid PDF.');
    // }

    // console.log("response from utils", response);

    // // Create a Blob from the PDF response
    // const blob = new Blob([response.data], { type: 'application/pdf' });

    // // Create a URL for the Blob object
    // const blobUrl = window.URL.createObjectURL(blob);

    // // Create a hidden link element
    // const link = document.createElement("a");
    // link.href = blobUrl;
    // link.download = `test_${testid}_report.pdf`; // Set a default filename for the download

    // // Append the link to the document and trigger the download
    // document.body.appendChild(link);
    // link.click();

    // // Clean up: remove the link after triggering the download
    // document.body.removeChild(link);

  } catch (error) {
    console.error('Error downloading PDF:', error);
    return error;
  }
};



// export const downloadPDF = async (url, testid, type) => {
//   try {
//     // Construct the URL with query parameters
//     const apiUrl = `/download/report?url=${encodeURIComponent(url)}&test_id=${encodeURIComponent(testid)}&type=${encodeURIComponent(type)}`;

//     // Fetch the PDF file from the server
//     const response = await apiClient.get(apiUrl, { responseType: 'blob' });

//     console.log("response from utils", response);

//     // Create a Blob from the PDF response
//     const blob = new Blob([response.data], { type: 'application/pdf' });

//     // Create a URL for the Blob object
//     const blobUrl = window.URL.createObjectURL(blob);

//     // Create a hidden link element
//     const link = document.createElement("a");
//     link.href = blobUrl;
//     link.download = `test_${testid}_report.pdf`; // Set a default filename for the download

//     // Append the link to the document and trigger the download
//     document.body.appendChild(link);
//     link.click();

//     // Clean up: remove the link after triggering the download
//     document.body.removeChild(link);

//   } catch (error) {
//     console.error('Error downloading PDF:', error);
//     return error;
//   }
// };


export const verifyOtp = (data) => {
  return apiClient
    .post('/user/verifyotp', data)
    .then(async (response) => response)
    .catch((error) => error);
};

export const signup = (data) => {
  return apiClient
    .post('/user/signup', data)
    .then(async (response) => response)
    .catch((error) => error);
};

export const forgotPassword = (data) => {
  return apiClient
    .post('/user/forgetpassword/sendmail', data)
    .then(async (response) => response)
    .catch((error) => error);
};

export const verifyResetPasswordToken = (token) => {
  return apiClient
    .get(`/user/verify-token?token=${token}`)
    .then(async (response) => response)
    .catch((error) => error);
};

export const resetPassword = (data) => {
  return apiClient
    .post(`/user/reset-password`, data)
    .then(async (response) => response)
    .catch((error) => error);
};

export const getUserGoogleToken = (token) => {
  return apiClient
    .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${token}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json'
      }
    })
    .then(async (res) => res)
    .catch((error) => error);
};

export const getUserDetailAfterGoogleLogin = (data) => {
  return apiClient
    .post(`user/login`, data)
    .then(async (response) => response)
    .catch((error) => error);
};

export const securityTest = (data) => {
  return apiClient
    .post(`securitytest/queue/run`, data)
    .then(async (response) => response)
    .catch((error) => error);
};

export const getSecurityTestResults = (data) => {
  return apiClient
    .post(`securitytest/result`, data)
    .then(async (res) => res)
    .catch((error) => error);
};

export const getSecurityTestDetail = (data) => {
  return apiClient
    .get(
      `securitytest/result/testdetails?user_id=${data.user_id}&client_id=${data.client_id}&testName=${data.testName}`
    )
    .then(async (res) => res)
    .catch((error) => error);
};

export const getLoadTestResults = (data) => {
  return apiClient
    .post(`loadtest/result`, data)
    .then(async (res) => res)
    .catch((error) => error);
};

export const loadTest = (data) => {
  return apiClient
    .post(`loadtest/generate-file`, data)
    .then(async (response) => response)
    .catch((error) => error);
};

export const loadTestKafka = (data) => {
  return apiClient
    .post(`loadtest/kafka/generate-file`, data)
    .then(async (response) => response)
    .catch((error) => error);
};

export const loadTestSoap = (data) => {
  return apiClient
    .post(`loadtest/soap/generate-file`, data)
    .then(async (response) => response)
    .catch((error) => error);
};

export const loadTestWebsite = (data) => {
  return apiClient
    .post(`loadtest/website/generate-file`, data)
    .then(async (response) => response)
    .catch((error) => error);
};

export const loadTestDatabaseRelational = (data) => {
  return apiClient
    .post(`loadtest/relationaldb/generate-file`, data)
    .then(async (response) => response)
    .catch((error) => error);
};

export const loadTestDatabaseNonRelational = (data) => {
  return apiClient
    .post(`loadtest/nonrelationaldb/generate-file`, data)
    .then(async (response) => response)
    .catch((error) => error);
};


export const getLoadTestDetail = (data) => {
  return apiClient
    .post(
      `loadtest/result/testdetails`, data
    )
    .then(async (res) => res)
    .catch((error) => error);
};

export const getAIRecommendationData = (data) => {
  return middlewareApiClient
    .post(
      `loadTestSolution`, data
    )
    .then(async (res) => res)
    .catch((error) => error);
};

export const createSubscription = (data) => {
  return apiClient
    .post(`create-subscription`, data)
    .then(async (response) => response)
    .catch((error) => error);
};

export const loadTestRun = (data) => {
  return apiClient
    .post(`loadtest/run`, data)
    .then(async (response) => response)
    .catch((error) => error);
};

export const runSecurityTest = (data) => {
  return apiClient
    .post(`securitytest/queue/run`, data)
    .then(async (response) => response)
    .catch((error) => error);
};

export const generateSecurityCertificate = (data) => {
  console.log(data);

  return apiClient
    .post(`securitytest/generate/certificate`, data)
    .then(async (response) => response)
    .catch((error) => error);
};

export const generateLoadCertificate = (data) => {
  console.log(data);

  return apiClient
    .post(`loadtest/generate/certificate`, data)
    .then(async (response) => response)
    .catch((error) => error);
};




export const deleteSecurityTest = (data) => {
  return apiClient
    .delete(
      `securitytest?user_id=${data.user_id}&enterprise_id=${data.enterprise_id}&test_id=${data.id}&object_id=${data.object_id}`
    )
    .then(async (response) => response)
    .catch((error) => error);
};


export const getSecurityTestDetails = (data) => {
  return apiClient
    .post(`securitytest/result/testdetails`, data)
    .then(async (res) => res)
    .catch((error) => error);
};

export const deleteLoadTest = (data) => {
  return apiClient
    .delete(
      `loadtest/delete?user_id=${data.user_id}&enterprise_id=${data.enterprise_id}&test_id=${data.id}&object_id=${data.object_id}`
    )
    .then(async (response) => response)
    .catch((error) => error);
};

export const architectureSuggestions = (data) => {
  return apiClient
    .post(`architecture-suggestions`, data)
    .then(async (response) => response)
    .catch((error) => error);
};

// export const getarchitectureInfo = (data) => {
//   return apiClient
//     .get(
//       `architecture-suggestions?user_id=${data.user_id}&enterprise_id=${data.enterprise_id}&architecture_id=${data.architecture_id}&architecture_name=${data.architecture_name}&object_id=${data.object_id}`
//     )
//     .then(async (response) => response)
//     .catch((error) => error);
// };


export const getarchitectureInfo = (data) => {
  return apiClient
    .get(
      `architecture-suggestions?user_id=${data.user_id}&enterprise_id=${data.enterprise_id}&architecture_id=${data.architecture_id}&architecture_name=${data.architecture_name}&object_id=${data.object_id}`,
      {
        headers: {
          'Accept': 'application/json', // Ensure you're asking for JSON
        }
      }
    )
    .then((response) => response)
    .catch((error) => error);
};


export const getarchitectureSuggestions = (data) => {
  return apiClient
    .post('list/architecture-suggestions', data)
    .then(async (response) => response)
    .catch((error) => error);
};

export const getSecuritySolution = (data) => {
  return middlewareApiClient
    .post('securityTestSolution', data)
    .then(async (response) => response)
    .catch((error) => error);
};

export const deleteArchitectureTest = (data) => {
  return apiClient
    .delete(
      `architecture-suggestions?user_id=${data.user_id}&enterprise_id=${data.enterprise_id}&architecture_id=${data.architecture_id}&object_id=${data.object_id}`
    )
    .then(async (response) => response)
    .catch((error) => error);
};

export const changePassword = (data) => {
  return apiClient
    .post(`user/changepassword`, data)
    .then(async (response) => response)
    .catch((error) => error);
};

export const getEnvironmentNames = (data) => {
  return apiClient
    .get(`environment?user_id=${data.user_id}&enterprise_id=${data.enterprise_id}`)
    .then(async (response) => response)
    .catch((error) => error);
};

export const createEnvironment = (data) => {
  return apiClient
    .post(`/create/environment`, data)
    .then(async (response) => response)
    .catch((error) => error);
};

export const uploadProfilePicture = (formData) => {
  return apiClient
    .post(`user/profile/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data' // Forcing it here
      }
    })
    .then(async (response) => response)
    .catch((error) => error);
};

export const getSubscriptionDetails = (data) => {
  return apiClient
    .get(
      `subscription?user_id=${data.user_id}&enterprise_id=${data.enterprise_id}&subscription_id=${data.subscription_id}`
    )
    .then(async (response) => response)
    .catch((error) => error);
};

export const setUsername = (data) => {
  return apiClient
    .post(`user/update/userName`, data)
    .then(async (response) => response)
    .catch((error) => error);
};

export const basicPlan = (data) => {
  return apiClient
    .post(`basic-subscription`, data)
    .then(async (response) => response)
    .catch((error) => error);
};

export const getPlanDetails = (data) => {
  return apiClient
    .get(
      `get/plans?enterprise_id=${data.enterprise_id ? data.enterprise_id : ''}&plan_name=${data.plan_type ? data.plan_type : ''}&curreny=${data.currencyType ? data.currencyType : ''}&plan_id=${data.plan_id ? data.plan_id : null}`
    )
    .then(async (response) => response)
    .catch((error) => error);
};

export const getAccessToken = (data) => {
  return apiClient
    .post(`user/refresh-token`, data)
    .then(async (response) => response)
    .catch((error) => error);
};
