import { LOADER_SET, NOTIFY_SET, RESET_REDUX, USER_SET, TOGGLE_SET } from './actions';

export const DEFAULT_USER = {
  user_id: null,
  email: '',
  token: null
};

export const loader = (state = false, action) => {
  switch (action.type) {
    case LOADER_SET:
      return action.data;

    default:
      return state;
  }
};

export const notify = (state = {}, action) => {
  switch (action.type) {
    case NOTIFY_SET:
      return action.state;

    default:
      return state;
  }
};

export const user = (state = DEFAULT_USER, action) => {
  switch (action.type) {
    case USER_SET:
      return action.user;
    case RESET_REDUX: {
      const userData = DEFAULT_USER;
      return userData;
    }

    default:
      return state;
  }
};


export const toggle = (state = false, action) => {
  switch (action.type) {
    case TOGGLE_SET:
      return action.isCollapsed;
    default:
      return state;
  }
};