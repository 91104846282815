import React from 'react';
import DashTable from '../../components/Admin_UserManagement/DashTable';
import { sendIcon, deleteIcon } from '../../assets/icons2/index';

const UserManagementForm = () => {
  const tabsConfig = [
    { value: 'all', label: 'All' },
    { value: 'invited', label: 'Invited' }
  ];

  const columns = [
    { id: 'testDetails', header: 'Team Members' },
    { id: 'status', header: 'Status' },
    { id: 'startTime', header: 'Last Used' }
  ];

  const data = {
    all: [
      {
        testDetails: 'Harsh',
        status: 'In Progress',
        cloudType: 'GCP',
        duration: '1h 30m 0s',
        startTime: '04/19/2024 08:43:26 PM'
      },
      {
        testDetails: 'Anshul',
        status: 'Success',
        cloudType: 'GCP',
        duration: '1h 30m 0s',
        startTime: '04/19/2024 08:43:26 PM'
      },
      {
        testDetails: 'Avdhut',
        status: 'In Progress',
        cloudType: 'Azure',
        duration: '2h 0m 0s',
        startTime: '04/19/2024 09:43:26 PM'
      },
      {
        testDetails: 'Shreedhar',
        status: 'Not Started',
        cloudType: 'Azure',
        duration: '2h 0m 0s',
        startTime: '04/19/2024 09:43:26 PM'
      },
      {
        testDetails: 'Akshay',
        status: 'Success',
        cloudType: 'Azure',
        duration: '2h 0m 0s',
        startTime: '04/19/2024 09:43:26 PM'
      },
      {
        testDetails: 'Pratik',
        status: 'Failed',
        cloudType: 'Azure',
        duration: '2h 0m 0s',
        startTime: '04/19/2024 09:43:26 PM'
      },
      {
        testDetails: 'devansh',
        status: 'Failed',
        cloudType: 'Azure',
        duration: '2h 0m 0s',
        startTime: '04/19/2024 09:43:26 PM'
      },
      {
        testDetails: 'Pratik',
        status: 'Failed',
        cloudType: 'Azure',
        duration: '2h 0m 0s',
        startTime: '04/19/2024 09:43:26 PM'
      },
      {
        testDetails: 'Pratik',
        status: 'Failed',
        cloudType: 'Azure',
        duration: '2h 0m 0s',
        startTime: '04/19/2024 09:43:26 PM'
      },
      {
        testDetails: 'Pratik',
        status: 'Failed',
        cloudType: 'Azure',
        duration: '2h 0m 0s',
        startTime: '04/19/2024 09:43:26 PM'
      },
      {
        testDetails: 'Pratik',
        status: 'Failed',
        cloudType: 'Azure',
        duration: '2h 0m 0s',
        startTime: '04/19/2024 09:43:26 PM'
      },
      {
        testDetails: 'Pratik',
        status: 'Failed',
        cloudType: 'Azure',
        duration: '2h 0m 0s',
        startTime: '04/19/2024 09:43:26 PM'
      },
      {
        testDetails: 'Pratik',
        status: 'Failed',
        cloudType: 'Azure',
        duration: '2h 0m 0s',
        startTime: '04/19/2024 09:43:26 PM'
      },
      {
        testDetails: 'Pratik',
        status: 'Failed',
        cloudType: 'Azure',
        duration: '2h 0m 0s',
        startTime: '04/19/2024 09:43:26 PM'
      },
      {
        testDetails: 'Pratik',
        status: 'Failed',
        cloudType: 'Azure',
        duration: '2h 0m 0s',
        startTime: '04/19/2024 09:43:26 PM'
      },
      {
        testDetails: 'Pratik',
        status: 'Failed',
        cloudType: 'Azure',
        duration: '2h 0m 0s',
        startTime: '04/19/2024 09:43:26 PM'
      },
      {
        testDetails: 'Pratik',
        status: 'Failed',
        cloudType: 'Azure',
        duration: '2h 0m 0s',
        startTime: '04/19/2024 09:43:26 PM'
      }
    ],
    invited: [
      {
        testDetails: 'Shreedhar',
        status: 'Not Started',
        cloudType: 'Azure',
        duration: '2h 0m 0s',
        startTime: '04/19/2024 09:43:26 PM'
      },
      {
        testDetails: 'Akshay',
        status: 'Success',
        cloudType: 'Azure',
        duration: '2h 0m 0s',
        startTime: '04/19/2024 09:43:26 PM'
      },
      {
        testDetails: 'Pratik',
        status: 'Failed',
        cloudType: 'Azure',
        duration: '2h 0m 0s',
        startTime: '04/19/2024 09:43:26 PM'
      },
      {
        testDetails: 'devansh',
        status: 'Failed',
        cloudType: 'Azure',
        duration: '2h 0m 0s',
        startTime: '04/19/2024 09:43:26 PM'
      }
    ]
  };

  const buttonOptions = [
    {
      icon: sendIcon,
      alt: 'Send Icon',
      text: 'Invite User',
      iconHeight: 4,
      iconWidth: 18,
      iconColor: 'text-[#FFFFFF]'
    },
    {
      icon: deleteIcon,
      alt: 'Delete Icon',
      text: 'Remove User',
      iconHeight: 17,
      iconWidth: 17,
      iconColor: 'text-red-600'
    }
  ];

  const accordionTab = [
    {
      title: 'Title 1',
      subtitles: ['Subtitle 1.1', 'Subtitle 1.2']
    },
    {
      title: 'Title 2',
      subtitles: ['Subtitle 2.1', 'Subtitle 2.2']
    },
    {
      title: 'Title 3',
      subtitles: ['Subtitle 3.1', 'Subtitle 3.2']
    },
    {
      title: 'Title 4',
      subtitles: ['Subtitle 3.1', 'Subtitle 3.2']
    },
    {
      title: 'Title 5',
      subtitles: ['Subtitle 3.1', 'Subtitle 3.2']
    },
    {
      title: 'Title 6',
      subtitles: ['Subtitle 3.1', 'Subtitle 3.2']
    },
    {
      title: 'Title 7',
      subtitles: ['Subtitle 3.1', 'Subtitle 3.2']
    }
  ];
  return (
    <main className="grid flex-1 items-start gap-4 sm:py-0 md:gap-8 h-[100vh] w-full mt-4">
      <div className="grid auto-rows-max items-start gap-4 md:gap-8 lg:col-span-2 manrope-fontCss">
        <DashTable
          tabsConfig={tabsConfig}
          columns={columns}
          data={data}
          buttonType={'Invite'}
          buttonOptions={buttonOptions}
          accordionTab={accordionTab}
        />
      </div>
    </main>
  );
};

export default UserManagementForm;
