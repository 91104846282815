import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../../components/ui/card';
// import { Button } from '../../components/ui/button';
// import {
//   Select,
//   SelectTrigger,
//   SelectValue,
//   SelectContent,
//   SelectItem
// } from '../../components/ui/select';

// import GCP from '../../assets/images/GCP.png';
// import AWS from '../../assets/images/AWS.png';
// import Azure from '../../assets/images/Azure.png';
import { Button } from '../../components/ui/button';
import withUser from '../../redux/Hoc/withUser';
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator
} from '../../components/ui/dropdown-menu';
import { DropDownIcon, QuestionMarkIcon } from '../../assets/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { getarchitectureInfo } from '../../utils/util';
import withLoader from '../../redux/Hoc/withLoader';

// const cloudData = {
//   AWS: {
//     name: 'AWS Cloud Architecture',
//     image: AWS,
//     total: 313.45,
//     services: [
//       { name: 'S3 + CloudFront', price: 0 },
//       { name: 'EKS', price: 73 },
//       { name: 'Elastic Load Balancer', price: 17.45 },
//       { name: 'EC2 Instance (T3.Xlarge)', price: 133 },
//       { name: 'Data Sharing / VPC / Network', price: 30 },
//       { name: 'Additional Taxes', price: 60 }
//     ]
//   },
//   GCP: {
//     name: 'GCP Cloud Architecture',
//     image: GCP,
//     total: 353.45,
//     services: [
//       { name: 'VM', price: 0 },
//       { name: 'Cloud Storage', price: 73 },
//       { name: 'Cloud Function', price: 17.45 },
//       { name: 'Cloud CDN', price: 133 },
//       { name: 'Cloud Load Balancing', price: 30 },
//       { name: 'Cloud SQL', price: 110 }
//     ]
//   },
//   Azure: {
//     name: 'Azure Cloud Architecture',
//     image: Azure,
//     total: 290,
//     services: [
//       { name: 'Azure Blob Storage', price: 0 },
//       { name: 'AKS', price: 80 },
//       { name: 'Azure Load Balancer', price: 15 },
//       { name: 'VM Instance (D2_v3)', price: 120 },
//       { name: 'Data Sharing / VNet / Network', price: 25 },
//       { name: 'Additional Taxes', price: 50 }
//     ]
//   }
// };

const CloudComparison = ({user,setLoader}) => {
  console.log("user hhh",user)
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialCloud1 = queryParams.get('cloud1') || 'AWS';
  const initialCloud2 = queryParams.get('cloud2') || 'GCP';
 const id=queryParams.get("id")
 const name=queryParams.get("name")
  const [selectedCloud1, setSelectedCloud1] = useState(initialCloud1);
  const [selectedCloud2, setSelectedCloud2] = useState(initialCloud2);
  const [compareMode, setCompareMode] = useState(`${initialCloud1} vs ${initialCloud2}`);
  
const [architectureInfoData,setArchitectureInfoData]=useState([])
const [cloudData, setCloudData] = useState({
  AWS: { name: '', image: '', total: 0, services: [] },
  GCP: { name: '', image: '', total: 0, services: [] },
  AZURE: { name: '', image: '', total: 0, services: [] }
});

  useEffect(() => {
    const fetchData = async () => {
      setLoader(true)
      try {
        const data = {
          user_id: user.user_id,
          enterprise_id: user.enterprise_id,
          architecture_id: id,
          architecture_name: name,
          object_id: user.object_id
        };
        const response = await getarchitectureInfo(data);
        setLoader(false)
        console.log(response.data.data)
        if(response){

          setArchitectureInfoData(response?.data.data.cloud_type);
        }

        console.log("hii from comparision : ",architectureInfoData)
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  
  
  // const AWS1 = architectureInfoData
  // ?.filter((curElem) => curElem.type === 'AWS')
  // .flatMap((curElem) =>
    
  //   curElem.services.map((service) => 
  //     {
  //       // the price of data is string like this $15 so i remove the $ and make the remaining no's from str to num
  //       const price = !Number(service.monthlyCost) ?parseFloat(service.monthlyCost.replace('$', '')):service.monthlyCost;
  //       return {
  //         name: service.name,
  //         price: price
  //       }
  //     })
  //   );
    
  //   // Filter and map GCP services dynamically
    
  //   const GCP1 = architectureInfoData
  //   ?.filter((curElem) => curElem.type === 'GCP')
  //   .flatMap((curElem) =>
  //     curElem.services.map((service) => 
  //       {
  //         // the price of data is string like this $15 so i remove the $ and make the remaining no's from str to num
  //         // const price = parseFloat(service.monthlyCost.replace('$', ''));
  //         const price = !Number(service.monthlyCost) ?parseFloat(service.monthlyCost.replace('$', '')):service.monthlyCost;
  //         return {
  //           name: service.name,
  //           price: price
  //         }
  //       })
  //     );
      
  //     const Azure1 = architectureInfoData
  //   ?.filter((curElem) => curElem.type === 'AZURE')
  //   .flatMap((curElem) =>
  //     curElem.services.map((service) => 
  //       {
  //         // the price of data is string like this $15 so i remove the $ and make the remaining no's from str to num
  //         // const price = parseFloat(service.monthlyCost.replace('$', ''));
  //         const price = !Number(service.monthlyCost) ?parseFloat(service.monthlyCost.replace('$', '')):service.monthlyCost;
  //         return {
  //           name: service.name,
  //           price: price
  //         }
  //       })
  //     );
      
  useEffect(() => {
    setLoader(true)
    if (architectureInfoData?.length > 0) {
      console.log("Fetched architectureInfoData:", architectureInfoData);
      
      const AWS1 = architectureInfoData
        .filter((curElem) => curElem.type === 'AWS')
        .flatMap((curElem) =>
          curElem.services.map((service) => {
            const price = !Number(service.monthlyCost) ? parseFloat(service.monthlyCost.replace('$', '')) : service.monthlyCost;
            return { name: service.name, price };
          })
        );
  
      const GCP1 = architectureInfoData
        .filter((curElem) => curElem.type === 'GCP')
        .flatMap((curElem) =>
          curElem.services.map((service) => {
            const price = !Number(service.monthlyCost) ? parseFloat(service.monthlyCost.replace('$', '')) : service.monthlyCost;
            return { name: service.name, price };
          })
        );
  
      const Azure1 = architectureInfoData
        .filter((curElem) => curElem.type === 'AZURE')
        .flatMap((curElem) =>
          curElem.services.map((service) => {
            const price = !Number(service.monthlyCost) ? parseFloat(service.monthlyCost.replace('$', '')) : service.monthlyCost;
            return { name: service.name, price };
          })
        );
  
      // Debug log to check intermediate values
      console.log("AWS1 data:", AWS1);
      console.log("GCP1 data:", GCP1);
      console.log("Azure1 data:", Azure1);
  
      setCloudData({
        AWS: {
          name: 'AWS Cloud Architecture',
          image: architectureInfoData.find(curElem => curElem.type === 'AWS')?.link || '',
          total: !Number(architectureInfoData.find(curElem => curElem.type === 'AWS')?.costPerMonth) 
          ? parseFloat(architectureInfoData.find(curElem => curElem.type === 'AWS')?.costPerMonth.replace('$', '')) 
          : architectureInfoData.find(curElem => curElem.type === 'AWS')?.costPerMonth || 0
        ,
          services: AWS1
        },
        GCP: {
          name: 'GCP Cloud Architecture',
          image: architectureInfoData.find(curElem => curElem.type === 'GCP')?.link || '',
          total:!Number(architectureInfoData.find(curElem => curElem.type === 'GCP')?.costPerMonth) 
          ? parseFloat(architectureInfoData.find(curElem => curElem.type === 'GCP')?.costPerMonth.replace('$', '')) 
          : architectureInfoData.find(curElem => curElem.type === 'GCP')?.costPerMonth || 0,
        
          services: GCP1
        },
        AZURE: {
          name: 'Azure Cloud Architecture',
          image: architectureInfoData.find(curElem => curElem.type === 'AZURE')?.link || '',
          total: !Number(architectureInfoData.find(curElem => curElem.type === 'AZURE')?.costPerMonth) 
          ? parseFloat(architectureInfoData.find(curElem => curElem.type === 'AZURE')?.costPerMonth.replace('$', '')) 
          : architectureInfoData.find(curElem => curElem.type === 'AZURE')?.costPerMonth || 0
        ,
          services: Azure1
        }
      });
    }

    setLoader(false)
  }, [architectureInfoData]);
  
      
      const handleCloudChange = (value) => {
        const [cloud1, cloud2] = value.split(' vs ');
        setSelectedCloud1(cloud1);
        setSelectedCloud2(cloud2);
        setCompareMode(value);
      };
      const [noOfUsersDropdownOpen, setNoOfUsersDropdownOpen] = useState(false);
      
      const handleUsersDropdown = (isOpen) => {
    setNoOfUsersDropdownOpen(isOpen);
  };
  console.log("hiiiii ",{...
    cloudData})
const navigate=useNavigate()
    const handleNavigate=()=>{
      
      navigate(`/architecture/info/${id}/${name}`)
    }

  return (
    <main className="grid flex-1 items-start gap-4 w-full">
      <div className="grid auto-rows-max items-start lg:col-span-2">
        <div className="flex justify-end">
          <DropdownMenu className="col-span-1" onOpenChange={handleUsersDropdown}>
            <DropdownMenuTrigger className="h-9 w-80 bg-[#2A2E3F] text-white border-[#444444] rounded-lg mt-2">
              <div className="px-[13px] flex items-center justify-between">
                {compareMode ? compareMode : 'Compare Cloud Types'}{' '}
                <div
                  className={`transition-transform ${noOfUsersDropdownOpen ? 'rotate-180' : ''}`}>
                  <DropDownIcon />
                </div>
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-[var(--radix-dropdown-menu-trigger-width)] bg-[#2A2E3F] mt-4">
              <DropdownMenuItem
                onClick={() => handleCloudChange('AWS vs GCP')}
                className={compareMode === 'AWS vs GCP' ? 'bg-accent' : ''}>
                <div className="w-full text-center text-[16px] font-semibold">AWS vs GCP</div>
              </DropdownMenuItem>
              <DropdownMenuSeparator className="" />
              <DropdownMenuItem
                onClick={() => handleCloudChange('GCP vs AZURE')}
                className={compareMode === 'GCP vs AZURE' ? 'bg-accent' : ''}>
                <div className="w-full text-center text-[16px] font-semibold">GCP vs Azure</div>
              </DropdownMenuItem>
              <DropdownMenuSeparator className="" />
              <DropdownMenuItem
                onClick={() => handleCloudChange('AWS vs AZURE')}
                className={compareMode === 'AWS vs AZURE' ? 'bg-accent' : ''}>
                <div className="w-full text-center text-[16px] font-semibold">AWS vs Azure</div>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
        <div className="grid grid-cols-2 w-full h-[360px] gap-4 py-4">
          <Card>
            {/* <CardContent className="w-full h-[100%] rounded-md bg-[#00163B] border-[#11141F] flex justify-center items-center">
              <img
                src={cloudData[selectedCloud1].image}
                alt={selectedCloud1}
                className="mx-auto max-w-full h-72 object-contain"
              />
            </CardContent> */}

<CardContent className="w-full h-[100%] rounded-md bg-white border-[#11141F] flex justify-center items-center">
  {cloudData[selectedCloud1]?.image ? (
    <img
      src={cloudData[selectedCloud1].image}
      alt={selectedCloud1}
      className="mx-auto max-w-full h-72 object-contain"
    />
  ) : (
    <div>No image available</div>
  )}
</CardContent>
          </Card>
          <Card className="rounded-lg flex justify-center">
          <CardContent className="w-full h-[100%] rounded-md bg-white border-[#11141F] flex justify-center items-center">
  {cloudData[selectedCloud2]?.image ? (
    <img
      src={cloudData[selectedCloud2]?.image}
      alt={selectedCloud1}
      className="mx-auto max-w-full h-72 object-contain"
    />
  ) : (
    <div>No image available</div>
  )}
</CardContent>
          </Card>
        </div>
        <div className="grid grid-cols-[auto,1fr] items-center gap-4 text-white py-2">
          <h3 className="text-xl font-semibold whitespace-nowrap">Overall Bill Summary</h3>
          <hr className="w-full border-[#002C74] border-dashed" />
        </div>
        {/* Grand Total */}
        <div className="flex justify-between items-center w-full h-auto gap-4">
          <div className="w-[90%] flex flex-col justify-start gap-2">
            <div className="flex flex-row items-center justify-between">
              <p className="text-base text-white font-bold">
                Grand Total:{' '}
                <span className="text-3xl text-white font-bold">
                  ${cloudData[selectedCloud1]?.total}
                </span>
              </p>
              <p className="mt-[10px] text-base font-semibold text-muted-foreground">
                Incl. of Taxes{' '}
              </p>
            </div>
            <Card className={`border-2 border-[#002C74] h-auto`}>
              <CardHeader className={`px-2 py-2`}>
                <CardTitle className="text-xl text-white font-bold">
                  <div className="flex justify-start items-center gap-2">
                    Services{' '}
                    <span className={`pt-1`}>
                      <QuestionMarkIcon />
                    </span>
                  </div>
                </CardTitle>
              </CardHeader>
              <hr className="w-full border-[#747474] " />
              <CardContent className={`px-0 `}>
                <div className="grid grid-cols-1 justify-between">
                  <ul>
                    {cloudData[selectedCloud1]?.services.map((service, index) => (
                      <React.Fragment key={index}>
                        {index !== 0 && <hr className="w-full  border-[#747474]" />}
                        <li className="flex justify-between py-0 m-2">
                          <span className="text-sm font-semibold text-[#A1A1AA]">
                            {service.name}
                          </span>
                          <span className="text-sm font-semibold text-[#A1A1AA]">
                            ${service.price}
                          </span>
                        </li>
                      </React.Fragment>
                    ))}
                    <hr className="w-full border-[#8D909F] border-dashed" />
                    <li className="flex justify-between px-3">
                      <span className="text-sm font-semibold mt-4">Grand Total:</span>
                      <span className="text-sm font-semibold mt-4">
                        $
                        {cloudData[selectedCloud1]?.services.reduce(
                          (acc, service) => acc + service.price,
                          0
                        )}
                      </span>
                    </li>
                  </ul>
                </div>
              </CardContent>
            </Card>
          </div>
          <div className="w-[90%] flex flex-col justify-start gap-2">
            <div className="flex flex-row items-center justify-between">
              <p className="text-base text-white font-bold">
                Grand Total:{' '}
                <span className="text-3xl text-white font-bold">
                  ${cloudData[selectedCloud2]?.total}
                </span>
              </p>
              <p className="mt-[10px] text-base font-semibold text-muted-foreground">
                Incl. of Taxes{' '}
              </p>
            </div>
            <Card className={`border-2 border-[#002C74]`}>
              <CardHeader className={`px-2 py-2`}>
                <CardTitle className="text-xl text-white font-bold">
                  {' '}
                  <div className="flex justify-start items-center gap-2">
                    Services{' '}
                    <span className={`pt-1`}>
                      <QuestionMarkIcon />
                    </span>
                  </div>
                </CardTitle>
              </CardHeader>
              <hr className="w-full border-[#747474]" />
              <CardContent className={`px-0`}>
                <div className="grid grid-cols-1 justify-between">
                  <ul>
                    {cloudData[selectedCloud2]?.services.map((service, index) => (
                      <React.Fragment key={index}>
                        {index !== 0 && <hr className="w-full border-[#747474]" />}
                        <li className="flex justify-between py-0 m-2">
                          <span className="text-sm font-semibold text-[#A1A1AA]">
                            {service.name}
                          </span>
                          <span className="text-sm font-semibold text-[#A1A1AA]">
                            ${service.price}
                          </span>
                        </li>
                      </React.Fragment>
                    ))}
                    <hr className="w-full border-[#8D909F] border-dashed" />
                    <li className="flex justify-between px-3">
                      <span className="text-sm font-semibold mt-4">Grand Total:</span>
                      <span className="text-sm font-semibold mt-4">
                        $
                        {cloudData[selectedCloud2]?.services.reduce(
                          (acc, service) => acc + service.price,
                          0
                        )}
                      </span>
                    </li>
                  </ul>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
        <div className="flex justify-end pb-8  pt-2">
          <Button onClick={handleNavigate} className="text-white text-sm rounded-lg h-9 w-44">
            <span>Okay, Understood</span>
          </Button>
        </div>
      </div>
    </main>
  );
};

export default withLoader(withUser(CloudComparison));