import React, { useState, useEffect, useRef } from 'react';
import { trim } from 'lodash';
import { Input } from '../components/ui/input';

const FormField = (props) => {
  const { input, meta, onDropDownChange = () => {}, ...rest } = props;
  const [text, setText] = useState(input.value || '');
  const textareaRef = useRef(null);
  const handleChange = (event) => {
    setText(event.target.value);
    input.onChange(event); // Update the form value
  };

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = textarea.scrollHeight + 'px';
    }
  }, [text]);
  const dropDownChange = (event) => {
    const {
      input: { name }
    } = props;
    if (onDropDownChange) {
      onDropDownChange({ name, value: event.target.value });
    }
  };

  return (
    <>
      {input.type === 'dropdown' ? (
        <select {...rest} {...input} {...(onDropDownChange ? { onChange: dropDownChange } : {})}>
          {rest.placeholder && <option value="">{rest.placeholder}</option>}
          {rest.data.map((value, key) => (
            <option key={`reduxDropdown${key}`}>{value}</option>
          ))}
        </select>
      ) : null}

      {input.type === 'text' ||
      input.type === 'email' ||
      input.type === 'number' ||
      input.type === 'password' ||
      input.type === 'checkbox' ? (
        <Input {...rest} {...input} type={input.type} />
      ) : input.type === 'textarea' ? (
        <textarea
          {...rest}
          {...input}
          ref={textareaRef}
          value={text}
          onChange={handleChange}
          style={{ overflow: 'hidden', transition: 'height 0.2s ease' }}
        />
      ) : null}

      {meta.touched && meta.error && (
        <p className="text-red-500 text-sm text-left">&nbsp;{meta.error}</p>
      )}
    </>
  );
};

export const renderField = (props) => <FormField {...props} />;

export const composeValidators =
  (...validators) =>
  (value, allValues) =>
    validators.reduce((error, validator) => error || validator(value, allValues), undefined);

export const required = (value) => (trim(value) ? undefined : 'Required');

export const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined;

export const password = (value) =>
  value && !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,}$/i.test(value)
    ? 'Password must be 8+ characters, with 1 uppercase, 1 lowercase, 1 number, and 1 special character.'
    : undefined;

export const passwordsMustMatch = (value, allValues) =>
  value !== allValues.password ? 'Passwords do not match' : undefined;

export const url = (value) =>
  value &&
  !/^(https?|http|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]*[-A-Za-z0-9+&@#/%=~_|]$/.test(value)
    ? 'Invalid URL'
    : undefined;

    export const openApiUrl = (value) =>
      value &&
      !/https:\/\/.+\.json/.test(value)
        ? 'Invalid OpenAPI URL'
        : undefined;
    