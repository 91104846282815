import { createSubscription } from '../utils/util';

export const payment = async (plan_id, user, navigate, total_count) => {
  try {
    const createOrderRes = await createSubscription({
      plan_id: plan_id,
      user_id: user.user_id,
      total_count: total_count
    });
    console.log('create order', createOrderRes);
    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID,
      subscription_id: createOrderRes?.data?.subscription_id,
      name: 'Merchant Name',
      description: 'Test Subscription',
      image: 'https://example.com/your_logo',
      handler: function () {
        navigate('/');
      },
      // callback_url: 'http://localhost:4000/v1/verify-payment',
      // redirect: true,
      prefill: {
        name: '',
        email: '',
        contact: '9999999999'
      },
      notes: {
        address: 'Razorpay Corporate Office'
      },
      theme: {
        color: '#3399cc'
      }
    };
    console.log('subscription_id', createOrderRes.data.subscription_id);

    const rzp1 = new window.Razorpay(options);

    rzp1.on('payment.failed', (response) => {
      console.log('Failed Payment', response.error);
    });
    rzp1.open();
  } catch (error) {
    console.error('Error creating subscription', error);
  }
};
