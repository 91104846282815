import React from 'react';

import { Text, View } from '@react-pdf/renderer';

const convertPToText = (data, isReferences) => {
  // Replace <p> with an empty string (start of a new Text block)

  const htmlString = data ? data : null;

  if (htmlString === null) {
    return null;
  }

  let updatedHtml = htmlString.replace(/<p>/g, '\n');

  // Replace </p> with a new line to simulate the closing of a Text block
  updatedHtml = updatedHtml.replace(/<\/p>/g, '\n');

  // Trim extra new lines and split into an array of text elements
  const textElements = updatedHtml
    ?.trim()
    ?.split('\n')
    .filter((text) => text.trim() !== '');

  if (isReferences) {
    return (
      <View style={{ fontSize: 10, gap: 4 }}>
        {textElements.map((text, index) => (
          <Text key={index} style={{ fontSize: 10, color: '#95a2b8' }}>
            {text}
          </Text>
        ))}
      </View>
    );
  }

  return (
    <View style={{ fontSize: 10, gap: 4 }}>
      {textElements.map((text, index) => (
        <Text key={index} style={{ fontSize: 10 }}>
          {text}
        </Text>
      ))}
    </View>
  );
};

export default convertPToText;
