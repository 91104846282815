import React from 'react';

const CloseIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.5748 16.1519C17.787 16.3749 18.0735 16.5 18.3721 16.5C18.6706 16.5 18.9571 16.3749 19.1693 16.1519C19.274 16.0425 19.3572 15.9119 19.414 15.768C19.4708 15.6241 19.5 15.4696 19.5 15.3135C19.5 15.1575 19.4708 15.003 19.414 14.8591C19.3572 14.7151 19.274 14.5846 19.1693 14.4751L12.798 7.8475C12.5854 7.62484 12.2987 7.5 12 7.5C11.7013 7.5 11.4146 7.62484 11.202 7.8475L4.83073 14.4751C4.72598 14.5846 4.6428 14.7151 4.58602 14.8591C4.52924 15.003 4.5 15.1575 4.5 15.3135C4.5 15.4696 4.52924 15.6241 4.58602 15.768C4.6428 15.9119 4.72598 16.0425 4.83073 16.1519C5.04289 16.3749 5.32939 16.5 5.62795 16.5C5.92651 16.5 6.21301 16.3749 6.42517 16.1519L12.0024 10.7166L17.5748 16.1519Z"
        fill="white"
      />
    </svg>
  );
};

export default CloseIcon;
