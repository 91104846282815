import React from 'react';
import leftbg from '../../assets/images/leftbg.png';
import load from '../../assets/images/load.png';
import airecom from '../../assets/images/airecom.png';
import security from '../../assets/images/security.png';

const Backdrop = () => {
  return (
    <>
      <div className="relative w-full h-full bg-white">
        <img src={leftbg} alt="Image" className="w-full h-screen object-cover" />
        <img
          src={load}
          alt="load"
          className="absolute w-[45%] h-[35%] top-1/4 left-1/4 transform -translate-x-1/2 -translate-y-2/3 object-contain"
        />
        <img
          src={airecom}
          alt="ai-recom"
          className="absolute w-[45%] h-[35%] top-1/3 left-[45%]  transform -translate-y-4  object-contain"
        />
        <img
          src={security}
          alt="security"
          className="absolute w-[45%] h-[35%] top-[80%] left-1/4 transform -translate-x-1/2 -translate-y-1/2 object-contain"
        />
      </div>
    </>
  );
};

export default Backdrop;
