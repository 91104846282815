export default function GreenTickIcon() {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="24" cy="24" r="24" fill="#22C55E" fillOpacity="0.26" />
      <svg
        width="24"
        height="18"
        viewBox="0 0 24 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        x="12"
        y="15"
        transform="translate(-12, -9)">
        <path
          d="M7.23984 16.9151C7.34467 17.0211 7.46929 17.1053 7.60665 17.1628C7.74401 17.2204 7.89133 17.25 8.04018 17.25C8.18902 17.25 8.33634 17.2204 8.4737 17.1629C8.61106 17.1053 8.73568 17.0211 8.84051 16.9151L22.9198 2.69405C23.1315 2.4802 23.25 2.19068 23.25 1.88932C23.25 1.58795 23.1315 1.29844 22.9198 1.08459C22.708 0.870649 22.4202 0.75 22.1195 0.75C21.8189 0.75 21.5311 0.870649 21.3193 1.08459L21.3193 1.08459L8.04019 14.4985L2.6807 9.08401L2.68068 9.08399C2.46888 8.87005 2.18108 8.7494 1.88045 8.7494C1.57982 8.7494 1.29202 8.87005 1.08022 9.08399C0.868498 9.29784 0.75 9.58736 0.75 9.88872C0.75 10.1901 0.868498 10.4796 1.08022 10.6935L7.23984 16.9151ZM7.23984 16.9151L7.4176 16.7393M7.23984 16.9151L7.23994 16.9152L7.4176 16.7393M7.4176 16.7393C7.49933 16.822 7.59638 16.8875 7.7032 16.9322C7.81003 16.977 7.92453 17 8.04018 17C8.15582 17 8.27032 16.977 8.37715 16.9322C8.48397 16.8875 8.58102 16.822 8.66275 16.7393M7.4176 16.7393L1.25788 10.5176C1.09276 10.3508 1 10.1246 1 9.88872C1 9.65286 1.09276 9.42666 1.25788 9.25988C1.42299 9.0931 1.64694 8.9994 1.88045 8.9994C2.11396 8.9994 2.33791 9.0931 2.50302 9.25988L8.66275 16.7393M8.66275 16.7393L22.7421 2.51816C22.9072 2.35138 23 2.12518 23 1.88932C23 1.65346 22.9072 1.42725 22.7421 1.26047C22.577 1.0937 22.3531 1 22.1195 1C21.886 1 21.6621 1.0937 21.497 1.26047L8.21607 14.6762L8.66275 16.7393Z"
          fill="white"
          stroke="white"
          strokeWidth="0.5"
        />
      </svg>
    </svg>
  );
}
