import React, { useRef, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { Label } from '../../components/ui/label';
import { Input } from '../../components/ui/input';
import { Button } from '../../components/ui/button';
import { LogOut } from 'lucide-react';
import { UserPlus } from 'lucide-react';
import { store } from '../../redux/store';
import { resetRedux } from '../../redux/actions';
import { useToast } from '../../components/ui/use-toast';
import { Cookies } from 'react-cookie';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '../../components/ui/dialog';
import withLoader from '../../redux/Hoc/withLoader';
import withUser from '../../redux/Hoc/withUser';
import { changePassword, uploadProfilePicture } from '../../utils/util';
import { EyeOpenIcon, EyeNoneIcon } from '@radix-ui/react-icons';
import { Pencil } from 'lucide-react';
import { setUsername } from '../../utils/util';

function profile({ setLoader, setUser, user }) {
  const { toast } = useToast();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [showValidations, setShowValidations] = useState(false);
  const [newPasswordValidation, setNewPasswordValidation] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    digit: false,
    special: false
  });
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const handleEditUsername = () => {
    setIsDisabled(false);
  };

  const destroySession = async () => {
    const cookies = new Cookies();

    cookies.remove('authToken', { path: '/' });
    cookies.remove('session', { path: '/' });
    cookies.remove('user', { path: '/' });

    sessionStorage.clear();
    localStorage.clear();

    console.log('Cookies after destruction:', cookies.get());
    console.log('Session Storage after destruction:', sessionStorage);
    console.log('Local Storage after destruction:', localStorage);
  };

  const onLogout = async () => {
    await store.dispatch(resetRedux());
    await destroySession();
    window.location = '/login';
  };

  const onChangePassword = async () => {
    console.log('Change Password ');
    try {
      if (
        Object.values(newPasswordValidation).every(Boolean) &&
        newPassword === confirmNewPassword
      ) {
        const payload = {
          email: user.email,
          oldPassword: currentPassword,
          newPassword: newPassword
        };
        console.log(payload);

        setLoader(true);
        const result = await changePassword(payload);
        console.log(result);
        setLoader(false);
        setIsDialogOpen(false);
        if (result.data.code === 200) {
          toast({
            variant: 'success',
            title: 'Success',
            description: result.data.message
          });
          setUser(result.data);
        } else if (result.data.code === 404) {
          toast({
            variant: 'destructive',
            title: 'Error',
            description: result.data.message
          });
        } else if (result.data.code === 400) {
          toast({
            variant: 'destructive',
            title: 'Error',
            description: result.data.message
          });
        } else {
          toast({
            variant: 'destructive',
            title: 'Error',
            description: result.data.message
          });
        }
      } else {
        toast({
          variant: 'destructive',
          title: 'Error',
          description: 'Please ensure all password requirements are met and passwords match.'
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const toggleCurrentPasswordVisibility = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleConfirmNewPasswordVisibility = () => {
    setShowConfirmNewPassword(!showConfirmNewPassword);
  };

  const Approvedicon = () => {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <circle cx="14" cy="14" r="14" fill="#24AC54" />
        <path
          d="M6.98804 13.9849L6.98804 13.9849C6.83586 14.1359 6.75 14.3411 6.75 14.5554C6.75 14.7698 6.83586 14.975 6.98804 15.126L10.9078 19.0145C10.9831 19.0893 11.0724 19.1485 11.1706 19.1889C11.2688 19.2292 11.3739 19.25 11.4801 19.25C11.5863 19.25 11.6914 19.2292 11.7896 19.1889C11.8878 19.1485 11.9771 19.0893 12.0525 19.0145L21.012 10.1263C21.1641 9.97537 21.25 9.77019 21.25 9.55582C21.25 9.34146 21.1641 9.13628 21.012 8.98531L21.012 8.98531C20.8599 8.83442 20.654 8.75 20.4397 8.75C20.2255 8.75 20.0196 8.83442 19.8675 8.98531L19.8675 8.98532L11.4801 17.3065L8.13255 13.985L8.13254 13.9849C7.98043 13.834 7.77455 13.7496 7.56029 13.7496C7.34603 13.7496 7.14014 13.834 6.98804 13.9849Z"
          fill="white"
          stroke="white"
          strokeWidth="0.5"
        />
      </svg>
    );
  };

  const Passwordicon = () => {
    return (
      <svg
        width="15"
        height="15"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <circle cx="8" cy="8" r="8" fill="#34416D" />
      </svg>
    );
  };

  const ValidationItem = ({ condition, text }) => (
    <div className="w-full flex flex-row items-center">
      <div className="relative flex justify-center items-center">
        <div className={`w-3 h-4 rounded-full ${condition ? 'text-green-500' : 'text-[#868686]'}`}>
          {condition ? (
            <Approvedicon width={5} height={5} />
          ) : (
            <Passwordicon width={5} height={5} />
          )}
        </div>
        {!(
          text === 'Password must contain at least one special character like !, @, #, $, etc.'
        ) && (
          <div
            className={`absolute left-1.5 top-4 transform -translate-y-1/2 w-0.5 h-7 ${
              condition ? 'bg-[#24AC54]' : 'bg-[#34416D]'
            }`}></div>
        )}
      </div>
      <p className="ml-4 text-xs text-[#868686]">{text}</p>
    </div>
  );

  const validateNewPassword = (value) => {
    const newValidation = {
      length: value.length >= 8,
      uppercase: /[A-Z]/.test(value),
      lowercase: /[a-z]/.test(value),
      digit: /\d/.test(value),
      special: /[!@#$%^&*(),.?":{}|<>]/.test(value)
    };
    setNewPasswordValidation(newValidation);

    if (Object.values(newValidation).every(Boolean)) {
      setShowValidations(false);
    }
  };

  const fileInput = useRef(null);

  const handleFile = async (file) => {
    if (!file) {
      console.error('No file provided');
      return;
    }

    // Checks Valid File Type
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    if (!validTypes.includes(file.type)) {
      toast({
        variant: 'destructive',
        title: 'Invalid File Type',
        description: 'Please upload a file in JPEG, JPG, or PNG format'
      });
      return;
    }

    // Checks File Size (Max 1MB)
    const maxSizeInMB = 1;
    if (file.size > maxSizeInMB * 1024 * 1024) {
      toast({
        variant: 'destructive',
        title: 'File Too Large',
        description: 'Please upload a file smaller than or equal to 1MB.'
      });
      return;
    }

    try {
      setLoader(true);

      const formData = new FormData();

      formData.append('image', file);
      formData.append('user_id', user.user_id);
      const response = await uploadProfilePicture(formData);

      if (response.status === 200) {
        toast({
          variant: 'success',
          title: 'Success',
          description: 'Profile picture updated successfully!'
        });
        // setUser(response.data.user);
      } else {
        toast({
          variant: 'destructive',
          title: 'Error',
          description: response.data.message || 'Something went wrong!'
        });
      }
    } catch (error) {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: error.message || 'Failed to upload the profile picture!'
      });
    } finally {
      setLoader(false);
    }
  };

  const handleChangeProfilePicture = () => {
    fileInput.current.click();
  };

  const handleFileChange = (event) => {
    const fileUploaded = event.target.files[0];
    handleFile(fileUploaded);
  };

  const handleFormSubmit = async (values) => {
    const { userName } = values;

    if (user) {
      setUser({ ...user, user_name: userName });
    }

    const payload = {
      user_name: userName,
      user_id: user.user_id
    };
    const response = await setUsername(payload);
    console.log('user', user);

    console.log(response);
    console.log('Username: ', userName);
  };

  const onSubmit = () => {
    console.log('Form Submitted');
  };

  return (
    <main className="flex-1 items-start p-4 sm:py-0 h-screen space-y-4 manrope-fontCss ">
      <div className="flex flex-row justify-between mt-3">
        {/* Change Profile Picture */}
        <div className="flex flex-row items-center ">
          <div className="bg-[#9747FF59] rounded-full w-12 h-12 flex items-center justify-center">
            <UserPlus />
          </div>
          <Button
            className="bg-[none] hover:bg-[none] text-[#9747FF]"
            onClick={handleChangeProfilePicture}>
            Change Profile Picture
          </Button>
          <input
            type="file"
            accept=".jpg,.jpeg,.png"
            onChange={handleFileChange}
            ref={fileInput}
            style={{ display: 'none' }}
          />
        </div>

        {/* Logout */}
        <Button
          className="bg-[#333E57] hover:bg-[#333E57] text-white cursor-pointer justify-between text-base font-semibold border-2 md:w-36 md:h-10 gap-3"
          onClick={onLogout}>
          <div className="hidden md:block">Logout</div>
          <LogOut stroke="#9747FF" />
        </Button>
      </div>
      <div className="flex flex-col gap-y-5">
        <Form
          onSubmit={handleFormSubmit}
          initialValues={{ userName: user?.user_name, emailId: user?.email }}
          render={({ handleSubmit }) => (
            <form
              onSubmit={handleSubmit}
              className="flex flex-col md:flex-row md:items-end md:space-x-6">
              <div className="flex flex-col w-[75%] md:flex-row md:space-x-10">
                {/* Username */}
                <div className="w-1/2 relative grid space-y-2 mb-2">
                  <div>
                    <Label htmlFor="userName" className="font-semibold text-lg manrope-fontCss">
                      Username
                    </Label>
                  </div>
                  <div className="flex flex-row justify-center items-center">
                    <Field
                      name="userName"
                      render={({ input, meta }) => (
                        <div className="w-full font-semibold text-lg manrope-fontCss">
                          <Input placeholder="Username" {...input} disabled={isDisabled} />
                          {meta.touched && meta.error && (
                            <div className="text-[#EF4444] text-sm my-3">{meta.error}</div>
                          )}
                        </div>
                      )}
                    />

                    {/* Pencil to edit */}
                    <Button
                      className="absolute h-20 inset-y-3 right-0 flex items-center px-4 cursor-pointer bg-[none] hover:bg-[none]"
                      onClick={handleEditUsername}>
                      <Pencil strokeWidth={1} size={20} stroke="#9747FF" />
                    </Button>
                  </div>
                </div>

                {/* Email Id */}
                <div className="w-1/2 space-y-2 mb-2">
                  <Label htmlFor="emailId" className="font-semibold text-lg manrope-fontCss">
                    Email Id
                  </Label>
                  <Field
                    name="emailId"
                    render={({ input, meta }) => (
                      <div className="w-full font-semibold text-lg manrope-fontCss">
                        <Input placeholder="example@gmail.com" {...input} disabled />
                        {meta.touched && meta.error && (
                          <div className="text-[#EF4444] text-sm my-3">{meta.error}</div>
                        )}
                      </div>
                    )}
                  />
                </div>
              </div>

              {/* Submit Button */}
              <div className="mb-2">
                <Button disabled={isDisabled}>Submit</Button>
              </div>
            </form>
          )}
        />
        <div>
          <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
            <DialogTrigger asChild>
              <Button
                className="bg-[#9747FF61] text-white font-medium text-base border-[#9747FF] border-2 w-36 h-10 mb-3"
                onClick={() => setIsDialogOpen(true)}>
                Reset Password
              </Button>
            </DialogTrigger>
            <DialogContent className="max-w-[70%]  bg-[#222938] text-white rounded-lg opacity-100">
              <DialogHeader>
                <DialogTitle className="text-2xl font-bold mb-2">Change Password</DialogTitle>
              </DialogHeader>

              <Form
                onSubmit={onSubmit}
                render={({ handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <div>
                      {/* Current Password */}
                      <div className="relative grid mb-4">
                        <div>
                          <Label
                            htmlFor="currentPassword"
                            className="text-white text-md font-semibold manrope-fontCss mb-2">
                            Current Password
                          </Label>
                        </div>

                        <Field
                          id="currentPassword"
                          type={showCurrentPassword ? 'text' : 'Password'}
                          name="currentPassword"
                          placeholder="Enter your Current Password"
                          className="bg-[#11141F] text-white border-[#34416D]">
                          {({ input, meta }) => (
                            <div>
                              <input
                                {...input}
                                className="w-full h-10 p-2 rounded bg-[#11141F] text-white border-[#34416D]"
                                onChange={(e) => {
                                  input.onChange(e);
                                  setCurrentPassword(e.target.value);
                                }}
                              />
                              {meta.error && meta.touched && (
                                <span className="text-red-500">{meta.error}</span>
                              )}
                            </div>
                          )}
                        </Field>
                        <div
                          className="absolute h-20 inset-y-1 right-0 flex items-center px-4 cursor-pointer"
                          onClick={toggleCurrentPasswordVisibility}>
                          {showCurrentPassword ? (
                            <EyeNoneIcon className="h-5 w-5 text-white" />
                          ) : (
                            <EyeOpenIcon className="h-5 w-5 text-white" />
                          )}
                        </div>
                      </div>

                      {/* New Password */}
                      <div className="relative grid mb-4">
                        <div>
                          <Label
                            htmlFor="newPassword"
                            className="text-white text-md font-semibold manrope-fontCss mb-2">
                            New Password
                          </Label>
                        </div>
                        <Field
                          id="newPassword"
                          type={showNewPassword ? 'text' : 'Password'}
                          name="newPassword"
                          placeholder="Enter your New Password"
                          className="bg-[#11141F] text-white border-[#34416D]">
                          {({ input, meta }) => (
                            <div>
                              <input
                                {...input}
                                className="w-full h-10 p-2 rounded bg-[#11141F] text-white border-[#34416D]"
                                onChange={(e) => {
                                  input.onChange(e);
                                  setNewPassword(e.target.value);
                                  validateNewPassword(e.target.value);
                                  setShowValidations(true);
                                }}
                                onFocus={() => setShowValidations(true)}
                              />
                              {meta.error && meta.touched && (
                                <span className="text-red-500">{meta.error}</span>
                              )}
                            </div>
                          )}
                        </Field>
                        <div
                          className="absolute h-20 inset-y-1 right-0 flex items-center px-4 cursor-pointer"
                          onClick={toggleNewPasswordVisibility}>
                          {showCurrentPassword ? (
                            <EyeNoneIcon className="h-5 w-5 text-white" />
                          ) : (
                            <EyeOpenIcon className="h-5 w-5 text-white" />
                          )}
                        </div>
                      </div>

                      {/* Password Validation Points */}
                      {showValidations && (
                        <div className="space-y-2 text-xs my-2">
                          <ValidationItem
                            condition={newPasswordValidation.length}
                            text="Password must be at least 8 characters long"
                            className="text-[#868686]"
                          />
                          <ValidationItem
                            condition={
                              newPasswordValidation.uppercase && newPasswordValidation.lowercase
                            }
                            text="Password must contain 1 Uppercase & 1 Lowercase letter"
                          />
                          <ValidationItem
                            condition={newPasswordValidation.digit}
                            text="Password must contain at least one digit like 1,2,3,4,5,6,etc."
                          />
                          <ValidationItem
                            condition={newPasswordValidation.special}
                            text="Password must contain at least one special character like !, @, #, $, etc."
                          />
                        </div>
                      )}

                      {/* Confirm New Password */}
                      <div className="relative grid mb-4">
                        <div>
                          <Label
                            htmlFor="confirmNewPassword"
                            className="text-white text-md font-semibold manrope-fontCss mb-2">
                            Confirm New Password
                          </Label>
                        </div>
                        <Field
                          id="confirmNewPassword"
                          name="confirmNewPassword"
                          type={showConfirmNewPassword ? 'text' : 'Password'}
                          placeholder="Re-enter New Password"
                          className="bg-[#11141F] text-white border-[#34416D]">
                          {({ input, meta }) => (
                            <div>
                              <input
                                {...input}
                                className="w-full h-10 p-2 rounded bg-[#11141F] text-white border-[#34416D]"
                                onChange={(e) => {
                                  input.onChange(e);
                                  setConfirmNewPassword(e.target.value);
                                }}
                              />
                              {meta.error && meta.touched && (
                                <span className="text-red-500">{meta.error}</span>
                              )}
                            </div>
                          )}
                        </Field>
                        <div
                          className="absolute h-20 inset-y-1 right-0 flex items-center px-4 cursor-pointer"
                          onClick={toggleConfirmNewPasswordVisibility}>
                          {showCurrentPassword ? (
                            <EyeNoneIcon className="h-5 w-5 text-white" />
                          ) : (
                            <EyeOpenIcon className="h-5 w-5 text-white" />
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              />
              <DialogFooter className="mt-4">
                <Button
                  type="submit"
                  className="bg-[#9747FF61] text-white font-medium text-base border-[#9747FF] border-2 w-36 h-10 mb-3"
                  onClick={onChangePassword}
                  disabled={
                    !Object.values(newPasswordValidation).every(Boolean) ||
                    newPassword != confirmNewPassword
                  }>
                  Change Password
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </main>
  );
}

export default withLoader(withUser(profile));
