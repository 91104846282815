import React, { useEffect, useState } from 'react';
import { Label } from '../../components/ui/label';
import { Input } from '../../components/ui/input';
import { Button } from '../../components/ui/button';
import { Card } from '../../components/ui/card';
import { Field, Form } from 'react-final-form';
import { DownloadIcon } from '../../assets/icons';
import RecommendationIcon from '../../assets/icons/recommendation-icon';
import { Tabs, TabsList, TabsTrigger } from '../../components/ui/tabs';
import CloseIcon from '../../assets/icons/close';
import withUser from '../../redux/Hoc/withUser';
import { useParams } from 'react-router-dom';
import { getSecurityTestDetails } from '../../utils/util';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import { useToast } from '../../components/ui/use-toast';
import SecurityResultPdf from '../../components/PDF/SecurityResultPdf';
import { PDFDownloadLink } from '@react-pdf/renderer';
import withLoader from '../../redux/Hoc/withLoader';

// const alerts = [
//   {
//     id: 1,
//     risk: 'Low',
//     title: 'Server Leaks Version Information via "Server" HTTP Response Header Field'
//   },
//   { id: 2, risk: 'Medium', title: 'Another Medium Risk Alert' },
//   { id: 3, risk: 'High', title: 'High Risk Alert Example' }
//   // ... more alerts
// ];

const renderContent = (aiData) => {
  const htmlContent = marked(aiData, { breaks: true });
  const sanitizedContent = DOMPurify.sanitize(htmlContent);

  return (
    <div className="w-full">
      <div
        className="prose max-w-full mt-4"
        dangerouslySetInnerHTML={{ __html: sanitizedContent }}
      />
    </div>
  );
};

function AlertCard({ alert, expanded, onToggleExpand, user, test_name, test_id ,loader}) {
  const [showRecommendation, setShowRecommendation] = useState(!!alert.model_solution);
  const [aiData, setAiData] = useState(alert.model_solution || 'Generating solution.....');
  const { toast } = useToast();

  

  const toggleRecommendation = async () => {
    

    if (!alert.model_solution) {
      setShowRecommendation(!showRecommendation);
      loader(true)
      try {
        const payload = {
          user_id: user.user_id,
          enterprise_id: user.enterprise_id ? user.enterprise_id : null,
          test_name: test_name,
          test_id: test_id,
          alert: alert.alert,
          object_id: user.object_id,
          subscription_id: user.activated_subscription_id
        };

        const response = await fetch(
          `${process.env.REACT_APP_MIDDLEWARE_API_ENDPOINT}/securityTestSolution`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${user.access_token}`
            },
            body: JSON.stringify(payload)
          }
        );


        loader(false)

        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let content = '';
        if (!response.ok) {
          const { value } = await reader.read();
          content += decoder.decode(value, { stream: true });
          console.log(content);

          content = JSON.parse(content);
          setAiData(content.message);
          throw new Error(content.message);
        }

        // Read the streaming response
        // eslint-disable-next-line no-constant-condition
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
          content += decoder.decode(value, { stream: true });
          console.log(content);

          setAiData(content);
        }
      } catch (error) {
        setShowRecommendation(!!showRecommendation)
        loader(false)
        console.log(error);
        toast({
          variant: 'destructive',
          title: 'Error',
          description: error.message
        });
      }
    } else {

      setShowRecommendation(!showRecommendation);
    }
  
  };

  const toggleRecommendationClose = () => {
    onToggleExpand(); // Collapse the card when the recommendation is closed
  };

  return (
    <Card className="w-full p-4 overflow-x-hidden">
      <div className="flex flex-row justify-between items-center space-x-4">
        {!expanded && (
          <div className="flex flex-col">
            <p className="font-bold text-lg">Alert: {alert.alert}</p>
            <p className="text-[#868686] text-md">
              <span className="font-bold text-white">Risk:</span> {alert.risk}
            </p>
          </div>
        )}

        {!expanded && (
          <div>
            <Button
              variant="none"
              className="text-md h-10 text-primary flex items-center justify-between"
              onClick={onToggleExpand}>
              {expanded ? 'Less Details' : 'More Details'}
            </Button>
          </div>
        )}
      </div>
      {expanded && (
        <div className={`grid ${showRecommendation ? 'grid-cols-2' : 'grid-cols-1'} gap-4`}>
          <Card className="bg-card p-4 h-[550px] overflow-auto">
            <div className="flex justify-between space-x-4">
              <div className="flex flex-col">
                <p className="font-black text-2xl">Alert: {alert.alert}</p>
              </div>
              {!showRecommendation && (
                <Button
                  className="bg-[#9747FF] hover:bg-[#9747FF] text-lg h-10 text-white flex space-x-2 items-center justify-between"
                  onClick={toggleRecommendation}>
                  <RecommendationIcon className="w-6 h-6" />
                  <span className="sr-only sm:not-sr-only text-sm font-light">
                    AI Recommendation
                  </span>
                </Button>
              )}
            </div>
            <div className="space-y-3 pt-3">
              <div>
                <p className="text-[#868686] text-md">
                  <span className="font-bold text-white">Description: </span>
                  {alert.description}
                </p>
                <p className="text-[#868686] pt-3 text-md">
                  <span className="font-bold text-white">Risk:</span> {alert.risk}
                </p>
              </div>
              {/* <div className="flex flex-col">
                <span className="font-bold text-white">Solution:</span>
                <div className="space-y-5">
                  <ul style={{ listStyleType: 'disc' }} className="ml-6 text-[#868686] text-md">
                    <li>{alert.solution}</li>
                  </ul>
                </div>
              </div> */}

              <div className="space-y-3">
                <div>
                  <p className="text-[#868686] text-md">
                    <span className="font-bold text-white">Findings:</span> {alert.other}
                  </p>
                </div>
              </div>
            </div>
          </Card>
          {showRecommendation && (
            <Card className="bg-card h-[550px] overflow-auto p-4">
              <div className="flex justify-between">
                <div className="flex gap-4 mt-2">
                  <RecommendationIcon className="w-2 h-2 text-primary" />
                  <span className="sr-only sm:not-sr-only text-sm font-light text-primary">
                    {alert.model_solution ? 'Model Solution' : 'Generating AI Recommendations...'}
                  </span>
                </div>
                <Button variant="ghost" onClick={toggleRecommendationClose}>
                  <CloseIcon  />
                </Button>
              </div>
              <h3 className="font-bold text-lg mb-4">AI Recommendation</h3>
              <div className="text-[#868686] text-md max-w-full">
                {aiData && renderContent(aiData)}
              </div>
            </Card>
          )}
        </div>
      )}
    </Card>
  );
}

const SecurityResult = ({ user ,setLoader}) => {
  const { id, name } = useParams();

  const onSubmit = () => {
    console.log('Form');
  };

  const [data, setData] = useState();
  const [alerts, setAlerts] = useState();

  useEffect(() => {
    
    fetchData();
    
  }, []);

  const fetchData = async () => {
    setLoader(true)
    try {
      const payload = {
        user_id: user.user_id,
        enterprise_id: user.enterprise_id ? user.enterprise_id : null,
        object_id: user.object_id,
        test_name: name,
        test_id: id,
        subscription_id: user.activated_subscription_id
      };
      const response = await getSecurityTestDetails(payload);
      setData(response.data.data);
      setAlerts(response?.data.data.type!=="security-rest-apiscan"?response.data.data.security_alert_data:response.data.data.security_alert_data[0].site[0].alerts);
      setLoader(false)
      console.log("heloo ",response.data.data.security_alert_data);
      // console.log("heloo api",response.data.data.security_alert_data[0].site[0].alerts);
    } catch (error) {
      console.log(error);
    }
  };

  const riskOrder = {
    High: 1,
    Medium: 2,
    Low: 3
  };

  const [selectedRisk, setSelectedRisk] = useState('all');
  const [expandedAlertId, setExpandedAlertId] = useState(null);

  const filteredAlerts = alerts
    ?.filter((alert) => alert.risk !== 'Informational' )
    .filter((alert) => selectedRisk === 'all' || alert.risk === selectedRisk)
    .sort((a, b) => riskOrder[a.risk] - riskOrder[b.risk]);

  const handleToggleExpand = (alertId) => {
    setExpandedAlertId(expandedAlertId === alertId ? null : alertId);
  };

  return (
    <main className="grid flex-1 items-start p-4 sm:py-0 h-full space-y-4 manrope-fontCss">
      <div className="flex flex-col space-y-3">
        <Form
          onSubmit={onSubmit}
          initialValues={{ testName: data?.test_name, targetURL: data?.url }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className="flex flex-col space-y-3">
              <div className="flex flex-col space-y-3">
                <div>
                  <Label htmlFor="testName">Test Name</Label>
                  <div className="flex flex-row space-x-5 ">
                    <Field
                      name="testName"
                      render={({ input, meta }) => (
                        <div className="w-1/2">
                          <Input placeholder="Your Test Name" {...input} disabled />
                          {meta.touched && meta.error && (
                            <div className="text-[#EF4444] text-sm my-3">{meta.error}</div>
                          )}
                        </div>
                      )}
                    />
                  </div>
                </div>
                <div>
                  <Label htmlFor="targetURL">Target URL</Label>
                  <Field
                    name="targetURL"
                    render={({ input, meta }) => (
                      <div className="w-1/2">
                        <Input placeholder="Enter your input here..." {...input} disabled />
                        {meta.touched && meta.error && (
                          <div className="text-[#EF4444] text-sm my-3">{meta.error}</div>
                        )}
                      </div>
                    )}
                  />
                </div>
              </div>
            </form>
          )}
        />
        <Card className="p-2 space-y-3 w-full">
          <div className="flex justify-between m-2">
            <Tabs defaultValue="all" value={selectedRisk} onValueChange={setSelectedRisk}>
              <TabsList>
                <TabsTrigger value="all">All Alerts</TabsTrigger>
                <TabsTrigger value="High">High Risk</TabsTrigger>
                <TabsTrigger value="Medium">Medium Risk</TabsTrigger>
                <TabsTrigger value="Low">Low Risk</TabsTrigger>
              </TabsList>
            </Tabs>
            <div>
              <PDFDownloadLink
                document={<SecurityResultPdf data={filteredAlerts} />}
                fileName="SecurityResult.pdf">
                <Button
                  variant="ghost"
                  className="h-9 w-[170px] text-xs bg-[#222938] hover:bg-[#222938] border-[#444444] border-2 hover:text-[#FFFFFF] flex items-center justify-between">
                  <DownloadIcon className="h-4 w-4" />
                  <span className="sr-only sm:not-sr-only text-sm font-light">Download Report</span>
                </Button>
              </PDFDownloadLink>
            </div>
          </div>
          <hr />
          {filteredAlerts?.map((alert) => (
            <AlertCard
              key={alert.id}
              alert={alert}
              expanded={expandedAlertId === alert.id}
              onToggleExpand={() => handleToggleExpand(alert.id)}
              user={user}
              test_name={name}
              test_id={id}
              loader={setLoader}
            />
          ))}
        </Card>
      </div>
    </main>
  );
};

export default withLoader(withUser(SecurityResult));
