export default function PrerequisiteIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 41 41" fill="white" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.13306 13.5333L7.46639 16.8667L14.1331 10.2"
        stroke="white"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.7998 13.5334H37.4665"
        stroke="white"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M4.13306 26.8667L7.46639 30.2L14.1331 23.5334"
        stroke="white"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.7998 26.8667H37.4665"
        stroke="white"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
}
