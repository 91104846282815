import React, { useState, useEffect } from 'react';
import { Form, Field } from 'react-final-form';
import { Link, useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import { getUserDetailAfterGoogleLogin, getUserGoogleToken } from '../../utils/util';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';

import { Button } from '../../components/ui/button';
import { Label } from '../../components/ui/label';
import { useToast } from '../../components/ui/use-toast';
import logoImage from '../../assets/images/Scale-secure-logo-white.png';
import google from '../../assets/images/Google.svg';
import microsoft from '../../assets/images/Microsoft.svg';
import { composeValidators, email, renderField, required } from '../../helpers/form-validations';
import withLoader from '../../redux/Hoc/withLoader';
import withUser from '../../redux/Hoc/withUser';
import { InputOTP, InputOTPGroup, InputOTPSlot } from '../../components/ui/input-otp';
import { isEmpty } from 'lodash';
import { generateOtp, verifyOtp } from '../../utils/util';
import { isBusinessEmail } from '../../helpers/global';
import { LOGIN_TYPE } from '../../constants/index';
import right from '../../assets/images/right.png';
import Backdrop from '../../components/Backdrop/backdrop';
import { signup } from '../../utils/util';
import { EyeOpenIcon, EyeNoneIcon } from '@radix-ui/react-icons';
import { generateToken } from '../../notifications/firebase';

const Signup = ({ setLoader, setUser }) => {
  const [emailValid, setEmailValid] = useState(false);
  const { toast } = useToast();
  const [step, setStep] = useState(1);
  const [showEmail, setShowEmail] = useState(true);
  // const [otpError, setOTPError] = useState('');
  const { instance, inProgress, accounts } = useMsal();
  const [formErrors, setFormErrors] = useState({});
  const [enteredtEmail, setEnteredtEmail] = useState('');
  const [otpError, setOtpError] = useState(false);
  const navigate = useNavigate();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showValidations, setShowValidations] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    digit: false,
    special: false
  });

  const validateEmail = (value) => {
    const isValid =
      composeValidators(required, email)(value) === undefined && isBusinessEmail(value);
    setEmailValid(isValid);
    return isValid ? undefined : 'Required Kindly Register with Business Email';
  };

  const Approvedicon = () => {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <circle cx="14" cy="14" r="14" fill="#24AC54" />
        <path
          d="M6.98804 13.9849L6.98804 13.9849C6.83586 14.1359 6.75 14.3411 6.75 14.5554C6.75 14.7698 6.83586 14.975 6.98804 15.126L10.9078 19.0145C10.9831 19.0893 11.0724 19.1485 11.1706 19.1889C11.2688 19.2292 11.3739 19.25 11.4801 19.25C11.5863 19.25 11.6914 19.2292 11.7896 19.1889C11.8878 19.1485 11.9771 19.0893 12.0525 19.0145L21.012 10.1263C21.1641 9.97537 21.25 9.77019 21.25 9.55582C21.25 9.34146 21.1641 9.13628 21.012 8.98531L21.012 8.98531C20.8599 8.83442 20.654 8.75 20.4397 8.75C20.2255 8.75 20.0196 8.83442 19.8675 8.98531L19.8675 8.98532L11.4801 17.3065L8.13255 13.985L8.13254 13.9849C7.98043 13.834 7.77455 13.7496 7.56029 13.7496C7.34603 13.7496 7.14014 13.834 6.98804 13.9849Z"
          fill="white"
          stroke="white"
          strokeWidth="0.5"
        />
      </svg>
    );
  };

  const Passwordicon = () => {
    return (
      <svg
        width="15"
        height="15"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <circle cx="8" cy="8" r="8" fill="#34416D" />
      </svg>
    );
  };

  useEffect(() => {
    if (accounts.length > 0 && inProgress === InteractionStatus.None) {
      handleMicrosoftLoginSuccess();
    }
  }, [accounts, inProgress]);

  const onEmailSubmit = async (formData) => {
    const payload = {
      email: formData.email,
      isResend: false
    };
    setEnteredtEmail(formData);

    if (!isBusinessEmail(formData.email)) {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'You can only register with business email!'
      });
      return false;
    }
    setShowEmail(false);
    try {
      setLoader(true);
      const otpRes = await generateOtp(payload);
      setLoader(false);
      if (otpRes.data.code === 200) {
        setStep(2);
        toast({
          variant: 'success',
          title: 'Success',
          description:
            'OTP has been generated and sent to the registered email address. Please check your inbox.'
        });
      } else {
        toast({
          variant: 'destructive',
          title: 'Error',
          description: otpRes.data.message
        });
        navigate('/login');
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message === 'Email already exists'
      ) {
        toast({
          variant: 'destructive',
          title: 'Error',
          description: 'Email already exists. Please use a different email.'
        });
      } else {
        console.error('Failed to generate OTP:', error.message);
      }
    }
  };

  const onOtpSubmit = async (formData) => {
    if (isEmpty(formData.otp) || formData.otp.length !== 6) {
      setOtpError(true);
      setFormErrors({ otp: 'Invalid OTP entered. Please re-check your email and enter!' });
      return;
    } else {
      setOtpError(false);
      setFormErrors({});
      const payload = {
        email: enteredtEmail.email,
        otp: formData.otp
      };

      setLoader(true);
      try {
        const response = await verifyOtp(payload);
        setLoader(false);
        if (response.data.message === 'Invalid OTP') {
          setOtpError(true);
          setFormErrors({ otp: 'Invalid OTP entered. Please re-check your email and enter!' });
        } else if (response.status === 200) {
          setStep(3);
          toast({
            variant: 'success',
            title: 'Success',
            description: 'OTP has been verified. Please enter a strong password.'
          });
        } else {
          toast({
            variant: 'destructive',
            title: 'Error',
            description: response.data.message
          });
        }
      } catch (error) {
        setLoader(false);
        console.error('Failed to verify OTP:', error.message);
        setOtpError(true);
        setFormErrors({ otp: 'Failed to verify OTP' });
        toast({
          variant: 'destructive',
          title: 'Error',
          description: 'Failed to verify OTP'
        });
      }
    }
  };

  const onPasswordSubmit = async (formData) => {
    console.log('check here into signup');
    if (Object.values(passwordValidation).every(Boolean) && password === confirmPassword) {
      const device_id = await generateToken();
      const payload = {
        email: formData.email,
        password: formData.password,
        device_id: device_id
        // token: token
      };
      setLoader(true);
      console.log('check here password', payload);
      const resetRes = await signup(payload);
      console.log(resetRes);
      setLoader(false);
      if (resetRes.status === 200) {
        toast({
          variant: 'success',
          title: 'Success',
          description: 'Successfully Signed Up'
        });
        setUser(resetRes.data.data);
        navigate('/plans');
      } else {
        toast({
          variant: 'destructive',
          title: 'Error',
          description: resetRes.data.message
        });
      }
    } else {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'Please ensure all password requirements are met and passwords match.'
      });
    }
  };

  // Google Login
  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const userDetail = await getUserGoogleToken(tokenResponse.access_token);

        if (!isBusinessEmail(userDetail.data.email)) {
          toast({
            variant: 'destructive',
            title: 'Error',
            description: 'You can only register with business email!'
          });
          return false;
        }

        const loginDetail = await getUserDetailAfterGoogleLogin({
          email: userDetail.data.email,
          access_token: tokenResponse.access_token,
          user_name: userDetail.data.name,
          client_name: userDetail.data.email.split('@')[1],
          type: LOGIN_TYPE.OAUTH
        });

        if (loginDetail.data.status) {
          setUser(loginDetail.data.data);
          navigate('/');
          toast({
            variant: 'success',
            title: 'Success',
            description: loginDetail.data.message
          });
        } else {
          toast({
            variant: 'destructive',
            title: 'Error',
            description: loginDetail.data.message
          });
        }
      } catch (error) {
        console.error('Error during Google login:', error);
        toast({
          variant: 'destructive',
          title: 'Error',
          description: 'Failed to login with Google'
        });
      }
    },
    onError: (error) => {
      console.error('Error Google Login: ', error);
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'Failed to login with Google'
      });
    }
  });

  // Microsoft Login
  const handleMicrosoftLogin = async () => {
    console.log('Microsoft login initiated');
    const loginRequest = {
      scopes: ['user.read']
      // redirectUri: `${window.location.origin}`
    };
    await instance.loginPopup(loginRequest);
  };

  const getUserMicrosoftDetails = async (accessToken) => {
    console.log('Fetching Microsoft user details');
    const response = await fetch('https://graph.microsoft.com/v1.0/me', {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch user details from Microsoft Graph API');
    }

    const data = await response.json();

    console.log('Microsoft user details fetched', data);

    return {
      email: data.mail || data.userPrincipalName,
      name: data.displayName,
      id: data.id
    };
  };

  const handleMicrosoftLoginSuccess = async () => {
    console.log('Microsoft login successful');
    const account = instance.getAllAccounts()[0];

    console.log('Acquiring token silently');
    const response = await instance.acquireTokenSilent({
      scopes: ['user.read'],
      account: account
    });

    console.log('Token acquired silently');

    const userDetail = await getUserMicrosoftDetails(response.accessToken);
    console.log(response.accessToken, userDetail);

    if (!isBusinessEmail(userDetail.email)) {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'You can only register with business email!'
      });
      return false;
    }

    const loginDetail = await getUserDetailAfterGoogleLogin({
      email: userDetail.email,
      access_token: response.accessToken,
      user_name: userDetail.name,
      client_name: userDetail.email.split('@')[1],
      type: LOGIN_TYPE.OAUTH
    });
    console.log('Microsoft login details:', loginDetail);

    if (loginDetail.data.status) {
      setUser(loginDetail.data.data);
      console.log('Navigating to dashboard');
      navigate('/loading');
      toast({
        variant: 'success',
        title: 'Success',
        description: loginDetail.data.message
      });
    } else {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: loginDetail.data.message
      });
    }
  };

  const validatePassword = (value) => {
    const newValidation = {
      length: value.length >= 8,
      uppercase: /[A-Z]/.test(value),
      lowercase: /[a-z]/.test(value),
      digit: /\d/.test(value),
      special: /[!@#$%^&*(),.?":{}|<>]/.test(value)
    };
    setPasswordValidation(newValidation);

    if (Object.values(newValidation).every(Boolean)) {
      setShowValidations(false);
    }
  };

  const ValidationItem = ({ condition, text }) => (
    <div className="w-full flex flex-row items-center">
      <div className="relative flex justify-center items-center">
        <div className={`w-3 h-4 rounded-full ${condition ? 'text-green-500' : 'text-[#868686]'}`}>
          {condition ? (
            <Approvedicon width={5} height={5} />
          ) : (
            <Passwordicon width={5} height={5} />
          )}
        </div>
        {!(
          text === 'Password must contain at least one special character like !, @, #, $, etc.'
        ) && (
          <div
            className={`absolute left-1.5 top-4 transform -translate-y-1/2 w-0.5 h-7 ${
              condition ? 'bg-[#24AC54]' : 'bg-[#34416D]'
            }`}></div>
        )}
      </div>
      <p className="ml-4 text-xs text-[#868686]">{text}</p>
    </div>
  );

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div className="w-full grid h-[100vh] grid-cols-1 sm:grid-cols-2 md:h-[100vh] lg:grid-cols-2 lg:h-[100vh] xl:h-[100vh] 2xl:h-[100vh] manrope-fontCss">
      <Backdrop />
      <Form
        onSubmit={
          step === 1
            ? onEmailSubmit
            : step === 2
              ? onOtpSubmit
              : step === 3
                ? onPasswordSubmit
                : undefined
        }
        render={({ handleSubmit }) => (
          <div
            className="relative flex items-start justify-center w-auto overflow-hidden "
            style={{
              backgroundImage: `url(${right})`,
              backgroundPosition: 'right',
              backgroundSize: 'cover'
            }}>
            <form
              onSubmit={handleSubmit}
              className="flex items-center justify-center w-[90%] h-[100%] flex-col">
              <div className="flex justify-center items-center w-full">
                <img width={'219px'} height={'107px'} src={logoImage} alt="" />
              </div>
              <div
                className="flex flex-col min-w-full gap-6 border rounded-xl px-10 py-6 h-fit"
                style={{ backgroundColor: 'rgba(30, 34, 48,0.93)' }}>
                <div className="text-start space-y-2">
                  <h1 className="text-2xl sm:text-lg lg:text-xl xl:text-2xl font-semibold manrope-fontCss">
                    Sign Up
                  </h1>
                  <p className="font-normal text-sm text-muted-foreground mr-14 manrope-fontCss">
                    Hello there! Looks like you are new here, Sign Up now.
                  </p>
                </div>

                <div className="grid gap-2 space-y-2 mt-2">
                  {showEmail && (
                    <div className="grid gap-2">
                      <Label htmlFor="email" className="text-base font-semibold manrope-fontCss">
                        Email<span className="text-[#EF4444] manrope-fontCss">*</span>
                      </Label>
                      <Field
                        id="email"
                        type="email"
                        name="email"
                        placeholder="m@example.com"
                        validate={(composeValidators(required, email), validateEmail)}
                        className="bg-[#11141F] h-12 w-full rounded-xl text-base border-[#34416D] manrope-fontCss">
                        {renderField}
                      </Field>
                    </div>
                  )}
                  {step === 1 && (
                    <Button
                      type="submit"
                      className={`text-white w-[30%] h-9 text-sm manrope-fontCss mt-4 ${
                        emailValid ? '' : 'opacity-50'
                      }`}
                      disabled={!emailValid}>
                      Generate OTP
                    </Button>
                  )}
                  {step === 2 && (
                    <div>
                      <div>
                        <p className="text-xl font-semibold">Enter OTP</p>
                      </div>
                      <div className=" grid grid-row items-center">
                        <div className="grid grid-row py-3">
                          <Field name="otp">
                            {({ input }) => (
                              <InputOTP
                                maxLength={6}
                                {...input}
                                className={`flex items-center gap-1 mb-4 justify-between  ${otpError && 'error'}`}>
                                {[...Array(6)].map((_, index) => (
                                  <React.Fragment key={index}>
                                    <InputOTPGroup>
                                      <InputOTPSlot
                                        index={index}
                                        className={`flex justify-center w-16 h-12 2xl:w-24 2xl:h-16 text-lg  text-center bg-[#11141F] border ${otpError ? 'border-red-500' : 'border-[#34416D]'} rounded-md items-center`}
                                      />
                                    </InputOTPGroup>
                                    {index < 5 && (
                                      <span
                                        className={`${otpError ? 'text-red-500' : 'text-[#34416D]'} text-2xl 2xl:text-5xl`}>
                                        -
                                      </span>
                                    )}
                                  </React.Fragment>
                                ))}
                              </InputOTP>
                            )}
                          </Field>
                        </div>
                        {formErrors.otp && (
                          <p className="text-red-500 text-base text-left mb-5">{formErrors.otp}</p>
                        )}
                        <div className="flex flex-row space-x-4 justify-between items-center">
                          <Button
                            type="submit"
                            className=" w-36 h-8 rounded-base manrope-fontCss text-white text-sm">
                            Confirm & Sign Up
                          </Button>
                          <p className="text-sm font-medium">
                            Didn&apos;t receive OTP?{' '}
                            <span className="text-[#9747FF] mr-1">Resend Now</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  {step === 3 && (
                    <div className="password-step">
                      <div className="text-start space-y-2 mr-16">
                        <h1 className="text-xl sm:text-xl lg:text-2xl xl:text-2xl font-semibold manrope-fontCss">
                          Create Password
                        </h1>
                        <p className="font-normal text-sm manrope-fontCss text-muted-foreground manrope-fontCss">
                          This will take some effort, Relax and then get started!
                        </p>
                      </div>

                      <div className="grid gap-4 mt-3">
                        {/* Set Password */}
                        <div className="relative grid gap-1">
                          <div className="flex items-center">
                            <Label
                              htmlFor="password"
                              className="text-white text-sm font-semibold manrope-fontCss">
                              Set-up your 8+ digits password
                            </Label>
                          </div>
                          <Field
                            id="password"
                            type={showPassword ? 'text' : 'password'}
                            name="password"
                            placeholder="Start entering your new password here..."
                            validate={composeValidators(required)}
                            className="bg-[#11141F] text-white text-base font-semibold">
                            {({ input, meta }) => (
                              <div>
                                <input
                                  {...input}
                                  className="w-full h-10 p-2 rounded bg-[#11141F] text-white "
                                  onChange={(e) => {
                                    input.onChange(e);
                                    setPassword(e.target.value);
                                    validatePassword(e.target.value);
                                    setShowValidations(true);
                                  }}
                                  onFocus={() => setShowValidations(true)}
                                />
                                {meta.error && meta.touched && (
                                  <span className="text-red-500">{meta.error}</span>
                                )}
                              </div>
                            )}
                          </Field>
                          <div
                            className="absolute h-20 inset-y-1 right-0 flex items-center px-4 cursor-pointer"
                            onClick={togglePasswordVisibility}>
                            {showPassword ? (
                              <EyeNoneIcon className="h-5 w-5 text-white" />
                            ) : (
                              <EyeOpenIcon className="h-5 w-5 text-white" />
                            )}
                          </div>
                        </div>
                        {/* Cofirm Password */}
                        <div className="relative grid gap-2">
                          <div className="flex items-center">
                            <Label
                              htmlFor="confirmPassword"
                              className="text-white text-sm font-semibold manrope-fontCss">
                              Confirm New Password
                            </Label>
                          </div>
                          <Field
                            id="confirmPassword"
                            type={showConfirmPassword ? 'text' : 'password'}
                            name="confirmPassword"
                            placeholder="Start entering your new password here..."
                            validate={composeValidators(required)}
                            className="bg-[#11141F] text-white border-[#34416D]">
                            {({ input, meta }) => (
                              <div>
                                <input
                                  {...input}
                                  className="w-full h-10 p-2 rounded bg-[#11141F] text-white border-[#34416D]"
                                  onChange={(e) => {
                                    input.onChange(e);
                                    setConfirmPassword(e.target.value);
                                  }}
                                />
                                {meta.error && meta.touched && (
                                  <span className="text-red-500">{meta.error}</span>
                                )}
                              </div>
                            )}
                          </Field>
                          <div
                            className="absolute h-20 inset-y-2 right-0 flex items-center px-4 cursor-pointer"
                            onClick={toggleConfirmPasswordVisibility}>
                            {showConfirmPassword ? (
                              <EyeNoneIcon className="h-5 w-5 text-white" />
                            ) : (
                              <EyeOpenIcon className="h-5 w-5 text-white" />
                            )}
                          </div>
                        </div>
                        {/* Password Validation points */}
                        {showValidations && (
                          <div className="space-y-2 text-xs ">
                            <ValidationItem
                              condition={passwordValidation.length}
                              text="Password must be at least 8 characters long"
                              className="text-[#868686]"
                            />
                            <ValidationItem
                              condition={
                                passwordValidation.uppercase && passwordValidation.lowercase
                              }
                              text="Password must contain 1 Uppercase & 1 Lowercase letter"
                            />
                            <ValidationItem
                              condition={passwordValidation.digit}
                              text="Password must contain at least one digit like 1,2,3,4,5,6,etc."
                            />
                            <ValidationItem
                              condition={passwordValidation.special}
                              text="Password must contain at least one special character like !, @, #, $, etc."
                            />
                          </div>
                        )}
                        {/* SignUp button */}
                        <Button
                          type="submit"
                          className="w-36 h-8 text-sm text-white bg-[#9747FF] hover:bg-[#9747FF] mt-2 manrope-fontCss"
                          disabled={
                            !Object.values(passwordValidation).every(Boolean) ||
                            password !== confirmPassword
                          }>
                          Save & Sign Up
                        </Button>
                      </div>
                    </div>
                  )}

                  <div className="flex  items-center justify-center w-[80%] mr-12 ml-12">
                    <div className="border-2 border-[#363636] rounded-xl flex-grow "></div>
                    <span className="mx-4 text-gray-400">OR</span>
                    <div className="border-2 border-[#363636] rounded-xl flex-grow"></div>
                  </div>
                  <div className="flex space-x-4 justify-center items-center">
                    <div className="h-10 w-10 rounded-full bg-[#11141F] flex justify-center items-center">
                      <img
                        onClick={() => googleLogin()}
                        src={google}
                        alt="Google"
                        className="h-5 w-5 cursor-pointer"
                      />
                    </div>
                    <div className="h-10 w-10 rounded-full bg-[#11141F] flex justify-center items-center">
                      <img
                        onClick={handleMicrosoftLogin}
                        src={microsoft}
                        alt="Microsoft"
                        className="h-5 w-5 cursor-pointer"
                      />
                    </div>
                  </div>
                  <div
                    className="mx-auto grid w-[90%] h-7 gap-6 border rounded-base p-1"
                    style={{ backgroundColor: '#2B3043' }}>
                    <div className="flex flex-row justify-center items-start space-x-2 text-xs">
                      <span className="text-[#9747FF] text-xs">Note:</span>
                      <p className="text-[#ADADAD] text-xs">
                        {''}Signing up via Google saves your time ~20 seconds
                      </p>
                    </div>
                  </div>
                </div>

                <div className="pb-2 text-center text-base">
                  Already have an account?{' '}
                  <Link to="/login" className="text-[#9747FF]">
                    Login
                  </Link>
                </div>
              </div>
            </form>
          </div>
        )}
      />
    </div>
  );
};

export default withLoader(withUser(Signup));
