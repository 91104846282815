export default function ArchitectureIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 28 34" fill="#94a3b8" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.383789 7.8C0.383789 5.43608 2.27023 3.60552 4.61232 2.43449C7.02318 1.22906 10.2733 0.51667 13.8005 0.51667C17.3276 0.51667 20.5778 1.22906 22.9887 2.43449C25.3307 3.60552 27.2171 5.43608 27.2171 7.8V26.2C27.2171 28.5639 25.3307 30.3944 22.9887 31.5654C20.5778 32.7709 17.3276 33.4833 13.8005 33.4833C10.2733 33.4833 7.02318 32.7709 4.61232 31.5654C2.27023 30.3944 0.383789 28.5639 0.383789 26.2V7.8ZM2.68379 7.8C2.68379 6.77658 3.54333 5.54047 5.64091 4.49167C7.6697 3.47728 10.5529 2.81667 13.8005 2.81667C17.0481 2.81667 19.9312 3.47728 21.9599 4.49167C24.0575 5.54047 24.9171 6.77658 24.9171 7.8C24.9171 8.82342 24.0575 10.0595 21.9599 11.1083C19.9312 12.1227 17.0481 12.7833 13.8005 12.7833C10.5529 12.7833 7.6697 12.1227 5.64091 11.1083C3.54333 10.0595 2.68379 8.82342 2.68379 7.8ZM2.68379 26.2C2.68379 27.2233 3.54333 28.4595 5.64091 29.5083C7.6697 30.5228 10.5529 31.1833 13.8005 31.1833C17.0481 31.1833 19.9312 30.5228 21.9599 29.5083C24.0575 28.4595 24.9171 27.2233 24.9171 26.2V21.151C24.3395 21.6118 23.6842 22.0177 22.9887 22.3654C20.5778 23.5709 17.3276 24.2833 13.8005 24.2833C10.2733 24.2833 7.02318 23.5709 4.61232 22.3654C3.9168 22.0177 3.26147 21.6118 2.68379 21.151V26.2ZM24.9171 11.9511V17C24.9171 18.0233 24.0575 19.2595 21.9599 20.3083C19.9312 21.3228 17.0481 21.9833 13.8005 21.9833C10.5529 21.9833 7.6697 21.3228 5.64091 20.3083C3.54333 19.2595 2.68379 18.0233 2.68379 17V11.9511C3.26147 12.4118 3.9168 12.8178 4.61232 13.1655C7.02318 14.3709 10.2733 15.0833 13.8005 15.0833C17.3276 15.0833 20.5778 14.3709 22.9887 13.1655C23.6842 12.8178 24.3395 12.4118 24.9171 11.9511Z"
        fill="#94a3b8"
      />
    </svg>
  );
}
