export default function RightArrowIcon() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="16" fill="white" />
      <path
        d="M16.4218 10.9784L19.9443 14.5003H9.3998C9.10807 14.5003 8.82828 14.6162 8.62199 14.8225C8.4157 15.0288 8.2998 15.3086 8.2998 15.6003C8.2998 15.892 8.4157 16.1718 8.62199 16.3781C8.82828 16.5844 9.10807 16.7003 9.3998 16.7003H19.9443L16.4218 20.2222L16.4218 20.2223C16.2154 20.4286 16.0995 20.7085 16.0995 21.0003C16.0995 21.2921 16.2154 21.572 16.4218 21.7784C16.6281 21.9847 16.908 22.1006 17.1998 22.1006C17.4916 22.1006 17.7715 21.9847 17.9779 21.7784L23.3777 16.3786C23.3777 16.3785 23.3778 16.3784 23.3779 16.3784C23.48 16.2762 23.5611 16.155 23.6164 16.0215L23.1545 15.8301L23.6164 16.0215C23.6718 15.888 23.7003 15.7449 23.7003 15.6003C23.7003 15.4558 23.6718 15.3126 23.6164 15.1791L23.1545 15.3705L23.6164 15.1791C23.5611 15.0456 23.48 14.9244 23.3779 14.8223C23.3778 14.8222 23.3777 14.8221 23.3777 14.8221L17.9779 9.42226C17.7715 9.2159 17.4916 9.09998 17.1998 9.09998C16.908 9.09998 16.6281 9.2159 16.4218 9.42226C16.2154 9.62861 16.0995 9.90848 16.0995 10.2003C16.0995 10.4921 16.2154 10.772 16.4218 10.9784L16.4218 10.9784Z"
        fill="#9747FF"
        stroke="#9747FF"
      />
    </svg>
  );
}
