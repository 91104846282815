import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Button } from '../ui/button';
import {
  GreenTickIcon,
  DangerProfile,
  ProNoteUserIcon,
  AdminIcon,
//   UserIcon
} from '../../assets/icons';

const VioletButton = ({ buttonName, handleButton }) => {
  const borderColor = '#9747ff61';
  const bgColor = '#9747FF';

  return (
    <Button
      onClick={handleButton}
      style={{
        borderColor: bgColor,
        backgroundColor: borderColor,
        color: '#FFFFFF',
        opacity: 1
      }}
      className="font-semibold text-base manrope-fontCss border-2 h-10 text-white border-[#9747ff61]">
      {buttonName}
    </Button>
  );
};

const BlueButton = ({ buttonName, handleButton }) => {
  const borderColor = '#444444';
  const bgColor = '#222938';
  return (
    <Button
      onClick={handleButton}
      style={{
        backgroundColor: bgColor,
        borderColor: borderColor,
        color: 'white',
        opacity: 1,
        borderWidth: '2px',
        borderStyle: 'solid'
      }}
      className="font-semibold text-base manrope-fontCss h-10">
      {buttonName}
    </Button>
  );
};

const notificationData = [
  {
    user: <GreenTickIcon />,
    title: 'Test has been completed successfully',
    time: 'Friday 03:12 PM',
    duration: '02 minutes',
    buttonName: [],
    buttonColor: []
  },
  {
    user: <DangerProfile />,
    title: 'Test 3 has failed due to some issue',
    time: 'Friday 03:12 PM',
    duration: '02 minutes',
    buttonName: ['View test'],
    buttonColor: ['violet']
  },
  {
    user: <DangerProfile />,
    title: 'Your subscription will end in 72 hours',
    time: 'Friday 03:12 PM',
    duration: '02 minutes',
    buttonName: ['Remove', 'Renew Subscription'],
    buttonColor: ['blue', 'violet']
  },
  {
    user: <ProNoteUserIcon />,
    title: 'Congrats! you are now a pro member!',
    time: 'Friday 03:12 PM',
    duration: '02 minutes',
    buttonName: ['Check benefits'],
    buttonColor: ['violet']
  },
  {
    user: <AdminIcon />,
    title: 'Admin invited you to ScaleSecure 1.0',
    time: 'Friday 03:12 PM',
    duration: '02 minutes',
    buttonName: ['Reject', 'Accept'],
    buttonColor: ['blue', 'violet']
  },
  {
    user: <AdminIcon />,
    title: 'Admin removed you from ScaleSecure 1.0',
    time: 'Friday 03:12 PM',
    duration: '02 minutes',
    buttonName: ['Know why?'],
    buttonColor: ['blue']
  },
  {
    user: <AdminIcon />,
    title: "Welcome back Anshul, let's get started",
    time: 'Friday 03:12 PM',
    duration: '02 minutes',
    buttonName: ['Keep exploring'],
    buttonColor: ['violet']
  }
];

function NotificationMenu({ anchorEl, open, onClose }) {
  const renderButton = (name, color) => {
    switch (color) {
      case 'violet':
        return <VioletButton buttonName={name} handleButton={() => console.log(name)} />;
      case 'blue':
        return <BlueButton buttonName={name} handleButton={() => console.log(name)} />;
      default:
        return null;
    }
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl && open)}
      onClose={onClose}
      PaperProps={{
        style: {
          width: '418px',
          backgroundColor: '#222938',
          color: 'white',
          marginRight: '40px',
          marginTop: '10px',
          padding: '0px'
        }
      }}
      className="manrope-fontCss">
      <p className={`px-6 py-2 pb-4 text-xl font-bold`}>Notifications</p>
      <hr className="h-px bg-[#6F6F6F] border-0" />
      {notificationData.map((notification, index) => (
        <div key={index}>
          <div className="px-3 py-4">
            <MenuItem >
              <div className="flex gap-2 w-full">
                <div>
                  {notification?.user}
                </div>
                <div className={`w-full`}>
                  <p className={`mb-[2px] text-base`}>{notification?.title}</p>
                  <p className={`flex justify-between w-full text-sm text-[#CFCFCF]`}>
                    <span>{notification?.time}</span>
                    <span>{notification?.duration}</span>
                  </p>
                  <div className={`flex gap-2 mt-3`}>
                    {notification.buttonName.map((name, idx) =>
                      renderButton(name, notification.buttonColor[idx])
                    )}
                  </div>
                </div>
              </div>
            </MenuItem>
          </div>
          <hr className="h-[0.5px] bg-[#6F6F6F] border-0" />
        </div>
      ))}
      {/* <hr className="h-[0.5px] bg-[#6F6F6F] border-0" /> */}
    </Menu>
  );
}

export default NotificationMenu;
