export const DASHBOARD_TABLE_TABS = {
  LOAD: 'load',
  SECURITY: 'security'
};

export const LOAD_TEST_METHODS = [
  {
    method: 'get',
    label: 'GET'
  },
  {
    method: 'post',
    label: 'POST'
  },
  {
    method: 'delete',
    label: 'DELETE'
  },
  {
    method: 'put',
    label: 'PUT'
  }
];

export const LOAD_TEST_AUTH_TYPE = [
  {
    type: 'noAuth',
    label: 'No Auth'
  },
  {
    type: 'basicAuth',
    label: 'Basic Auth'
  },
  {
    type: 'bearerToken',
    label: 'Bearer Token'
  },
  { type: 'jwtbearer', label: 'JWT BearerToken' },
  { type: 'digitalauth', label: 'Digital Auth' },
  { type: 'oauth1', label: 'OAuth 1.0' },
  { type: 'oauth2', label: 'OAuth 2.0' },
  { type: 'hawk', label: 'Hawk Authentication' },
  { type: 'awssign', label: 'AWS Signature' },
  { type: 'nltm', label: 'NLTM Authentication' }
];
export const LOAD_TEST_BODY_TYPE = [
  {
    type: 'raw',
    label: 'Raw'
  },
  {
    type: 'json',
    label: 'Json'
  }
];

export const PERSONAL_EMAIL_DOMAINS = [
  'gmail.com',
  'yahoo.com',
  'hotmail.com',
  'outlook.com',
  'aol.com',
  'icloud.com',
  'live.com',
  'msn.com'
];

export const LOGIN_TYPE = {
  PLATFORM: 'platform',
  OAUTH: 'oauth',
  MICROSOFT: 'microsoft',
  GOOGLE: 'google'
};

export const BASIC_PLAN_FEATURES = [
  '1 Load Testing of each type',
  'Overall limit of 1 Load Testing',
  'Overall limit of 1 Security Testing',
  'Overall limit of 100 Virtual Users',
  'Overall limit of 1 AI Recommendations'
];

export const STANDARD_PLAN_FEATURES = [
  '5 AI Recommendations for Load Testing',
  'Monthly limit of N Load Testings',
  'Monthly limit of 5 Security Testing',
  'Monthly limit of 5000 Virtual Users',
  '2 AI Recommendations for Security'
];

export const PRO_PLAN_FEATURES = [
  '35 AI Recommendations across tests',
  'Monthly limit of N Load Testing',
  'Monthly limit of 30 Security Testing',
  'Monthly limit of 50000 Virtual Users',
  '5 Architecture Suggestions'
];

export const ENTERPRISE_PLAN_FEATURES = [
  'Custom Number of  Architectures',
  'Custom Limit of Load Testing',
  'Custom Limit of Security Testing',
  'Custom Limit of  Virtual Users',
  'Custom Limit of AI Recommendations'
];

export const YEARLY_BASIC_PLAN_FEATURES = [
  '1 Load Testing of each type',
  'Overall limit of 1 Load Testing',
  'Overall limit of 1 Security Testing',
  'Overall limit of 100 Virtual Users',
  'Overall limit of 1 AI Recommendations'
];

export const YEARLY_STANDARD_PLAN_FEATURES = [
  '60 AI Recommendations for Load Testing',
  'Monthly limit of N Load Testings',
  'Monthly limit of 60 Security Testing',
  'Monthly limit of 60000 Virtual Users',
  '24 AI Recommendations for Security'
];

export const YEARLY_PRO_PLAN_FEATURES = [
  '420 AI Recommendations across tests',
  'Monthly limit of N Load Testing',
  'Monthly limit of 360 Security Testing',
  'Monthly limit of 600000 Virtual Users',
  '60 Architecture Suggestions'
];

export const YEARLY_ENTERPRISE_PLAN_FEATURES = [
  'Custom Number of  Architectures',
  'Custom Limit of Load Testing',
  'Custom Limit of Security Testing',
  'Custom Limit of  Virtual Users',
  'Custom Limit of AI Recommendations'
];

export const PREREQUISITE_FORM_INFO = {
  AWS: {
    'frontend-server': ['EC2', 'EKS', 'Elastic Beanstalk', 'ECS (With EC2)'],
    'frontend-serverless': ['S3 + Cloudfront', 'AWS Amplify', 'ECS (With Fargate)'],
    'backend-server': ['EC2', 'EKS', 'Elastic Beanstalk', 'ECS (With EC2)'],
    'backend-serverless': ['Lamda', 'ECS (With Fargate)'],
    database: ['MySQL', 'SQL', 'MSSQL', 'PostgreSQL', 'Mongo DB', 'Others'],
    'database-server': ['EC2', 'EKS', 'ECS', 'RCS', 'Document DB'],
    'is-server-configured': ['Yes', 'No']
  },
  AZURE: {
    'frontend-server': ['VM Instance', 'GKE'],
    'frontend-serverless': ['App Engine', 'Cloud Run'],
    'backend-server': ['VM Instance', 'GKE'],
    'backend-serverless': ['App Engine', 'Cloud Run'],
    database: ['MySQL', 'SQL', 'MSSQL', 'PostgreSQL', 'Mongo DB', 'Others'],
    'database-server': ['VM', 'Compute Engine', 'SQL'],
    'is-server-configured': ['Yes', 'No']
  },
  GCP: {
    'frontend-server': ['VM Instance', 'GKE'],
    'frontend-serverless': ['App Engine', 'Cloud Run'],
    'backend-server': ['VM Instance', 'GKE'],
    'backend-serverless': ['App Engine', 'Cloud Run'],
    database: ['MySQL', 'SQL', 'MSSQL', 'PostgreSQL', 'Mongo DB', 'Others'],
    'database-server': ['VM', 'Compute Engine', 'SQL'],
    'is-server-configured': ['Yes', 'No']
  }
};
