import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Svg, Line } from '@react-pdf/renderer';
import rightImage from '../../assets/images/PdfImage1.png';
import topImage from '../../assets/images/PdfImage2.png';
import leftImage from '../../assets/images/PdfImage3.png';
import convertPToText from '../../utils/convertHtmlToReactPdfElements';
// import DisplayObject from '../../utils/displayInstance';
import RiskImage from '../../utils/RiskImage';

// Font.register({
//   family: "Manrope",
//   fonts: [
//     {
//       src: `../assets/Manrope-ExtraLight.ttf`,
//       fontWeight: 200,
//     },
//     {
//       src: `../assets/Manrope-Light.ttf`,
//       fontWeight: 300,
//     },
//     {
//       src: `../assets/Manrope-Regular.ttf`,
//       fontWeight: 400,
//     },
//     {
//       src: `../assets/Manrope-Medium.ttf`,
//       fontWeight: 500,
//     },
//     {
//       src: `../assets/Manrope-SemiBold.ttf`,
//       fontWeight: 600,
//     },
//     {
//       src: `../assets/Manrope-Bold.ttf`,
//       fontWeight: 700,
//     },
//     {
//       src: `../assets/Manrope-ExtraBold.ttf`,
//       fontWeight: 800,
//     },
//   ],
// });

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#ffffff',
    padding: 30,
    display: 'flex',
    justifyContent: 'center',
    color: '#333333'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '90%',
    marginTop: 80,
    marginLeft: 20
  },
  header: {
    fontSize: 16,
    color: 'grey',
    textAlign: 'left',
    marginTop: 200
  },
  title: {
    fontSize: 36
  },
  report: {
    fontSize: 36,
    color: '#9747FF'
  },
  subtitle: {
    fontSize: 24
  },
  preparedBy: {
    fontSize: 16,
    color: 'grey',
    marginTop: 50
  },

  footer: {
    fontSize: 16,
    marginTop: 350
  },
  overviewPageContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%'
  },
  overviewHeader: {
    fontSize: 18,
    textAlign: 'right',
    marginBottom: 10
  },
  overviewTitle: {
    marginTop: 30,
    marginLeft: 20,
    fontSize: 30
  },
  overviewList: {
    marginTop: 30,
    marginLeft: 30,
    fontSize: 16,
    lineHeight: 1.5,
    width: '350px'
  },
  overviewListItem: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  overviewFooterImage: {
    width: 50,
    height: 50,
    alignSelf: 'flex-end'
  },
  footerText: {
    fontSize: 12
  },
  split: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%'
  },
  rightImage: {
    width: '600px',
    height: '600px',
    marginLeft: -150,
    marginBottom: -150
  },
  rightContainer: {
    marginBottom: 600,
    marginTop: -30,
    marginLeft: -150,
    height: '100%'
  },
  overviewContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%'
  },
  topImage: {
    top: 0,
    position: 'fixed',
    height: '50px',
    width: '900px',
    marginLeft: -30,
    marginTop: -30
  },
  leftImage: {
    left: 0,
    width: '100%',
    marginLeft: -100
  },
  bottomImage: {
    width: '900px',
    height: '50px',
    marginLeft: -30,
    marginBottom: -30
  },
  page3: {
    backgroundColor: '#ffffff',
    padding: 30,
    display: 'flex',
    color: '#333333'
  }
});

const SecurityResultPdf = ({ data }) => {
  //   console.log(data);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.split}>
          <View style={styles.container}>
            <View>
              <Text style={styles.header} fontWeight={'bold'}>
                July 22, 2024
              </Text>
              <Text style={styles.title}>Vulnerability Scan</Text>
              <Text style={styles.report}>Report</Text>
            </View>
            <View>
              <Text style={styles.footer}>hostedscan.com</Text>
            </View>
          </View>
          <View style={styles.rightContainer}>
            <Image style={styles.rightImage} src={rightImage} />
          </View>
        </View>
      </Page>

      {/* Overview Page */}
      {/* <Page size="A4" style={styles.page}>
        <View style={styles.overviewPageContainer}>
          <View style={styles.overviewContainer}>
            <View>
              <Image style={styles.topImage} src={topImage} />
              <View style={styles.split}>
                <Image style={styles.leftImage} src={leftImage} />
                <View style={{ display: 'flex', flexDirection: 'column' }}>
                  <Text style={styles.overviewTitle}>Overview</Text>
                  <Svg height="1" width="800" style={{ marginTop: 10 }}>
                    <Line
                      x1="0"
                      y1="0"
                      x2="1000"
                      y2="0"
                      strokeWidth={2}
                      stroke="#9747FF"
                      opacity="0.2"
                    />
                  </Svg>
                  <View style={styles.overviewList}>
                    {[
                      { title: 'Executive Summary', page: 3 },
                      { title: 'Vulnerabilities By Target', page: 4 },
                      {
                        title: 'Active Web Application Vulnerabilities',
                        page: 7
                      },
                      {
                        title: 'Passive Web Application Vulnerabilities',
                        page: 17
                      },
                      { title: 'SSL/TLS Security', page: 26 },
                      { title: 'Network Vulnerabilities', page: 27 },
                      { title: 'Open TCP Ports', page: 28 },
                      { title: 'Open UDP Ports', page: 31 },
                      { title: 'Glossary', page: 32 }
                    ].map((item, index) => (
                      <View key={index} style={styles.overviewListItem}>
                        <Text>
                          {index + 1}. {item.title}
                        </Text>
                        <Text style={{ color: '#9747FF' }}>{item.page}</Text>
                      </View>
                    ))}
                  </View>
                </View>
              </View>
              <Image style={styles.bottomImage} src={topImage} />
            </View>
          </View>
        </View>
      </Page> */}

      {data?.map((alert, idx) => (
        <Page size="A4" style={styles.page3} key={idx}>
          {idx === 0 && (
            <>
              <View style={styles.section_details}>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <Text
                    style={{
                      color: '#95a2b8',
                      marginRight: '10',
                      fontSize: '20px'
                    }}>
                    3.3
                  </Text>
                  <Text style={{ fontSize: '20px' }}>Vulnerability Details</Text>
                </View>
              </View>

              <View>
                <Text style={{ marginLeft: '30', marginTop: '4', fontSize: '12px' }}>
                  Detailed information about each potential vulnerability found by the scan.
                </Text>
              </View>

              <Svg height="1" width="800" style={{ marginTop: 30 }}>
                <Line
                  x1="0"
                  y1="0"
                  x2="520"
                  y2="0"
                  strokeWidth={2}
                  stroke="#95a2b8"
                  opacity="0.2"
                />
              </Svg>
            </>
          )}

          <View style={{ marginTop: 30 }}>
            <RiskImage
              risk={
                alert.risk === 'Low'
                  ? 1
                  : alert.risk === 'Medium'
                    ? 2
                    : alert.risk === 'High'
                      ? 3
                      : alert.risk === 'Extreme'
                        ? 4
                        : 0
              }
            />
            <Text style={{ marginTop: -246, marginLeft: 50, fontSize: '22px' }}>{alert.name}</Text>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginLeft: 50,
                gap: 30,
                marginTop: 15
              }}>
              <View>
                <Text style={{ fontSize: 10, color: '#95a2b8' }}>SEVERITY</Text>
                <Text style={{ fontSize: 12 }}>{alert.risk}</Text>
              </View>
              {/* <View>
                <Text style={{ fontSize: 10, color: '#95a2b8' }}>AFFECTED TARGETS</Text>
                <Text style={{ fontSize: 12 }}>1 target</Text>
              </View>
              <View>
                <Text style={{ fontSize: 10, color: '#95a2b8' }}>LAST DETECTED</Text>
                <Text style={{ fontSize: 12 }}>17 days ago</Text>
              </View> */}
            </View>
          </View>

          <Svg height="1" width="800" style={{ marginTop: 30 }}>
            <Line x1="0" y1="0" x2="520" y2="0" strokeWidth={2} stroke="#95a2b8" opacity="0.2" />
          </Svg>

          <View style={{ marginTop: 30, gap: 20 }}>
            <View>
              <Text style={{ fontSize: 14 }}>Description: </Text>
              {convertPToText(alert.description, false)}
            </View>

            {/* <View>
              <Text style={{ fontSize: 14 }}>Instances (1 of 3) : </Text>
              <DisplayObject data={alert.instances ? alert?.instances[0] : {}} />
            </View> */}

            <View>
              <Text style={{ fontSize: 14 }}>References : </Text>
              {convertPToText(alert.reference, true)}
            </View>
          </View>
        </Page>
      ))}
    </Document>
  );
};

export default SecurityResultPdf;
