export default function EnterpriseUserIcon() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32 20V10.6667C32 10.313 31.8595 9.97391 31.6095 9.72386C31.3594 9.47381 31.0203 9.33333 30.6667 9.33333H22.6667V1.33333C22.6667 0.979711 22.5262 0.640573 22.2761 0.390525C22.0261 0.140476 21.687 0 21.3333 0H1.33333C0.979711 0 0.640573 0.140476 0.390525 0.390525C0.140476 0.640573 0 0.979711 0 1.33333V12V21.3333C0 21.687 0.140476 22.0261 0.390525 22.2761C0.640573 22.5262 0.979711 22.6667 1.33333 22.6667H9.33333V30.6667C9.33333 31.0203 9.47381 31.3594 9.72386 31.6095C9.97391 31.8595 10.313 32 10.6667 32H30.6667C31.0203 32 31.3594 31.8595 31.6095 31.6095C31.8595 31.3594 32 31.0203 32 30.6667V20ZM20.5517 29.3333L2.66667 11.4483V4.55167L27.4483 29.3333H20.5517ZM4.55167 2.66667H11.4483L29.3333 20.5517V27.4483L4.55167 2.66667ZM29.3333 16.7817L24.5517 12H29.3333V16.7817ZM20 7.44833L15.2183 2.66667H20V7.44833ZM2.66667 15.2183L7.44833 20H2.66667V15.2183ZM12 24.5517L16.7817 29.3333H12V24.5517Z"
        fill="white"
      />
    </svg>
  );
}
