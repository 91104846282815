import React, { useState } from 'react';
import { Card, CardContent } from '../../components/ui/card';
import { Button } from '../../components/ui/button';
import { Input } from '../../components/ui/input';
import { architectureSuggestions } from '../../utils/util';
import withUser from '../../redux/Hoc/withUser';
import withLoader from '../../redux/Hoc/withLoader';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '../ui/dropdown-menu';
import { DropDownIcon } from '../../../src/assets/icons';
import { useToast } from '../../components/ui/use-toast';
import { useNavigate } from 'react-router-dom';

const Architecture = ({ user, setLoader }) => {
  const navigate=useNavigate()
  const { toast } = useToast();
  const [selectedClouds, setSelectedClouds] = useState([]);
  const [architectureName, setArchitectureName] = useState('');
  const [infrastructureType, setInfrastructureType] = useState('');
  const [unit, setUnit] = useState('Select Option');
  const [unit1, setUnit1] = useState('Select Option');
  const [architectureDomain, setArchitectureDomain] = useState('Select Domain');
  const [noOfUsersDropdownOpen, setNoOfUsersDropdownOpen] = useState(false);
  const [noOfConcurrentUsersDropdownOpen, setNoOfConcurrentUsersDropdownOpen] = useState(false);
  const [noOfConcurrentUsers, setNoOfConcurrentUsers] = useState('');
  const [noOfUsers, setNoOfUsers] = useState('');
  const isSelected = (cloud) => selectedClouds.includes(cloud);
  const [isCloudsValid, setIsCloudsValid] = useState(true);
  const [isArchitectureNameValid, setIsArchitectureNameValid] = useState(true);
  const [isInfrastructureTypeValid, setIsInfrastructureTypeValid] = useState(true);
  const [isUnitValid, setIsUnitValid] = useState(true);
  const [isNoOfUsersValid, setIsNoOfUsersValid] = useState(true);
  const [isUnit1Valid, setIsUnit1Valid] = useState(true);
  const [isNoOfConcurrentUsersValid, setIsNoOfConcurrentUsersValid] = useState(true);
  const [isArchitectureDomainValid, setIsArchitectureDomainValid] = useState(true);
  const [isArchitectureDomainDropdownOpen, setIsArchitectureDomainDropdownOpen] = useState(false);
  const handleCloudSelection = (cloud) => {
    setSelectedClouds((prev) =>
      prev.includes(cloud) ? prev.filter((item) => item !== cloud) : [...prev, cloud]
    );
  };

  const handleUsersDropdown = () => {
    setNoOfUsersDropdownOpen(!noOfUsersDropdownOpen);
  };

  const handleArchitectureDomainDropdown = () => {
    setIsArchitectureDomainDropdownOpen(!isArchitectureDomainDropdownOpen);
  };

  const handleConcurrentUsersDropdown = () => {
    setNoOfConcurrentUsersDropdownOpen(!noOfConcurrentUsersDropdownOpen);
  };

  const handleInfrastructureSelection = (type) => {
    setInfrastructureType(type);
    setIsInfrastructureTypeValid(true);
  };

  const handleSubmit = async () => {
    setLoader(true);
    const isValid = {
      clouds: selectedClouds.length > 0,
      architectureDomain: architectureDomain !== 'Select Domain',
      architectureName: architectureName.trim().length > 0,
      infrastructureType: infrastructureType !== '',
      unit: unit !== 'Select Option',
      noOfUsers: noOfUsers.trim().length > 0,
      unit1: unit1 !== 'Select Option',
      noOfConcurrentUsers: noOfConcurrentUsers.trim().length > 0
    };
    setIsArchitectureDomainValid(isValid.architectureDomain);
    setIsCloudsValid(isValid.clouds);
    setIsArchitectureNameValid(isValid.architectureName);
    setIsInfrastructureTypeValid(isValid.infrastructureType);
    setIsUnitValid(isValid.unit);
    setIsNoOfUsersValid(isValid.noOfUsers);
    setIsUnit1Valid(isValid.unit1);
    setIsNoOfConcurrentUsersValid(isValid.noOfConcurrentUsers);

    try {
      if (Object.values(isValid).every((value) => value)) {
        const payload = {
          architecture_name: architectureName,
          architecture_domain: architectureDomain,
          min_number_of_users: 0,
          max_number_of_users: noOfUsers,
          number_of_concurrent_request: isNoOfConcurrentUsersValid,
          architecture_type: infrastructureType,
          infrastructure_type: infrastructureType,
          cloud_type: selectedClouds,
          enterprise_id: user.enterprise_id ? user.enterprise_id : null,
          user_id: user.user_id,
          object_id: user.object_id,
          subscription_id: user.activated_subscription_id
        };
        console.log(payload);

        const response = await architectureSuggestions(payload);
        console.log(response);
        console.log('Submit');
        
        navigate(`/architecture`)

        if (response.data.code === 200) {
          toast({
            variant: 'success',
            title: 'Success',
            description: response.data.message
          });
          
        } else {
          toast({
            variant: 'destructive',
            title: 'Error',
            description: response.data.message || 'Something went wrong!'
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  return (
    <main className="grid flex-1 items-start gap-6">
      <div className="p-0 w-full manrope-fontCss hidden md:block">
        <Card className="w-full p-0 manrope-fontCss bg-[#11141F]">
          <CardContent>
            {/* Architecture Name */}
            <div className="grid grid-col-1 mb-4 mt-4 manrope-fontCss">
              <h3 className="mb-2 text-base font-semibold">Architecture Name</h3>
              <Input
                type="text"
                className="h-9 p-2 mb-2 bg-[#161A27] text-white border-[#747474] border-2"
                placeholder="Write test name like “Architecture 1”, etc"
                onChange={(e) => {
                  setArchitectureName(e.target.value),
                    setIsArchitectureNameValid(e.target.value.trim() !== '');
                }}
              />
              {!isArchitectureNameValid && <span className="text-red-500 text-xs">Required</span>}
            </div>

            {/* Cloud Type */}
            <div>
              <h1 className="w-full mb-2 text-base font-bold manrope-fontCss">Cloud Type</h1>
              <div className="grid grid-cols-3 items-center gap-x-10 gap-y-2 mb-4 manrope-fontCss border-[#747474]">
                {['AWS', 'Azure', 'GCP'].map((cloud) => (
                  <div key={cloud} className="relative">
                    <Button
                      className={`col-span-1 h-9 w-full bg-[#161A27] text-white text-sm font-semibold manrope-fontCss hover:bg-[#020817] transition-colors border-[#747474] border-2 ${
                        isSelected(cloud) ? 'bg-[#9747FF] hover:bg-[#9747FF]' : ''
                      }`}
                      onClick={() => handleCloudSelection(cloud)}>
                      {cloud}
                    </Button>
                  </div>
                ))}
                {!isCloudsValid && selectedClouds.length === 0 && (
                  <span className=" text-red-500 text-xs mt-0">Select at least one cloud</span>
                )}
              </div>
            </div>

            <div className="flex flex-row space-x-10 mb-4">
              {/* Infrastructure Type */}
              <div className="w-[68%]">
                <h3 className="mb-1 text-base font-semibold">Infrastructure Type</h3>
                <div className="grid grid-cols-2 gap-10 items-start">
                  <div className="flex flex-col col-span-2">
                    <div className="grid grid-cols-2 gap-5 items-center">
                      <Button
                        className={`px-3 py-4 border-[#747474] border-2 h-9 col-span-1 ${
                          isInfrastructureTypeValid && infrastructureType === 'Server'
                            ? 'bg-[#9747FF] text-white'
                            : 'bg-[#161A27] text-white'
                        }`}
                        onClick={() => handleInfrastructureSelection('Server')}>
                        Server
                      </Button>
                      <Button
                        className={`px-3 py-4 border-[#747474] border-2 h-9 col-span-1 ${
                          isInfrastructureTypeValid && infrastructureType === 'Serverless'
                            ? 'bg-[#9747FF] text-white'
                            : 'bg-[#161A27] text-white'
                        }`}
                        onClick={() => handleInfrastructureSelection('Serverless')}>
                        Serverless
                      </Button>
                    </div>
                    {!isInfrastructureTypeValid && (
                      <span className="text-red-500 text-xs mt-2">Required</span>
                    )}
                  </div>
                </div>
              </div>

              {/* Architecture Domain or line of Business*/}
              <div className="w-[32%]">
                <h3 className="mb-1 text-base font-semibold">Line of Business</h3>
                <div className="grid grid-col-1">
                  <DropdownMenu className="mt-2" onOpenChange={handleArchitectureDomainDropdown}>
                    <DropdownMenuTrigger className="h-9 manrope-fontCss bg-[#161A27] text-white text-start rounded-md border-[#747474] border-2">
                      <div className="px-[13px] flex items-center justify-between">
                        {architectureDomain}{' '}
                        <div
                          className={`transition-transform ${isArchitectureDomainDropdownOpen ? 'rotate-180' : ''}`}>
                          <DropDownIcon />
                        </div>
                      </div>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="w-[var(--radix-dropdown-menu-trigger-width)] bg-[#161A27] mt-3">
                      <DropdownMenuItem
                        onClick={() => setArchitectureDomain('Ecommerce')}
                        className={architectureDomain === 'Ecommerce' ? 'bg-accent ' : ''}>
                        <div className="w-full text-center text-[16px] font-semibold">{`E commerce`}</div>
                      </DropdownMenuItem>
                      <DropdownMenuSeparator className="" />
                      <DropdownMenuItem
                        onClick={() => setArchitectureDomain('Retail')}
                        className={architectureDomain === 'Retail' ? 'bg-accent ' : ''}>
                        <div className="w-full text-center text-[16px] font-semibold">{`Retail`}</div>
                      </DropdownMenuItem>
                      <DropdownMenuSeparator className="" />
                      <DropdownMenuItem
                        onClick={() => setArchitectureDomain('Supply chain')}
                        className={architectureDomain === 'Supply chain' ? 'bg-accent ' : ''}>
                        <div className="w-full text-center text-[16px] font-semibold">{`Supply chain`}</div>
                      </DropdownMenuItem>
                      <DropdownMenuSeparator className="" />
                      <DropdownMenuItem
                        onClick={() => setArchitectureDomain('Healthcare')}
                        className={architectureDomain === 'Healthcare' ? 'bg-accent ' : ''}>
                        <div className="w-full text-center text-[16px] font-semibold">{`Healthcare`}</div>
                      </DropdownMenuItem>
                      <DropdownMenuSeparator className="" />
                      <DropdownMenuItem
                        onClick={() => setArchitectureDomain('Technology')}
                        className={architectureDomain === 'Technology' ? 'bg-accent ' : ''}>
                        <div className="w-full text-center text-[16px] font-semibold">{`Technology `}</div>
                      </DropdownMenuItem>
                      <DropdownMenuSeparator className="" />
                      <DropdownMenuItem
                        onClick={() => setArchitectureDomain('Telecom')}
                        className={architectureDomain === 'Telecom' ? 'bg-accent ' : ''}>
                        <div className="w-full text-center text-[16px] font-semibold">{`Telecom `}</div>
                      </DropdownMenuItem>
                      <DropdownMenuSeparator className="" />
                      <DropdownMenuItem
                        onClick={() => setArchitectureDomain('Web application development')}
                        className={
                          architectureDomain === 'Web application development' ? 'bg-accent ' : ''
                        }>
                        <div className="w-full text-center text-[16px] font-semibold">{`Web application development`}</div>
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                  {!isArchitectureDomainValid && architectureDomain === 'Select Domain' && (
                    <span className="text-red-500 text-xs col-span-1 mt-2">Select one option</span>
                  )}
                </div>
              </div>
            </div>

            {/* No of Users */}
            <div className="mb-4 manrope-fontCss">
              <h3 className="mb-2 text-base font-semibold manrope-fontCss">Number of users</h3>
              <div className="grid grid-cols-3 gap-10">
                <div className="flex flex-col col-span-2">
                  <Input
                    type="number"
                    className="h-9 bg-[#161A27] text-white col-span-2 border-[#747474] border-2"
                    placeholder="Users"
                    onChange={(e) => {
                      setNoOfUsers(e.target.value),
                        setIsNoOfUsersValid(e.target.value.trim() !== '');
                    }}
                  />
                  {!isNoOfUsersValid && (
                    <span className="text-red-500 text-xs col-span-2 mt-2">Required</span>
                  )}
                </div>
                <div className="flex flex-col col-span-1">
                  <DropdownMenu className="" onOpenChange={handleUsersDropdown}>
                    <DropdownMenuTrigger className="h-9 manrope-fontCss bg-[#161A27] text-white text-start rounded-md border-[#747474] border-2">
                      <div className="px-[13px] flex items-center justify-between">
                        {unit}{' '}
                        <div
                          className={`transition-transform ${noOfUsersDropdownOpen ? 'rotate-180' : ''}`}>
                          <DropDownIcon />
                        </div>
                      </div>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="w-[var(--radix-dropdown-menu-trigger-width)] bg-[#161A27] mt-3">
                      <DropdownMenuItem
                        onClick={() => setUnit('1000')}
                        className={unit === 'Thousand' ? 'bg-accent ' : ''}>
                        <div className="w-full text-center text-[16px] font-semibold">{`Thousand(<1,00,000)`}</div>
                      </DropdownMenuItem>
                      <DropdownMenuSeparator className="" />
                      <DropdownMenuItem
                        onClick={() => setUnit('1,000,000')}
                        className={unit === 'Million' ? 'bg-accent ' : ''}>
                        <div className="w-full text-center text-[16px] font-semibold">{`Million(<1,00,00,000)`}</div>
                      </DropdownMenuItem>
                      <DropdownMenuSeparator className="" />
                      <DropdownMenuItem
                        onClick={() => setUnit('1,000,000,000')}
                        className={unit === 'Billion' ? 'bg-accent ' : ''}>
                        <div className="w-full text-center text-[16px] font-semibold">Billion</div>
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                  {!isUnitValid && unit === 'Select Option' && (
                    <span className="text-red-500 text-xs col-span-1 mt-2">Select one option</span>
                  )}
                </div>
              </div>
            </div>

            {/* No of Concurrent Users */}
            <div className="mb-4 mt-4">
              <h3 className="mb-1 text-base font-semibold">Number of concurrent users</h3>
              <div className="grid grid-cols-3 gap-10">
                <div className="flex flex-col col-span-2">
                  <Input
                    type="number"
                    className="h-9 bg-[#161A27] mb-2 text-white col-span-2 border-[#747474] border-2"
                    placeholder="Concurrent Users"
                    onChange={(e) => {
                      setNoOfConcurrentUsers(e.target.value),
                        setIsNoOfConcurrentUsersValid(e.target.value.trim() !== '');
                    }}
                  />
                  {!isNoOfConcurrentUsersValid && (
                    <span className="text-red-500 text-xs col-span-2">Required</span>
                  )}
                </div>
                <div className="flex flex-col col-span-1 gap-y-2">
                  <DropdownMenu
                    className="col-span-1 mb-2"
                    onOpenChange={handleConcurrentUsersDropdown}>
                    <DropdownMenuTrigger className="h-9 bg-[#161A27] text-white text-start rounded-md border-[#747474] border-2">
                      <div className="px-[13px] flex items-center justify-between">
                        {unit1}{' '}
                        <div
                          className={`transition-transform ${noOfConcurrentUsersDropdownOpen ? 'rotate-180' : ''}`}>
                          <DropDownIcon />
                        </div>
                      </div>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="w-[var(--radix-dropdown-menu-trigger-width)] bg-[#161A27] mt-3">
                      <DropdownMenuItem
                        onClick={() => setUnit1('Thousand')}
                        className={unit1 === 'Thousand' ? 'bg-accent ' : ''}>
                        <div className="w-full text-center text-[16px] font-semibold">{`Thousand(<1,00,000)`}</div>
                      </DropdownMenuItem>
                      <DropdownMenuSeparator className="" />
                      <DropdownMenuItem
                        onClick={() => setUnit1('Million')}
                        className={unit1 === 'Million' ? 'bg-accent ' : ''}>
                        <div className="w-full text-center text-[16px] font-semibold">{`Million(<1,00,00,000)`}</div>
                      </DropdownMenuItem>
                      <DropdownMenuSeparator className="" />
                      <DropdownMenuItem
                        onClick={() => setUnit1('Billion')}
                        className={unit1 === 'Billion' ? 'bg-accent ' : ''}>
                        <div className="w-full text-center text-[16px] font-semibold">Billion</div>
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                  {!isUnit1Valid && unit1 === 'Select Option' && (
                    <span className="text-red-500 text-xs col-span-1">Select one option</span>
                  )}
                </div>
              </div>
            </div>

            {/* Buttons */}
            <div className="flex justify-end">
              <div className="flex justify-end mt-0 gap-4 ">
                <div>
                  <Button
                    variant="ghost"
                    className="text-white w-[234px] text-base h-9 bg-[#444444]">
                    Discard Changes
                  </Button>
                </div>
                <div className="flex w-full items-end">
                  <Button
                    className={`text-white w-[234px] text-base h-9`}
                    onClick={() => {
                      console.log('done');
                      handleSubmit();
                    }}>
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </main>
  );
};

export default withLoader(withUser(Architecture));
