export default function CollapseIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.0909 0H2.90909C2.13755 0 1.39761 0.306493 0.852053 0.852053C0.306493 1.39761 0 2.13755 0 2.90909V29.0909C0 29.8624 0.306493 30.6024 0.852053 31.1479C1.39761 31.6935 2.13755 32 2.90909 32H29.0909C29.8624 32 30.6024 31.6935 31.1479 31.1479C31.6935 30.6024 32 29.8624 32 29.0909V2.90909C32 2.13755 31.6935 1.39761 31.1479 0.852053C30.6024 0.306493 29.8624 0 29.0909 0ZM2.90909 2.90909H8.5V29.0909H2.90909V2.90909ZM29.0909 29.0909H12V2.90909H29.0909V29.0909Z"
        fill="white"
      />
    </svg>
  );
}
