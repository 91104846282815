export default function DropdownIcon() {
  return (
    <svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.8535 0.853933L5.85373 5.85337C5.8073 5.89986 5.75216 5.93673 5.69146 5.96189C5.63076 5.98705 5.5657 6 5.5 6C5.4343 6 5.36924 5.98705 5.30854 5.96189C5.24784 5.93673 5.1927 5.89986 5.14627 5.85337L0.146521 0.853933C0.052705 0.760123 0 0.632889 0 0.500222C0 0.367555 0.052705 0.240321 0.146521 0.146511C0.240336 0.0527015 0.367577 0 0.500253 0C0.632928 0 0.760169 0.0527015 0.853985 0.146511L5.5 4.79287L10.146 0.146511C10.1925 0.100061 10.2476 0.0632154 10.3083 0.0380769C10.369 0.0129384 10.4341 0 10.4997 0C10.5654 0 10.6305 0.0129384 10.6912 0.0380769C10.7519 0.0632154 10.807 0.100061 10.8535 0.146511C10.8999 0.192961 10.9368 0.248105 10.9619 0.308795C10.9871 0.369485 11 0.434532 11 0.500222C11 0.565912 10.9871 0.630959 10.9619 0.691649C10.9368 0.752339 10.8999 0.807483 10.8535 0.853933Z"
        fill="white"
      />
    </svg>
  );
}
