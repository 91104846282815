import { Card, CardDescription, CardHeader, CardTitle } from '../../components/ui/card';
import { Button } from '../../components/ui/button';
import { useNavigate } from 'react-router-dom';
import RightArrowIcon from '../../assets/icons/right-arrow-icon';
// import { CardContent } from '@mui/material';
0;
const ButtonCard = ({ title, buttonName, url, className, containerClassName }) => {
  const navigate = useNavigate();

  const handleQuickStartClick = () => {
    navigate(url);
  };

  return (
    <div className={`md:w-[250px] w-[220px] manrope-fontCss h-full mt-2 ${containerClassName}`}>
      <Card x-chunk="dashboard-05-chunk-2" className={`h-[85%] ${className}`}>
        <CardHeader className=" flex p-4 px-6 pb-0">
          <CardTitle className="font-semibold h-full flex items-start gap-2 text-xl">
            {title}
          </CardTitle>
        </CardHeader>
        <CardDescription>
          <div className="flex flex-col items-start mt-4">
            <Button
              className="w-full space-x-2 flex justify-between mt-3 "
              onClick={handleQuickStartClick}>
              <p className="text-white font-bold text-base">{buttonName}</p>
              <div className="bg-white h-7 w-7 flex items-center p-0.5 justify-center border-none rounded-full">
                <RightArrowIcon />
              </div>
            </Button>
          </div>
        </CardDescription>
      </Card>
    </div>
  );
};

export default ButtonCard;
