import React, { useState, useEffect } from 'react';
import { useToast } from '../../components/ui/use-toast';
import withUser from '../../redux/Hoc/withUser';
import withLoader from '../../redux/Hoc/withLoader';
// import DashTable from '../../components/Admin_UserManagement/DashTable';
// import { deleteIcon, download } from '../../assets/icons2/index';
import { Button } from '../../components/ui/button';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import { getLoadTestResults, loadTest, deleteLoadTest } from '../../utils/util'; // Import API functions
import NormalCard from '../../components/Cards/normal-card';
// import ButtonCard from '../../components/Cards/button-card';
import LoadButtonCard from '../../components/Cards/LoadButtonCard';
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from '../../components/ui/table';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuItem,
  DropdownMenuSeparator,
} from '../../components/ui/dropdown-menu';
import { EllipsisVertical } from 'lucide-react';

import { Badge } from '../../components/ui/badge';
const LoadIndex = ({ user, setLoader }) => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const [loadData, setLoadData] = useState({ load: [] });
  // const [selectedTest, setSelectedTest] = useState(null);
  const [loading, setLoading] = useState(true);

  // const tabsConfig = [{ value: 'load', label: 'Load' }];
  // const columns = [
  //   { id: 'test_name', header: 'Test Details' },
  //   { id: 'status', header: 'Status' },
  //   { id: 'virtual_users', header: 'Virtual Users' },
  //   { id: 'duration', header: 'Duration' },
  //   { id: 'startTime', header: 'Start Date' }
  // ];

  // const buttonOptions = [
  //   {
  //     icon: download,
  //     alt: 'Send Icon',
  //     text: <span className="text-base font-semibold">Download Certificate</span>,
  //     iconHeight: 4,
  //     iconWidth: 18,
  //     iconColor: 'text-[#FFFFFF]',
  //     name: 'Download',
  //     onClick: () => handleDownload(selectedTest)
  //   },
  //   {
  //     icon: deleteIcon,
  //     alt: '',
  //     text: <span className="text-base font-semibold">Delete</span>,
  //     iconHeight: 17,
  //     iconWidth: 17,
  //     iconColor: 'text-[#FFFFFF]',
  //     name: 'Delete',
  //     onClick: () => handleDelete(selectedTest)
  //   }
  // ];


  const [countOfLoadTest,setCountOfLoadTest]=useState( )

  useEffect(() => {
    fetchLoadTestResults();
    const interval = setInterval(() => {
        fetchLoadTestResults(false);
    }, 15 * 1000);

    return () => clearInterval(interval);
  }, []);

  const fetchLoadTestResults = async (isLoader) => {
    try {
      if (isLoader) { setLoader(true) };
      const response = await getLoadTestResults({
        user_id: user.user_id,
        enterprise_id: user.enterprise_id,
        object_id: user.object_id,
        Page_number: 1,
        Per_page: 20,
      });
      console.log('response', response);
      setLoader(false);
      setLoading(false)
      if (response.data?.data.length == 0) {
        setLoadData({
          load: []
        });
      }

      if (response.data?.data.length > 0) {
        setLoadData({
          load: response.data.data
        });
      }
    } catch (error) {
      setLoader(false);
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'Failed to fetch load test results'
      });
    }
  };

  const deleteLoad = async (item) => {
    console.log(item);
    try {
      const payload = {
        user_id: user.user_id,
        enterprise_id: user.enterprise_id ? user.enterprise_id : null,
        id: item.test_id,
        object_id: user.object_id
      };
      setLoader(true)
      const result = await deleteLoadTest(payload);
      const response = result.data
      setLoader(false)
      console.log(response);
      if (response.status === 'success' && response.code === 200) {
        toast({
          title: "Success",
          description: response.message,
          variant: "success",
        });
      } else if (response.status === 'error') {
        switch (response.code) {
          case 400:
            console.error(response.message);
            toast({
              title: "Error",
              description: response.message,
              variant: "error",
            });
            break;
          case 404:
            console.error(response.message);
            toast({
              title: "Error",
              description: response.message,
              variant: "error",
            });
            break;
          case 500:
            console.error(response.message);
            toast({
              title: "Error",
              description: "Internal server error. Please try again later.",
              variant: "error",
            });
            break;
          default:
            console.error("Unexpected error occurred.");
            toast({
              title: "Error",
              description: "An unexpected error occurred.",
              variant: "error",
            });
            break;
        }
      }

    } catch (error) {
      console.error("API call failed:", error);
      toast({
        title: "Error",
        description: "Failed to delete the load test. Please check your connection and try again.",
        variant: "error",
      });
    }

  }

  const handleStartTest = async (testData) => {
    try {
      setLoader(true);
      const response = await loadTest({
        user_id: user.user_id,
        client_id: user.client_id,
        ...testData
      });
      setLoader(false);
      // console.log("load test count",response?.data.count)
      if (response.data) {
        toast({
          title: 'Success',
          description: 'Load test started successfully'
        });
        fetchLoadTestResults();
      }
    } catch (error) {
      setLoader(false);
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'Failed to start load test'
      });
    }
  };

  const loadItemType = (type) => {
    switch (type) {
      case 'loadtest-nonrelationalDb':
        return 'Database';
      case 'loadtest-relationalDb':
        return 'Database';
      case 'loadtest-Website':
        return 'Website';
      case 'loadtest-soapapi':
        return 'SOAP';
      case 'loadtest-kafka':
        return 'Message Broker';
      case 'loadtest-rest-api':
        return 'Rest API';
      case 'loadtest-graphQL':
        return 'graphQL';
      default:
        return type
    }
  }

  // const handleTestSelect = async (test) => {
  //   console.log("check");

  //   try {
  //     setLoader(true);
  //     const response = await getLoadTestDetail({
  //       user_id: user.user_id,
  //       client_id: user.client_id,
  //       testName: test.testDetails
  //     });
  //     setLoader(false);
  //     if (response.data) {
  //       setSelectedTest(response.data);
  //     }
  //   } catch (error) {
  //     setLoader(false);
  //     toast({
  //       variant: 'destructive',
  //       title: 'Error',
  //       description: 'Failed to fetch test details'
  //     });
  //   }
  // };

  // const handleDownload = (test) => {
  //   // Implement download logic here
  //   console.log('Downloading certificate for', test);
  // };

  const handleNavigate = (id) => {
    navigate(`/load/test-results/${id}`);
  };

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case 'started':
        return 'border-[#9747FF] bg-[#9747FF] text-[#9747FF] bg-opacity-25';
      case 'success':
      case 'passed':
      case 'completed':
        return 'border-[#22C55E] bg-[#22C55E] text-[#22C55E] bg-opacity-25';
      case 'not started':
        return 'border-[#A9A9A9] bg-[#A9A9A9] text-[#A9A9A9] bg-opacity-25';
      case 'paused':
      case 'pending':
      case 'in progress':
        return 'border-[#FEB101] bg-[#FEB101] text-[#FEB101] bg-opacity-25';
      case 'failed':
        return 'border-[#EF4444] bg-[#EF4444] text-[#EF4444] bg-opacity-25';
      default:
        return 'border-[#A9A9A9] bg-[#A9A9A9] text-[#A9A9A9] bg-opacity-25';
    }
  };

  const getStatusColorForDot = (status) => {
    switch (status?.toLowerCase()) {
      case 'started':
        return 'bg-[#9747FF]';
      case 'success':
      case 'passed':
      case 'completed':
        return 'bg-[#22C55E]';
      case 'not started':
        return 'bg-[#A9A9A9]';
      case 'paused':
      case 'pending':
      case 'in progress':
        return 'bg-[#FEB101]';
      case 'failed':
        return 'bg-[#EF4444]';
      default:
        return 'bg-[#A9A9A9]';
    }
  };

  // const handleDelete = async (test) => {
  //   // Implement delete logic here
  //   console.log('Deleting test', test);
  //   // After successful deletion, refresh the list
  //   await fetchLoadTestResults();
  // };
  const url = '/load/test';
  const buttonName = 'Quick start';
  const buttonTitle = 'Start Testing';
  const cardData = [
    { title: 'Test', numberOfTest: countOfLoadTest },
    // { title: 'Virtual Users', numberOfTest: 32, denominator: 50 },
    // { title: 'Minutes Used', numberOfTest: 32, denominator: 50 }
  ];

  const renderTableRows = ({ load }) => {
    return load.map((item, index) => (
      <TableRow
        key={index}
        className="border-b border-[#333333] cursor-pointer"
        onClick={() => {
          if (item.status == "success") {
            handleNavigate(item.test_name)
          }
        }}
      // style={item.status != "success" ? { poin}}
      >
        <TableCell className="text-center">
          <div className="text-lg font-semibold text-white">{item.test_name}</div>
        </TableCell>
        <TableCell className="text-center">
          <Badge
            className={`${getStatusColor(item.status)} text-base font-semibold w-32 h-8 inline-flex items-center justify-center`}
          >
            <div className="flex flex-row justify-center items-center">
              <div
                className={`${getStatusColorForDot(item.status)} w-2 h-2 rounded-full mr-2`}
              />
              {item.status}
            </div>
          </Badge>
        </TableCell>
        <TableCell className="text-center">
          <div className="text-xs font-medium text-white">{item.virtual_users}</div>
        </TableCell>
        <TableCell className="text-center">
          <div className="text-xs font-medium text-white">{item.duration}</div>
        </TableCell>
        <TableCell className="text-center">
          <div className="text-xs font-medium text-white">{loadItemType(item.type)}</div>
        </TableCell>
        <TableCell className="text-center">
          <div className="flex flex-col items-center">
            <div className="text-xs text-white font-medium">
              {moment(item.created_at).format('MM/DD/YYYY')}
            </div>
            <div className="text-xs font-medium text-muted-foreground">
              {moment(item.created_at).format('hh:mm:ss A')}
            </div>
          </div>
        </TableCell>
        <TableCell className="text-center">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0">
                <EllipsisVertical className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem>
                <Button
                  variant="none"
                  className="flex items-center px-2 gap-4 h-6 text-sm"
                  onClick={() => {
                    if (item.status == "success") {
                      handleNavigate(item.test_name)
                    }
                  }}
                >
                  View Results
                </Button>
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem>
                <Button
                  variant="none"
                  className="flex items-center px-2 gap-4 h-6 text-sm"
                  onClick={() => deleteLoad(item)}
                >
                  Delete
                </Button>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </TableCell>
      </TableRow>
    ));
  };

  const renderTableContent = () => (
    <Table>
      <TableHeader className="bg-[#11141F] rounded-lg">
        <TableRow className="border-b border-[#333333]">
          <TableHead className="text-white text-base font-medium p-4 text-center">Test Name</TableHead>
          <TableHead className="text-white text-base font-medium p-4 text-center">Status</TableHead>
          <TableHead className="text-white text-base font-medium p-4 text-center">Virtual Users</TableHead>
          <TableHead className="text-white text-base font-medium p-4 text-center">Duration</TableHead>
          <TableHead className="text-white text-base font-medium p-4 text-center">Type</TableHead>
          <TableHead className="text-white text-base font-medium p-4 text-center">Created At</TableHead>
          <TableHead className="text-white text-xl font-medium text-muted-foreground p-4"></TableHead>
        </TableRow>
      </TableHeader>
      <TableBody className="bg-[#11141F]">
        {renderTableRows(loadData)}
      </TableBody>
    </Table>
  );


  return (
    <main className="grid flex-1 items-start gap-4 p-4 sm:py-0 md:gap-8 w-full">
      <div className="grid auto-rows-max items-start gap-4 md:gap-8 lg:col-span-2">
        <div className={`flex h-full w-full gap-4`}>
          {cardData.map((card, index) => (
            <NormalCard
              onStartTest={handleStartTest}
              key={index}
              title={card.title}
              numberOfTest={card.numberOfTest}
              denominator={card.denominator}
            />
          ))}
          <LoadButtonCard title={buttonTitle} buttonName={buttonName} url={url} />
        </div>
        {loading ? <div>Loading...</div> : renderTableContent()}
        {/* {renderTableContent()} */}
        {/* <DashTable
          tabsConfig={tabsConfig}
          columns={columns}
          data={loadData}
          buttonType={'Download'}
          buttonOptions={buttonOptions}
          onRowClick={handleTestSelect}
        /> */}
      </div>
    </main>
  );
};

export default withLoader(withUser(LoadIndex));
