import React, { useState } from 'react';
import { cn } from '../../lib/utils';
import { Button } from '../../components/ui/button';
import { Input } from '../../components/ui/input';
import { Label } from '../../components/ui/label';
import { Checkbox } from '../../components/ui/checkbox';
import { Form, Field } from 'react-final-form';
// import { PREREQUISITE_FORM_INFO } from '../../constants';
import { required } from '../../helpers/form-validations';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle
  // DialogTrigger
} from '../../components/ui/dialog';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '../../components/ui/select';
import { useSelector } from 'react-redux';
import { createEnvironment } from '../../../src/utils/util';
import { Switch } from '../../components/ui/switch';
import { useNavigate } from 'react-router-dom';

const CustomButton = ({ children, className, variant, ...props }) => {
  return (
    <Button
      type="button"
      variant={variant ?? 'secondary'}
      className={cn('text-white border-[0.96px] border-[#747474]', className)}
      {...props}>
      {children}
    </Button>
  );
};

const dropdownOptions = {
  'AWS': {
    'Server': ['EC2', 'EKS', 'Elastic Beanstalk', 'ECS (with EC2)'],
    'Serverless': ['S3 + Cloudfront', 'AWS Amplify', 'ECS', 'ECS (with Fargate)']
  },
  'GCP': {
    'Server': ['VM Instance', 'GKE'],
    'Serverless': ['App Engine', 'Cloud Run']
  },
  'Azure': {
    'Server': ['Azure Virtual VMs', 'AKS'],
    'Serverless': ['Azure Container Instances', 'Azure Static Webapps', 'Azure Container Apps']
  }
};

const PrerequisitePage = () => {
  const databaseOptions = ['MySQL', 'PostgreSQL', 'MongoDB', 'Oracle'];
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();

  // const [currentTab, setCurrentTab] = useState('');
  // const [currentTabBackend, setCurrentTabBackend] = useState('');
  const [isServerConfigured, setIsServerConfigured] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(true);
  const [selectedPreRequisite, setSelectedPreRequisite] = useState('');

  // const dropdownRef = React.useRef(null);

  // React.useEffect(() => {
  //   if (isServerConfigured) {
  //     dropdownRef.current.scrollIntoView({ behavior: 'smooth' });
  //   }
  // }, [isServerConfigured]);

  const onSubmit = async (values) => {
    const formattedValues = {
      'enterprise_id': user?.enterprise_id,
      'user_id': user?.user_id,
      'environment': 'development',
      'deployed_on': 'AWS',
      'name': values['environment-name'],
      'frontend': {
        'server_type': values['frontend-server'] ?? null,
        'cpu': values['frontend-server-cpu'] ?? null,
        'ram': values['frontend-server-ram'] ?? null,
        'serverless_option': values['frontend-serverless'] ?? null,
        'dockerized': values['frontend-server-dockerized'],
        'other_details': values['frontend-server-other'] ?? '',
      },
      'backend': {
        'server_type': values['backend-server'] ?? null,
        'cpu': values['backend-server-cpu'] ?? null,
        'ram': values['backend-server-ram'] ?? null,
        'serverless_option': values['backend-serverless'] ?? null,
        'dockerized': values['backend-server-dockerized'],
        'other_details': values['backend-server-other'] ?? '',
      },
      'database': {
        'type': values['database'],
        'server_type': values['db-type']
      },
      'web_server': {
        'is_configured': values['web-server-configured'],
        'server_type': values['server-configured']
      },
      'cloud_provider': 'AWS'
    }

    const response = await createEnvironment(formattedValues);
    if (response?.status === 200 ){
      navigate('/')
    }
  };

  const handlePreRequisiteSelection = (option) => {
    setSelectedPreRequisite(option);
  };

  const handleDialogConfirm = () => {
    setIsDialogOpen(false);
    // Here you would typically trigger the form to open
  };

  return (
    <main className="grid flex-1 items-start p-4 sm:py-0 h-full space-y-4 manrope-fontCss">
      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent className="sm:max-w-[960px] sm:h-[386px] rounded-tl-[5px]">
          <DialogHeader>
            <DialogTitle>Choose the type of Pre-Requisite</DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <p className="text-sm text-gray-500">Select from available options and get started</p>
            <div className="grid grid-cols-3 gap-4">
              {['Dev1', 'Test2', 'Dev3', 'Test4', 'Dev5', 'Create New'].map((option) => (
                <CustomButton
                  key={option}
                  variant={selectedPreRequisite === option ? 'default' : 'secondary'}
                  onClick={() => handlePreRequisiteSelection(option)}>
                  {option}
                </CustomButton>
              ))}
              <CustomButton>View More</CustomButton>
            </div>
            {selectedPreRequisite && <p className="text-sm">{selectedPreRequisite} selected</p>}
            <Button
              onClick={handleDialogConfirm}
              className="w-full bg-purple-600 hover:bg-purple-700">
              Choose & Start
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      <div className="overflow-y-auto h-[100%] hide-scrollbar">
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, form, values }) => (
            <form onSubmit={handleSubmit} className="my-4">
              <div className='my-4'>
              <Field
                    name="environment-name"
                    validate={required}
                    render={({ input, meta }) => (
                      <div>
                        <Input placeholder="Environment Name" {...input} />
                        {meta.touched && meta.error && (
                          <div className="text-[#EF4444] text-sm my-[2px]">{meta.error}</div>
                        )}
                      </div>
                    )}
                  />
              </div>
              <section className="space-y-4">
                <div className="flex flex-row items-center justify-center gap-2">
                  <h2 className="text-lg font-bold">Front-End</h2>
                  <hr className="flex-1 w-full border-[#1E4E9D] border-t-2 mt-[2px] border-dashed" />
                </div>
                <div className="grid lg:grid-cols-4 gap-5">
                  <div>
                    <Field
                      name="frontend-cloud-provider"
                      validate={required}
                      render={({ input, meta }) => (
                        <div>
                          <Select
                          onValueChange={(e) => {
                            form.change('frontend-server', undefined);
                            form.change('frontend-serverless', undefined);
                            input.onChange(e);
                          }}
                          defaultValue={input.value}>
                            <SelectTrigger className="w-full">
                              <SelectValue placeholder="Choose from here" />
                            </SelectTrigger>
                            <SelectContent>
                              {Object.keys(dropdownOptions).map((provider) => (
                                <SelectItem key={provider} value={provider}>
                                  {provider}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                          {meta.touched && meta.error && (
                            <div className="text-[#EF4444] text-sm my-[2px]">{meta.error}</div>
                          )}
                        </div>
                      )}
                    />
                  </div>
                </div>
                <div className="grid lg:grid-cols-4 gap-5">
                  <div>
                    <Field
                      name="frontend-is-serverless"
                      type='checkbox'
                      validate={required}
                      render={({ input, meta }) => (
                          <div className="flex items-center space-x-2">
                            <Switch id="frontend-is-serverless" checked={input.checked} onCheckedChange={input.onChange} {...input} />
                            <Label htmlFor="frontend-is-serverless">Is Serverless?</Label>
                          </div>
                        )}
                    />
                  </div>
                </div>
                <div className="grid lg:grid-cols-2 gap-8 w-1/2">
                  <div className={["w-full", values['frontend-is-serverless'] ? 'hidden': 'visible'].join(' ')}>
                    <h2 className="text-base font-semibold text-[#CFCFCF]">
                      Server
                      {/* <span className="text-[#747474] font-normal text-sm ml-1">
                        (Choose an option from here)
                      </span> */}
                    </h2>
                    <Field
                      name="frontend-server"
                      validate={(value, allValues) => allValues['frontend-serverless'] ? undefined : required(value)}
                      render={({ input, meta }) => (
                        <div>
                          <Select onValueChange={input.onChange} defaultValue={input.value}>
                            <SelectTrigger className="w-full">
                              <SelectValue placeholder="Choose server from here" />
                            </SelectTrigger>
                            <SelectContent>
                              {
                              dropdownOptions[values['frontend-cloud-provider']]?.['Server'].map((option) => (
                                <SelectItem key={option} value={option}>
                                  {option}
                                </SelectItem>
                              ))
                            }
                            </SelectContent>
                          </Select>
                          {meta.touched && meta.error && (
                            <div className="text-[#EF4444] text-sm my-[2px]">{meta.error}</div>
                          )}
                        </div>
                      )}
                    />
                  </div>
                  <div className={["w-full", values['frontend-is-serverless'] ? 'visible': 'hidden'].join(' ')}>
                    <h2 className="text-base font-semibold text-[#CFCFCF]">
                      Serverless
                      {/* <span className="text-[#747474] font-normal text-sm ml-1">
                        (Choose an option from here)
                      </span> */}
                    </h2>
                    <Field
                      name="frontend-serverless"
                      validate={(value, allValues) => allValues['frontend-server'] ? undefined : required(value)}
                      render={({ input, meta }) => (
                        <div>
                          <Select onValueChange={input.onChange} defaultValue={input.value}>
                            <SelectTrigger className="w-full">
                              <SelectValue placeholder="Choose serverless from here" />
                            </SelectTrigger>
                            <SelectContent>
                              {dropdownOptions[values['frontend-cloud-provider']]?.['Serverless'].map((option) => (
                                <SelectItem key={option} value={option}>
                                  {option}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                          {meta.touched && meta.error && (
                            <div className="text-[#EF4444] text-sm my-[2px]">{meta.error}</div>
                          )}
                        </div>
                      )}
                    />
                  </div>
                </div>
                <div className={["grid lg:grid-cols-2 gap-8 w-1/2", values['frontend-is-serverless'] ? 'hidden': 'visible' ].join(' ')}>
                  <Field
                    name="frontend-server-cpu"
                    validate={(value, allValues) => allValues['frontend-serverless'] ? undefined : required(value)}
                    render={({ input, meta }) => (
                      <div>
                        <Input placeholder="CPU - Enter here" {...input} />
                        {meta.touched && meta.error && (
                          <div className="text-[#EF4444] text-sm my-[2px]">{meta.error}</div>
                        )}
                      </div>
                    )}
                  />
                  <Field
                    name="frontend-server-ram"
                    subscription={{ value: true, error: true, touched: true }}
                    validate={(value, allValues) => allValues['frontend-serverless'] ? undefined : required(value)}
                    render={({ input, meta }) => (
                      <div>
                        <Input placeholder="RAM - Enter here" {...input} />
                        {meta.touched && meta.error && (
                          <div className="text-[#EF4444] text-sm my-[2px]">{meta.error}</div>
                        )}
                      </div>
                    )}
                  />
                </div>

                <div className="space-y-3">
                  <h2 className="text-base font-semibold text-[#CFCFCF]">Others</h2>
                  <div className="flex flex-row w-3/4">
                    <Field
                      name="frontend-server-other"
                      render={({ input, meta }) => (
                        <div className="w-full">
                          <Input placeholder="Enter your input here..." {...input} />
                          {meta.touched && meta.error && (
                            <div className="text-[#EF4444] text-sm my-[2px]">{meta.error}</div>
                          )}
                        </div>
                      )}
                    />
                  </div>
                </div>
                <Field
                  name="frontend-server-dockerised"
                  render={({ input, meta }) => (
                    <div className="w-full py-2">
                      <div className="flex flex-row items-center">
                        <Checkbox
                          id="is-dockerised-frontend"
                          checked={input.value}
                          onCheckedChange={input.onChange}
                        />
                        <Label
                          htmlFor="is-dockerised-frontend"
                          className="font-semibold text-sm ml-2">
                          Application Dockerised?
                        </Label>
                      </div>
                      {meta.touched && meta.error && (
                        <div className="text-[#EF4444] text-sm my-[2px]">{meta.error}</div>
                      )}
                    </div>
                  )}
                />
              </section>

              {/* Back-End Section */}
              <section className="space-y-4">
                <div className="flex flex-row items-center justify-center gap-2 my-4">
                  <h2 className="text-lg font-bold">Back-End</h2>
                  <hr className="flex-1 w-full border-[#1E4E9D] border-t-2 mt-[2px] border-dashed" />
                </div>
                <div className="grid lg:grid-cols-4 gap-5">
                  <div>
                    <Field
                      name="backend-cloud-provider"
                      validate={required}
                      render={({ input, meta }) => (
                        <div>
                          <Select onValueChange={(e) => {
                            form.change('backend-server', null);
                            form.change('backend-serverless', null);
                            input.onChange(e);
                          }} defaultValue={input.value}>
                            <SelectTrigger className="w-full">
                              <SelectValue placeholder="Choose from here" />
                            </SelectTrigger>
                            <SelectContent>
                              {Object.keys(dropdownOptions).map((provider) => (
                                <SelectItem key={provider} value={provider}>
                                  {provider}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                          {meta.touched && meta.error && (
                            <div className="text-[#EF4444] text-sm my-[2px]">{meta.error}</div>
                          )}
                        </div>
                      )}
                    />
                  </div>
                </div>
                <div className="grid lg:grid-cols-4 gap-5">
                  <div>
                    <Field
                      name="backend-is-serverless"
                      type='checkbox'
                      validate={required}
                      render={({ input, meta }) => (
                          <div className="flex items-center space-x-2">
                            <Switch id="backend-is-serverless" checked={input.checked} onCheckedChange={input.onChange} {...input} />
                            <Label htmlFor="backend-is-serverless">Is Serverless?</Label>
                          </div>
                        )}
                    />
                  </div>
                </div>
                <div className="grid lg:grid-cols-2 gap-8 w-1/2">
                  <div className={["w-full", values['backend-is-serverless'] ? 'hidden': 'visible'].join(' ')}>
                    <h2 className="text-base font-semibold text-[#CFCFCF]">
                      Server
                      {/* <span className="text-[#747474] font-normal text-sm ml-1">
                        (Choose an option from here)
                      </span> */}
                    </h2>
                    <Field
                      name="backend-server"
                      validate={(value, allValues) => allValues['backend-serverless'] ? undefined : required(value)}
                      render={({ input, meta }) => (
                        <div>
                          <Select onValueChange={input.onChange} defaultValue={input.value}>
                            <SelectTrigger className="w-full">
                              <SelectValue placeholder="Choose server from here" />
                            </SelectTrigger>
                            <SelectContent>
                              {dropdownOptions[values['backend-cloud-provider']]?.['Server'].map((option) => (
                                <SelectItem key={option} value={option}>
                                  {option}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                          {meta.touched && meta.error && (
                            <div className="text-[#EF4444] text-sm my-[2px]">{meta.error}</div>
                          )}
                        </div>
                      )}
                    />
                  </div>
                  <div className={["w-full", values['backend-is-serverless'] ? 'visible': 'hidden'].join(' ')}>
                    <h2 className="text-base font-semibold text-[#CFCFCF]">
                      Serverless
                      {/* <span className="text-[#747474] font-normal text-sm ml-1">
                        (Choose an option from here)
                      </span> */}
                    </h2>
                    <Field
                      name="backend-serverless"
                      validate={(value, allValues) => allValues['backend-server'] ? undefined : required(value)}
                      render={({ input, meta }) => (
                        <div>
                          <Select onValueChange={input.onChange} defaultValue={input.value}>
                            <SelectTrigger className="w-full">
                              <SelectValue placeholder="Choose serverless from here" />
                            </SelectTrigger>
                            <SelectContent>
                              {dropdownOptions[values['backend-cloud-provider']]?.['Serverless'].map((option) => (
                                <SelectItem key={option} value={option}>
                                  {option}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                          {meta.touched && meta.error && (
                            <div className="text-[#EF4444] text-sm my-[2px]">{meta.error}</div>
                          )}
                        </div>
                      )}
                    />
                  </div>
                </div>

                <div className={["grid lg:grid-cols-2 gap-8 w-1/2", values['backend-is-serverless'] ? 'hidden': 'visible'].join(' ')}>
                  <Field
                    name="backend-server-cpu"
                    validate={(value, allValues) => allValues['backend-serverless'] ? undefined : required(value)}
                    render={({ input, meta }) => (
                      <div>
                        <Input placeholder="CPU - Enter here" {...input} />
                        {meta.touched && meta.error && (
                          <div className="text-[#EF4444] text-sm my-[2px]">{meta.error}</div>
                        )}
                      </div>
                    )}
                  />
                  <Field
                    name="backend-server-ram"
                    validate={(value, allValues) => allValues['backend-serverless'] ? undefined : required(value)}
                    render={({ input, meta }) => (
                      <div>
                        <Input placeholder="RAM - Enter here" {...input} />
                        {meta.touched && meta.error && (
                          <div className="text-[#EF4444] text-sm my-[2px]">{meta.error}</div>
                        )}
                      </div>
                    )}
                  />
                </div>

                <div className="space-y-3 w-3/4">
                  <h2 className="text-base font-semibold text-[#CFCFCF]">Others</h2>
                  <div className="flex flex-row w-full">
                    <Field
                      name="backend-server-other"
                      render={({ input, meta }) => (
                        <div className="w-full">
                          <Input placeholder="Enter your input here..." {...input} />
                          {meta.touched && meta.error && (
                            <div className="text-[#EF4444] text-sm my-[2px]">{meta.error}</div>
                          )}
                        </div>
                      )}
                    />
                  </div>
                </div>
                <Field
                  name="backend-server-dockerised"
                  render={({ input, meta }) => (
                    <div className="w-full py-2">
                      <div className="flex flex-row items-center">
                        <Checkbox
                          id="is-dockerised-backend"
                          checked={input.value}
                          onCheckedChange={input.onChange}
                        />
                        <Label
                          htmlFor="is-dockerised-backend"
                          className="font-semibold text-base ml-2">
                          Application Dockerised?
                        </Label>
                      </div>
                      {meta.touched && meta.error && (
                        <div className="text-[#EF4444] text-sm my-[2px]">{meta.error}</div>
                      )}
                    </div>
                  )}
                />
              </section>

              {/* Database Section */}
              <section className="space-y-4">
                <div className="flex flex-row items-center justify-center gap-2 py-2">
                  <h2 className="text-lg font-bold">Database</h2>
                  <hr className="flex-1 w-full border-[#1E4E9D] border-t-2 mt-[2px] border-dashed" />
                </div>
                <div className="grid lg:grid-cols-4 gap-5">
                  <Field
                    name="database"
                    validate={required}
                    render={({ input, meta }) => (
                      <div>
                        <Select onValueChange={input.onChange} defaultValue={input.value}>
                          <SelectTrigger className="w-full">
                            <SelectValue placeholder="Choose from here" />
                          </SelectTrigger>
                          <SelectContent>
                            {databaseOptions.map((option) => (
                              <SelectItem key={option} value={option}>
                                {option}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                        {meta.touched && meta.error && (
                          <div className="text-[#EF4444] text-sm my-[2px]">{meta.error}</div>
                        )}
                      </div>
                    )}
                  />
                </div>

                <div className="flex flex-row w-3/4">
                  <Field
                    name="other-database"
                    render={({ input, meta }) => (
                      <div className="w-full">
                        <Input placeholder="If chosen Others, please specify here..." {...input} />
                        {meta.touched && meta.error && (
                          <div className="text-[#EF4444] text-sm my-[2px]">{meta.error}</div>
                        )}
                      </div>
                    )}
                  />
                </div>
                <Field
                  name="db-type"
                  validate={required}
                  render={({ input, meta }) => (
                    <div className="grid grid-cols-3 gap-8 w-3/4">
                      <CustomButton
                        variant={input.value === 'VM' ? 'default' : 'secondary'}
                        onClick={() => {
                          input.onChange('VM');
                        }}>
                        VM
                      </CustomButton>
                      <CustomButton
                        variant={input.value === 'Compute Engine' ? 'default' : 'secondary'}
                        onClick={() => {
                          input.onChange('Compute Engine');
                        }}>
                        Compute Engine
                      </CustomButton>
                      <CustomButton
                        variant={input.value === 'SQL' ? 'default' : 'secondary'}
                        onClick={() => {
                          input.onChange('SQL');
                        }}>
                        SQL
                      </CustomButton>
                      {meta.touched && meta.error && (
                        <div className="text-[#EF4444] text-sm my-[2px]">{meta.error}</div>
                      )}
                    </div>
                  )}
                />
              </section>

              {/* Is Web Server Configured Section */}
              <section className="space-y-3">
                <div className="flex flex-row items-center justify-center gap-2 py-4">
                  <h2 className="text-lg font-bold">Is Web Server Configured?</h2>
                  <hr className="flex-1 w-full border-[#1E4E9D] border-t-2 mt-[2px] border-dashed" />
                </div>
                <Field
                  name="is-server-configured"
                  validate={required}
                  render={({ input, meta }) => (
                    <div>
                      <div className="grid grid-cols-2 gap-8 w-2/4">
                        <CustomButton
                          variant={input.value === 'Yes' ? 'default' : 'secondary'}
                          onClick={() => {
                            input.onChange('Yes');
                            setIsServerConfigured(true);
                          }}>
                          Yes
                        </CustomButton>
                        <CustomButton
                          variant={input.value === 'No' ? 'default' : 'secondary'}
                          onClick={() => {
                            input.onChange('No');
                            setIsServerConfigured(false);
                          }}>
                          No
                        </CustomButton>
                      </div>
                      {meta.touched && meta.error && (
                        <div className="text-[#EF4444] text-sm my-[2px]">{meta.error}</div>
                      )}
                    </div>
                  )}
                />

                {isServerConfigured && (
                  <Field
                    name="server-configured"
                    validate={required}
                    render={({ input, meta }) => (
                      <div className="flex gap-8">
                        <div className="flex flex-col  pr-4 w-1/4">
                          <CustomButton
                            variant={input.value === 'Nginx' ? 'default' : 'secondary'}
                            onClick={() => {
                              input.onChange('Nginx');
                            }}
                            className="rounded-none border-0 border-b-[1px] border-[#3D3D3D] rounded-t-sm">
                            Nginx
                          </CustomButton>
                          <CustomButton
                            variant={input.value === 'Apache' ? 'default' : 'secondary'}
                            onClick={() => {
                              input.onChange('Apache');
                            }}
                            className="rounded-none border-0 border-b-[1px] border-[#3D3D3D]">
                            Apache
                          </CustomButton>
                          <CustomButton
                            variant={input.value === 'IIS' ? 'default' : 'secondary'}
                            onClick={() => {
                              input.onChange('IIS');
                            }}
                            className="rounded-none border-0 rounded-b-sm">
                            IIS
                          </CustomButton>
                        </div>
                        {meta.touched && meta.error && (
                          <div className="text-[#EF4444] text-sm my-[2px]">{meta.error}</div>
                        )}
                      </div>
                    )}
                  />
                )}
              </section>

              <div className="flex flex-row gap-4 justify-end">
                <CustomButton
                  className="w-40"
                  onClick={() => {
                    form.restart();
                  }}>
                  Discard Changes
                </CustomButton>
                <CustomButton variant="default" className="border-0 w-40" type="submit">
                  Next
                </CustomButton>
              </div>
            </form>
          )}
        />
      </div>
    </main>
  );
};

export default PrerequisitePage;
