import { createStore, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import reduxStorage from 'redux-persist/lib/storage';
import { loader, notify, user, toggle } from './reducers';

const persistConfig = {
  key: 'root',
  storage: reduxStorage,
  whitelist: ['user']
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    loader,
    notify,
    user,
    toggle
  })
);

export const store = createStore(persistedReducer);
export const persistor = persistStore(store);
