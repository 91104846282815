import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import withLoader from '../../redux/Hoc/withLoader';
import withUser from '../../redux/Hoc/withUser';
import { useToast } from '../../components/ui/use-toast'; // Assuming useToast is imported from a utility
import { getLoadTestDetail, getAIRecommendationData } from '../../utils/util'; // Import API functions
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from '../../components/ui/table';
import { Button } from '../../components/ui/button';
/* eslint-disable no-unused-vars */


const LoadTestResults = ({ user, setLoader }) => {
	const { test_name } = useParams();
	const [testDetails, setTestDetails] = useState(null);
	const [recommendationData, setrecommendationData] = useState(null);
	const [showRecommendationData, setshowRecommendationData] = useState(false);
	const { toast } = useToast();


	useEffect(() => {
		const fetchLoadTestDetails = async () => {
			setLoader(true);
			try {
				const response = await getLoadTestDetail({
					user_id: user.user_id,
					enterprise_id: user.enterprise_id,
					test_name: test_name,
					object_id: user.object_id
				});
				let result = response.data;
				if (result.status == "success") {
					result.data.file_path = [result.data.file_path["0"]]
					setTestDetails(result.data);
					toast({ title: 'Data retrieved successfully', variant: 'success' });
				} else {
					switch (result.code) {
						case 400:
							toast({ title: 'Error', description: result.message, variant: 'error' });
							break;
						case 404:
							toast({ title: 'Data not found', description: result.message, variant: 'error' });
							break;
						case 500:
							toast({ title: 'Internal server error', description: result.message, variant: 'error' });
							break;
						default:
							toast({ title: 'Unknown error', description: result.message, variant: 'error' });
					}
				}
			} catch (error) {
				console.error('API call failed:', error);
				toast({ title: 'Failed to connect to the server', description: error.message, variant: 'error' });
			} finally {
				setLoader(false);
			}
		};
		fetchLoadTestDetails();
	}, [test_name, setLoader, toast]);

	const getAIRecommendation = async () => {
		const payload = {
			user_id: user?.user_id,
			enterprise_id: user?.enterprise_id,
			testname: testDetails?.test_name,
			test_id: testDetails?.test_id,
			subscription_id: user?.activated_subscription_id,
			object_id: user?.object_id
		}
		const loadTestRes = await getAIRecommendationData(payload);

		console.log(loadTestRes);


	}

	const renderTestDetailsResults = () => {
		const resultList = testDetails.result.map((each, index) => {
			const otherDetails = Object.entries(each).filter(([key]) => key !== 'metric_name');
			return (
				<li key={index} className="mb-6">
					<h3 className="font-semibold text-xl text-gray-300 mb-2">{each.metric_name}</h3>

					<ul className="bg-gray-800 p-4 rounded-lg shadow-lg text-sm">
						{otherDetails.map(([key, value], idx) => (
							<li key={idx} className="flex justify-between py-1 border-b border-gray-700 last:border-none">
								<span className="font-medium text-gray-400">{key.replace(/_/g, ' ')}:</span>
								<span className="text-gray-200">{value}</span>
							</li>
						))}
					</ul>
				</li>
			);
		});

		return <ul>{resultList}</ul>;
	}

	return (
		<div className="p-4">
			{testDetails ? (
				<div>
					<h1 className="text-2xl font-bold mb-4">Load Test Details - {testDetails.test_name}</h1>
					<Table className="bg-[#11141F] rounded-lg mb-4">
						<TableHeader>
							<TableRow className="border-b border-[#333333]">
								<TableHead className="text-white text-base font-medium p-4 text-left">Test Name</TableHead>
								<TableHead className="text-white text-base font-medium p-4 text-left">Number of Virtual Users</TableHead>
								<TableHead className="text-white text-base font-medium p-4 text-left">Run At</TableHead>
							</TableRow>
						</TableHeader>
						<TableBody>
							<TableRow className="border-b border-[#333333]">
								<TableCell className="py-2 px-4 text-white">{testDetails.test_name}</TableCell>
								<TableCell className="py-2 px-4 text-white">{testDetails.number_of_virtual_users}</TableCell>
								<TableCell className="py-2 px-4 text-white">
									{new Date(testDetails.created_at).toLocaleString()}
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>

					<div>
						<div className="mt-4">
							<div className="flex justify-between items-center">
								<h1 className="text-2xl font-semibold">Output</h1>
								<Button
									type="button"
									className="text-white h-10 w-60 text-base font-medium"
									onClick={() => getAIRecommendation()}
								>
									AI Recommendations
								</Button>
							</div>
							{testDetails.result ? (
								<div>
									{renderTestDetailsResults()}
								</div>
							) : (
								<div>
								</div>
							)}
							<iframe
								src={testDetails.file_path[0].output_path}
								title="File Viewer"
								className="w-full h-[100vw]"
							></iframe>
						</div>
					</div>

				</div>
			) : (
				<div>No data available</div>
			)}
		</div>
	);
};

export default withLoader(withUser(LoadTestResults));
