import React, { useEffect, useState } from 'react';
import { Card, CardDescription, CardHeader, CardTitle } from '../../components/ui/card';
import { Button } from '../../components/ui/button';
import { Zap, PhoneCall, CircleHelp } from 'lucide-react';
import { Slider } from '../../components/ui/slider';
import { useNavigate } from 'react-router-dom';
import withLoader from '../../redux/Hoc/withLoader';
import withUser from '../../redux/Hoc/withUser';
import { getPlanDetails, getSubscriptionDetails } from '../../utils/util';

const Subscriptions = ({ setLoader, user }) => {
  const navigate = useNavigate();
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [totalUsed, setTotalUsed] = useState({
    VirtualUsers: 0,
    AIRecommendationForLoadTesting: 0,
    SecurityTesting: 0,
    AIRecommendationsForSecurityTesting: 0,
    ArchitectureSuggestion: 0
  });
  const [totalAvailable, setTotalAvailable] = useState({
    VirtualUsers: 0,
    AIRecommendationForLoadTesting: 0,
    SecurityTesting: 0,
    AIRecommendationsForSecurityTesting: 0,
    ArchitectureSuggestion: 0
  });

  // Subscription API call
  useEffect(() => {
    const subscriptionDetails = async () => {
      try {
        setLoader(true);
        const data = {
          user_id: user.user_id,
          enterprise_id: user.enterprise_id ? user.enterprise_id : null,
          subscription_id: user.activated_subscription_id
        };
        const response = await getSubscriptionDetails(data);
        console.log('Subscription Details: ', response);
        setSubscriptionData(response.data.data[0]);
        setLoader(false);
      } catch (error) {
        console.log(error);
        setLoader(false);
      }
    };

    subscriptionDetails();
  }, []);

  // Plan API Call
  useEffect(() => {
    setTotalUsed({
      VirtualUsers: subscriptionData?.number_of_virtual_users,
      AIRecommendationForLoadTesting:
        subscriptionData?.number_of_ai_recommendations_for_load_testing,
      SecurityTesting: subscriptionData?.number_of_security_testing,
      AIRecommendationsForSecurityTesting:
        subscriptionData?.number_of_ai_recommendations_for_security_testing,
      ArchitectureSuggestion: subscriptionData?.number_of_architecture_suggestion
    });

    const fetchData = async () => {
      setLoader(true);

      try {
        const data = {
          plan_id: subscriptionData.plan_id
        };

        const response = await getPlanDetails(data);

        console.log('Plan Details: ', response);
        setTotalAvailable({
          VirtualUsers: response.data.data?.number_of_virtual_users,
          AIRecommendationForLoadTesting:
            response.data.data?.number_of_ai_recommendations_for_load_testing,
          SecurityTesting: response.data.data?.number_of_security_testing,
          AIRecommendationsForSecurityTesting:
            response.data.data?.number_of_ai_recommendations_for_security_testing,
          ArchitectureSuggestion: response.data.data?.number_of_architecture_suggestion
        });
        setLoader(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    console.log('Total AVailable: ', totalAvailable);
  }, [subscriptionData]);

  const calculatePercentage = () => {
    const totalUsedSum = Object.values(totalUsed).reduce((acc, value) => acc + value, 0);
    const totalAvailableSum = Object.values(totalAvailable).reduce((acc, value) => acc + value, 0);
    const result = parseInt(((totalUsedSum / totalAvailableSum) * 100).toFixed(2));
    return 100 - result;
  };

  const handleChangePlanButton = () => {
    navigate('/plans');
  };

  if (!subscriptionData) return <div>No subscription data available</div>;

  const planName = subscriptionData?.subscription_plan;
  const expiryDate = subscriptionData?.expiry_on;
  const startDate = subscriptionData?.created_on;

  return (
    <main className="grid flex-1 items-start gap-4 p-4  sm:py-0 md:gap-8 bg-[#11141F] overflow-hidden">
      <div className="p-4 space-y-2 manrope-fontCss ">
        <div className="flex flex-row justify-between space-y-2">
          <div className="text-lg text-white font-bold">Ongoing Subscription - {planName}</div>
          <div className="space-x-4 flex flex-row items-center">
            <Button
              className="flex gap-2 text-white h-9 w-full text-xs font-medium border-2 border-[#444444]"
              onClick={handleChangePlanButton}>
              <Zap className="h-3.5 w-3.5" fill="white" stroke="white" />
              Change Plan
            </Button>
            <Button
              variant="ghost"
              className="flex gap-2 text-white h-9 w-full text-xs font-medium bg-[#444444] border-2 border-[#444444]">
              <PhoneCall />
              Contact Us
            </Button>
          </div>
        </div>
        <p className="text-sm text-muted-foreground -mt-2">
          You are currently a member of {planName} and below are your statistics
        </p>
        <div className="grid gap-4 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-3 xl:grid-cols-5">
          {Object.keys(totalUsed).map((key, index) => (
            <Card key={index} className="bg-[#161A27] dashboard-05-chunk-1 h-full">
              <CardHeader className="h-full p-4 flex justify-between">
                <div className="flex flex-row items-center space-x-3">
                  <CardDescription className="text-base font-bold text-white">
                    No. of {key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')}
                  </CardDescription>
                  <CircleHelp className="h-5 w-5 text-white" />
                </div>
                <CardTitle className="text-2xl font-semibold text-muted-foreground mt-1">
                  {totalUsed ? totalUsed[key] : ''}
                  {totalAvailable[key] !== undefined ? `/${totalAvailable[key]}` : ''}
                </CardTitle>
              </CardHeader>
            </Card>
          ))}
        </div>

        <div className="border-t flex-grow bg-[#333333]"></div>
        <div className="p-4 flex flex-col space-y-4">
          <p className="text-white text-lg font-bold">Plan Limit Used</p>
          <div className="w-full flex flex-col">
            <div className="flex flex-row space-x-4 ">
              <Slider
                value={[calculatePercentage()]}
                max={100}
                step={0.01}
                className="w-[95%]"
                disabled
              />
              <span className="font-semibold text-xl">{calculatePercentage()}%</span>
            </div>
          </div>
          <div className="flex flex-row justify-between w-[95%]">
            <p className=" text-base font-medium text-muted-foreground">
              Plan Start Date:{startDate}
            </p>
            <p className="text-base font-medium text-muted-foreground">
              Plan Expiry Date:{expiryDate}
            </p>
          </div>

          <p className="text-md text-white">
            You are currently a member of <span className="font-bold">{planName}</span> and this
            plan expires on <span className="font-bold text-lg">{expiryDate}</span>
          </p>
        </div>
        <footer className="p-4">
          <div className="gradient-border rounded-2xl overflow-hidden">
            <Card className="bg-[#161A27] ">
              <CardHeader className="p-4">
                <div className="flex flex-row justify-between items-center">
                  <div>
                    <CardDescription className="text-xl font-semibold text-white mb-1">
                      Get 50% more benefits
                    </CardDescription>
                    <p className="text-sm text-gray-400">
                      Increase your benefits by choosing our Standard & Pro Plans. Increase your
                      testing efficiency within seconds!
                    </p>
                  </div>
                  <div>
                    <Button
                      className="bg-[#8A5CF6] hover:bg-[#7C4DEF] text-white px-4 py-2 rounded-md flex items-center gap-2"
                      onClick={handleChangePlanButton}>
                      <Zap className="h-4 w-4" />
                      Change Plan
                    </Button>
                  </div>
                </div>
              </CardHeader>
            </Card>
          </div>
        </footer>
      </div>
    </main>
  );
};

export default withLoader(withUser(Subscriptions));
